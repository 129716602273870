import React, { useEffect, useRef } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { useToast } from "../../../hooks/useToast";
import { useDeleteDocs } from "../../../hooks/useDeleteDocs";

function AccountDeleteModal({ data, show, handleClose, updateByDeleteUser }) {
  const toast = useToast();

  const { deleteDocument, isPending } = useDeleteDocs();

  const formikRef = useRef(null);

  useEffect(() => {
    if (show && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [show, formikRef]);

  const handleSubmit = async () => {
    await deleteDocument("events", data.id);
    updateByDeleteUser(data.id);
    toast("success", "完了しました");
    handleClose();
  };
  return (
    <>
      {data && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              閉じる
            </Button>

            {!isPending && (
              <Button variant="danger" onClick={handleSubmit}>
                削除する
              </Button>
            )}

            {isPending && (
              <Button variant="danger" disabled type="button">
                削除する{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default AccountDeleteModal;
