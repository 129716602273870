import React, { useState } from "react";
import Styles from "./Intro.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import TopSlide from "./TopSlide";

export const Intro = () => {
  const { width } = useWindowDimensions();
  const [topPending, isTopPending] = useState(false);
  return (
    <div className={Styles.top}>
      <TopSlide isTopPending={isTopPending} />
      {width > 576 && (
        <>
          <div className={Styles.sidebar}>
            <Link to={"/"} className={Styles.brand}>
              <img src="/img/logo.webp" />
            </Link>

            <div className={Styles.menu}>
              <div className={Styles.link}>
                <p>About</p>
                <span>
                  <Link to={"/about-javma"}>VMD協会とは</Link>
                </span>
                <span>
                  <Link to={"/about-vmd"}>VMDとは</Link>
                </span>
              </div>

              <Link to={"/events"} className={Styles.link}>
                <p>EVENTS/NEWS</p>
                <span>イベント/ニュース</span>
              </Link>

              <Link to={"/licensure-exam"} className={Styles.link}>
                <p>LICENSURE EXAM</p>
                <span>商品装飾展示技能検定</span>
              </Link>

              <Link to={"/members"} className={Styles.link}>
                <p>MEMBERSHIP LIST</p>
                <span>正会員リスト</span>
              </Link>

              <Link to={"/supporting-members"} className={Styles.link}>
                <p>SUPPORTING MEMBER</p>
                <span>賛助会員リスト</span>
              </Link>

              <Link to={"/publication"} className={Styles.link}>
                <p>PUBLICATION</p>
                <span>出版物のご案内</span>
              </Link>

              <Link to={"/membership"} className={Styles.link}>
                <p>MEMBERSHIP</p>
                <span>入会内容</span>
              </Link>
              <Link to={"/#contactus"} className={Styles.link}>
                <p>CONTACT US</p>
                <span>お問い合わせ/メルマガ購読</span>
              </Link>
            </div>
          </div>

          <Link to={"/login"} className={Styles.links}>
            管理者ログイン
          </Link>
        </>
      )}

      <div className={Styles.textDiv}>
        <p>
          JAPAN VISUAL<br></br>
          MERCHANDISING<br></br>
          ASSOCIATION
        </p>
      </div>
      <div className={Styles.scroll}>
        <p>SCROLL</p>
        <FontAwesomeIcon className={Styles.scrollIcon} icon={faChevronDown} />
      </div>
    </div>
  );
};
