import React, { useEffect, useRef } from "react";
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Styles from "./Purchase.module.css";
import { useSendEmail } from "../../hooks/useSendMail";
import { useNavigate } from "react-router-dom";

const Purchase = () => {
  const titleRef = useRef(null);
  const { sendMail, isConfirmed, isPending, error } = useSendEmail();
  const navigate = useNavigate();

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      postcode: "",
      address: "",
      terminilogy: "",
      academic: "",
      skill: ""
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required("お名前は必須です")
        .test(
          "not-only-numbers",
          "お名前は数字のみでは入力できません",
          (value) => isNaN(value) || value.match(/[a-zA-Z]/)
        ),

      email: Yup.string()
        .matches(
          /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "有効なメールアドレスを入力してください。"
        )
        .email("有効なメールアドレスを入力してください")
        .required("メールアドレスは必須です"),

      phone: Yup.string()
        .matches(
          /^(0\d{1,4})-?(\d{1,4})-?(\d{4})$/,
          "有効な電話番号を入力してください (例: 03-0000-1111 または 090-0000-1111)"
        )
        .required("電話番号は必須です"),

      postcode: Yup.string().required("郵便番号を入力してください"),
      address: Yup.string().required("住所を入力してください"),
      academic: Yup.string().required("冊数を入力してください"),
      skill: Yup.string().required("冊数を入力してください")
    }),
    onSubmit: (values) => {
      console.log("Form Submitted with values:", values);

      sendMail({
        mailOption: {
          from: "日本ビジュアルマーチャンダイジング協会<info@javma.com>",
          to: values.email,
          cc: "info@javma.com",
          subject: "【日本ビジュアルマーチャンダイジング協会】書籍ご購入お申し込み送信完了のお知らせ",
          html: `
                  ${values.name}様 <br>
                  <br>
                  ※このメールはメッセージの送信が完了した時点で自動で配信されるメールです。<br>
                  <br>
                  この度は、日本ビジュアルマーチャンダイジング協会へ<br>
                  書籍のご購入お申し込みをいただき誠にありがとうございました。<br>
                  下記の内容で承りましたのでご確認ください。<br>
                  改めて、事務局よりご連絡をさせて頂きますのでしばらくお待ちください。<br>
                  <br>
                  <hr>
                  [氏名　　　　　]${values.name}<br>
                  [メールアドレス]${values.email}<br>
                  [電話　　　　　]${values.phone}<br>
                  [郵便番号　　　]${values.postcode}<br>
                  [住所　　　      ]${values.address}<br>
                  [『国家検定 商品装飾展示技能検定ガイドブック』 学科 ${values.academic}冊]<br>
                  [『国家検定 商品装飾展示技能検定ガイドブック』 実技編 ${values.skill}冊]<br>
                  <hr>
                  <br>
                  ※1週間以上経っても事務局から連絡がない場合は、<br>
                  お手数をおかけいたしますが、以下の事務局までお問い合わせください。<br>
                  ※本メールにお心当たりのない場合は、誠に恐れ入りますが、<br>
                  破棄していただくようにお願い申し上げます。<br>
                  <hr>
                  日本ビジュアルマーチャンダイジング協会<br>
                  <a href="mailto: info@javma.com">info@javma.com</a>
                  <hr>
                  `
        }
      });
    }
  });

  useEffect(() => {
    console.log("error", error);
  }, [error]);

  useEffect(() => {
    if (isConfirmed) {
      navigate("/message-sent");
    }
  }, [isConfirmed]);

  return (
    <div>
      <Container>
        <Row className="mt-5 d-flex justify-content-center">
          <Col xs={12} sm={8}>
            <div className={Styles.productDecoration}>
              <span className={Styles.spaceRemover}>『</span>商品装飾展示技能検定ガイドブック』
              <br className="d-block d-sm-none"></br>を
            </div>
            <div className={Styles.formIntroTitle} ref={titleRef}>
              <p>ご購入ご希望の方は下記入力の上、よろしければ確認ボタンをクリックしてください</p>
            </div>
            <h2 className={Styles.purchase}>PURCHASE REQUEST</h2>
            <p className={Styles.purchaseSubtitle}>購入申込</p>
            <form className="mt-8" onSubmit={formik.handleSubmit}>
              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  氏名：
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="name"
                    className={`${Styles.customInput} border-0`}
                    placeholder="山本　一郎"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className={Styles.errorMsg}>{formik.errors.name}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  メールアドレス：
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    className={`${Styles.customInput} border-0`}
                    placeholder="yamamotoichiro@vmd.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className={Styles.errorMsg}>{formik.errors.email}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  電話：
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="phone"
                    className={`${Styles.customInput} border-0`}
                    placeholder="03-0000-1111"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className={Styles.errorMsg}>{formik.errors.phone}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  郵便番号：
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="postcode"
                    className={`${Styles.customInput} border-0`}
                    placeholder="111-111"
                    value={formik.values.postcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.postcode && formik.errors.postcode ? (
                    <div className={Styles.errorMsg}>{formik.errors.postcode}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  送り先住所：
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="address"
                    className={`${Styles.customInput} border-0`}
                    placeholder="東京都○○○区○○1-1-1　○○ビル 101"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className={Styles.errorMsg}>{formik.errors.address}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={9} md={7} className={Styles.label}>
                  <span className={Styles.spaceRemover}>『</span>国家検定 商品装飾展示技能検定ガイドブック』
                  <br className="d-block d-sm-none"></br> 学科編（　）冊：
                </Form.Label>
                <Col sm={3} md={5}>
                  <Form.Control
                    type="text"
                    name="academic"
                    className={`${Styles.customInput} border-0`}
                    placeholder="0"
                    value={formik.values.academic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.academic && formik.errors.academic ? (
                    <div className={Styles.errorMsg}>{formik.errors.academic}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={9} md={7} className={Styles.label}>
                  <span className={Styles.spaceRemover}>『</span>国家検定 商品装飾展示技能検定ガイドブック』
                  <br className="d-block d-sm-none"></br> 実技編（　）冊：
                </Form.Label>
                <Col sm={3} md={5}>
                  <Form.Control
                    type="text"
                    name="skill"
                    className={`${Styles.customInput} border-0`}
                    placeholder="0"
                    value={formik.values.skill}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.skill && formik.errors.skill ? (
                    <div className={Styles.errorMsg}>{formik.errors.skill}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <div className={`w-100 ${Styles.CustomBtnContainer}`}>
                <Button type="submit" className={Styles.send}>
                  {isPending ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    <>送信する</>
                  )}
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Purchase;
