import React from 'react'
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import Styles from './Contact.module.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ContactHome from '../home/ContactHome/ContactHome';

const Contact = () => {
    return (
        <>
            <Header />
            <div className={Styles.contactus}>
                <div className={Styles.pageIntro}>
                    <Container className={Styles.container}>
                        <div className={Styles.title}>CONTACT US</div>
                        <div className={Styles.subtitle}>お問い合わせ</div>
                    </Container>
                </div>

                <ContactHome showTitle={false}/>

            </div>
            <Footer />
        </>
    )
}

export default Contact