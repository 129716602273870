import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import MetaData from "../../utils/MetaData";
import { useGetDoc } from "../../hooks/useGetDoc";
import Styles from "./LicensureExamHistory.module.css";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NotFound from "../notFoundPage/notFound";
import { useCollection } from "../../hooks/useCollection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/Universal/UComponent/Sidebar";

const LicensureExamHistory = () => {
  const { width } = useWindowDimensions();
  const { firebaseGetDoc, data, isPending, error } = useGetDoc();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      firebaseGetDoc("examHistory", id);
    }
  }, [id]);

  const { documents: examHistoryData, getMultipleDocs } = useCollection();

  const loadData = () => {
    getMultipleDocs({
      _collection: "examHistory",
      _query: [],
      orderByField: "position",
      orderByOption: "desc"
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      {isPending && (
        <>
          <MetaData />
          <Header />
          <div className={Styles.licensure}>
            <div className={Styles.pageIntro}>
              <Container className={Styles.container}>
                <div className={Styles.title}>Certification Details and History</div>
                <div className={Styles.subtitle}>技能検定の詳細と履歴</div>
              </Container>
            </div>
          </div>
          <div className={Styles.detailsContainer}>
            <Container>
              <Row className="d-flex justify-content-center mt-5 py-5">
                <Spinner animation="grow" className="mx-2" />
                <Spinner animation="grow" className="mx-2" />
                <Spinner animation="grow" className="mx-2" />
                <Spinner animation="grow" className="mx-2" />
                <Spinner animation="grow" className="mx-2" />
              </Row>
            </Container>
          </div>
          <Footer />
        </>
      )}

      {data ? (
        <>
          <MetaData />
          <Header />
          <div className={Styles.licensure}>
            <div className={Styles.pageIntro}>
              <Container className={Styles.container}>
                <div className={Styles.title}>Certification Details and History</div>
                <div className={Styles.subtitle}>技能検定の詳細と履歴</div>
              </Container>
            </div>
            <div className={Styles.detailsContainer}>
              <Container>
                <Row>
                  <Col xs={12} sm={8} className="px-4 ps-sm-2 pe-sm-5">
                    <>
                      <div className={`ql-snow ql-container border-0`}>
                        <div
                          className={` ql-editor px-0 py-5`}
                          dangerouslySetInnerHTML={{
                            __html: data.content
                          }}
                        ></div>
                      </div>
                    </>
                  </Col>

                  <Col sm={3} className="px-0">
                    <Sidebar examHistoryDataShow={true} />
                  </Col>
                </Row>
                {/* <Row className="pe-5 me-5">
                  {examHistoryData && Object.values(examHistoryData).length > 0 && (
                    <>
                      {Object.values(examHistoryData).map((doc, i) => (
                        <Col lg={3} xs={12} className="mt-2">
                          <Link to={`/licensure-exam-history/${doc.id}`} className={Styles.arrowItem}>
                            {doc.title} <FontAwesomeIcon icon={faCircleChevronRight} />
                          </Link>
                        </Col>
                      ))}
                    </>
                  )}
                </Row> */}
              </Container>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default LicensureExamHistory;
