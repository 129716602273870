import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer1 = () => {
  return (
    <div className={Styles.answer}>
      <p>
        ▶︎学生の場合<br></br>
        厚生労働大臣指定を受けた専修学校、各種学校の卒業予定者と商品装飾展示技能職種に関連する学科を専攻した短大、高等専門学校、高校（職業課程）卒業予定者は在学中に受検が可能です。指定校については、受検される都道府県の職業能力開発協会にお問い合わせください
      </p>
      <p className="mb-0">
        ▶︎社会人の場合<br></br>
        商品装飾展示に関する実務経験のある方なら、その期間に関わらずどなたでも受検できます。実務経験は、陳列、ディスプレイ、VMDの企画・デザインやフィニッシュワークの専門職だけでなく、販売職、営業職などの方が職務の一部として商品装飾展示に関わっている場合なども含め幅広く認められています。アパレル、ファッション、インテリアなどに限らず、食品、サービス業など、業種、業態に関わらす受検できます。
        <br></br>
        個別の事例については、受検される都道府県の職業能力開発協会にお問い合わせください。
      </p>
      <Link to={"https://www.javada.or.jp/kyoukai/itiran.html"} target="_blank">
        都道府県職業能力開発協会 : 中央職業能力開発協会（JAVADA）
      </Link>
    </div>
  );
};

export default Answer1;
