import React from "react";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import Styles from "./Event.module.css";
import { Col, Container, Row } from "react-bootstrap";
import EventList from "./EventList";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaData from "../../utils/MetaData";

const Events = () => {
  const { width } = useWindowDimensions();
  return (
    <>
    <MetaData />
      <Header />
      <div className={Styles.event}>
        <div className={Styles.pageIntro}>
          <div className={Styles.title}>EVENT/NEWS</div>
          <div className={Styles.subtitle}>イベント/ニュース</div>
        </div>

        <div className={Styles.description}>
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={11} sm={7}>
                <p>
                  日本ビジュアルマーチャンダイジング協会に関する、開催予定のイベントやイベント開催後のレポート、出版物のご案内、会員のインタビュー記事などを各種掲載しています。
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className={`${width <= 576 ? Styles.mobileList : ""}`}>
          <Row className="d-flex justify-content-center mt-5 mx-3">
            <Col sm={10} className="px-1 px-sm-0 mt-2">
              <EventList />
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Events;
