import React, { useEffect } from "react";

import _ from "lodash";
import { Link } from "react-router-dom";
import { Dropdown, Form, OverlayTrigger, Placeholder, Tooltip } from "react-bootstrap";

import { documentId } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faLock,
  faLockOpen,
  faRepeat,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AccountList.module.css";
import { useToast } from "../../../hooks/useToast";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { getGroupName, getRandomInt } from "../../../utils/Utils";

function EventListItem({
  doc,
  getUpdatedMCDatabyID,
  ownerView = false,
  groupChangeModal = () => {},
  selectedUsersIds,
  setSelectedUsersIds,
  from = "",
  deleteUser
}) {
  const toast = useToast();
  const { firebaseSetDoc, isPending, isConfirmed, error } = useSetDoc();
  const {
    getSingleDocWithQuery,
    data: fetchedGroups,
    isPending: groupPending,
    error: groupError
  } = useGetDoc();
  // const { documents: fetchedGroups, isPending: groupPending, lastDoc, isLast, getMultipleDocs } = useCollection();

  const updateProfile = async (setData, id) => {
    await firebaseSetDoc("events", setData, id, true);
    await getUpdatedMCDatabyID(id);
    toast("success", "完了しました");
  };

  const publicTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      公開中
    </Tooltip>
  );

  const privateTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      非公開中
    </Tooltip>
  );
  const handleUsersCheckboxChange = (e) => {
    const articleId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedUsersIds.includes(articleId)) {
      // Add the article ID if it's not already in the array
      setSelectedUsersIds((prevIds) => [...prevIds, articleId]);
    } else if (!isChecked) {
      // Remove the article ID from the array
      setSelectedUsersIds((prevIds) => prevIds.filter((id) => id !== articleId));
    }
  };
  return (
    <>
      <tr className="mt-4">
        <td className="border border-end-0 rounded-start align-middle text-center">
          {from !== "dashboard" && selectedUsersIds !== undefined && (
            <Form.Check
              type="checkbox"
              value={doc.id}
              checked={selectedUsersIds.includes(doc.id)}
              onChange={handleUsersCheckboxChange}
            />
          )}
        </td>
        <td className="border-top border-bottom align-middle ">
          {doc.mainPhoto ? (
            <img src={doc.mainPhoto} className={` ${styles.user_photo}`} alt="user" />
          ) : (
            <img src="/img/no_user_pic.png" className={` ${styles.user_photo}`} alt="user" />
          )}
        </td>

        <td className="border-top border-bottom align-middle text-break">
          <Link to={`/mypage/event-details/${doc.id}`}>{doc.title}</Link>
        </td>
        <td className="border border-start-0 rounded-end align-middle text-center">
          <div className="d-flex justify-content-center align-items-center">
            {doc.private ? (
              <FontAwesomeIcon icon={faLock} className="me-2 text-warning" />
            ) : (
              <FontAwesomeIcon icon={faLockOpen} className="me-2 text-success" />
            )}
            <>
              {from !== "dashboard" && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    className="p-0 text-decoration-none text-dark"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {doc.private ? (
                      <>
                        <Dropdown.Item
                          className="btn text-success fw-bold"
                          onClick={() => updateProfile({ private: false }, doc.id)}
                        >
                          <FontAwesomeIcon icon={faLockOpen} /> 公開
                        </Dropdown.Item>
                      </>
                    ) : (
                      <>
                        <Dropdown.Item
                          className="btn  text-warning fw-bold"
                          onClick={() => updateProfile({ private: true }, doc.id)}
                        >
                          <FontAwesomeIcon icon={faLock} /> 非公開
                        </Dropdown.Item>
                      </>
                    )}

                    <Dropdown.Divider />

                    <Dropdown.Item className="btn text-danger fw-bold" onClick={() => deleteUser(doc)}>
                      <FontAwesomeIcon icon={faTrashAlt} /> 削除
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          </div>
        </td>
      </tr>

      <tr className="spacer">
        <td></td>
      </tr>
    </>
  );
}

export default EventListItem;
