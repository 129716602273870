import { db, firebaseErrors } from "../firebase/config";

//firebase imports
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";

export const useUpdateDoc = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const firebaseUpdateDoc = async (c, docs, id) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);
    let document = { ...docs };
    let ref = doc(db, c, id);
    // console.log(document, ref)
    await updateDoc(ref, { ...document }).then(
      (res) => {
        setIsPending(false);
        setIsConfirmed(true);
        // console.log(res);
      },
      (err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(firebaseErrors[err.code] || err.message);
        // console.log(firebaseErrors[err.code] || err.message);
      }
    );
  };

  // const firebaseSetDocQuery = async (c, docs, q, merge = false) => {
  //   setIsPending(true);
  //   setError(null);
  //   setIsConfirmed(false);

  //   let ref = collection(db, c);
  //   if (q) {
  //     q.forEach((qitem) => {
  //       ref = query(ref, where(...qitem));
  //     });
  //   }

  //   const docSnap = await getDocs(ref);
  //   let results = [];
  //   docSnap.forEach(async (doc) => {
  //     results.push({ ...doc.data(), id: doc.id });
  //     await firebaseSetDoc(c, docs, doc.id, merge);
  //   });

  //   return results;

  // };

  const firebaseUpdateDocs = async (c, docs, ids) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);

    try {
      const promises = [];
      for (let i = 0; i < ids.length; i++) {
        const docData = { ...docs };
        const ref = doc(db, c, ids[i]);
        promises.push(updateDoc(ref, { ...docData }));
      }
      await Promise.all(promises);
      // console.log("Documents updated successfully");
    } catch (error) {
      setIsConfirmed(false);
      setError(firebaseErrors[error.code] || error.message);
      return;
    } finally {
      setIsPending(false);
      setIsConfirmed(true);
    }
  };

  return { firebaseUpdateDoc, firebaseUpdateDocs, isPending, isConfirmed, error };
};
