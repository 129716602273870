import React, { useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { useUpdatePassword } from "../../../hooks/useUpdatePassword";
import { password, passwordConfirm } from "../../../utils/InputSchema";

export default function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const { error, isPending, message, updatePasswordFn } = useUpdatePassword();

  let initialValues = {
    password: "",
    passwordConfirm: ""
  };

  const handelSubmit = (values) => {
    updatePasswordFn(values.password);
  };
  const schema = yup.object().shape({
    password: password(),
    passwordConfirm: passwordConfirm(yup.ref("password"))
  });
  return (
    <>
      <Container className="pt-3">
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <Card className="">
              <Card.Body>
                {error && (
                  <Alert variant="danger" dismissible className="text-center">
                    {error && <p className="m-0">{error}</p>}
                  </Alert>
                )}

                {message && (
                  <Alert variant="success" dismissible className="text-center">
                    {message && <p className="m-0">{message}</p>}
                  </Alert>
                )}

                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handelSubmit}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          新しいパスワード <span className="text-danger">※</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="半角英数字8文字以上"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                            required
                            isInvalid={touched.password && errors.password}
                            isValid={touched.password && !errors.password}
                          />
                          <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          新しいパスワード(確認用) <span className="text-danger">※</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type={showPasswordC ? "text" : "password"}
                            placeholder="半角英数字8文字以上"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordConfirm}
                            name="passwordConfirm"
                            isInvalid={touched.passwordConfirm && errors.passwordConfirm}
                            isValid={touched.passwordConfirm && !errors.passwordConfirm}
                          />
                          <InputGroup.Text
                            onClick={() => setShowPasswordC(!showPasswordC)}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={!showPasswordC ? faEyeSlash : faEye} />
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">{errors.passwordConfirm}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <div className="text-center">
                        {!isPending && (
                          <Button className="mb-2" variant="primary" type="submit">
                            変更 <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                        )}

                        {isPending && (
                          <Button className="mb-2" variant="primary" disabled>
                            変更 <FontAwesomeIcon icon={faPenToSquare} />
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
