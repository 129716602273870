import React, { useEffect } from "react";
import Header from "../../components/Universal/Header/Header";
import { useParams } from "react-router-dom";
import Styles from "./Member.module.css";
import { Button, Col, Container, Ratio, Row } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Footer from "../../components/Universal/Footer/Footer";
import { useGetDoc } from "../../hooks/useGetDoc";
import NotFound from "../notFoundPage/notFound";

const Member = () => {
  const { width } = useWindowDimensions();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const { memberID } = useParams();

  useEffect(() => {
    if (memberID) {
      getSingleDocWithQuery("members", [["memberId", "==", parseInt(memberID)]]);
    }
  }, [memberID]);

  return (
    <>
      {data && data[0] ? (
        <>
          <Header />
          <div className={Styles.member}>
            <h1 className={Styles.title}>MEMBER’S DETAILS</h1>
            <p className={Styles.subtitle}>正会員の詳細</p>

            <Container>
              <Row className="d-flex justify-content-center px-0 mt-0 pt-0 mt-sm-4 pt-sm-3">
                <Col xs={11} sm={9} className="px-1 px-sm-0 mt-1 mt-sm-5">
                  <Row className="mt-5 justify-content-sm-center">
                    <Col xs={9} sm={4} className="px-0">
                      <Ratio aspectRatio="1x1">
                        {data[0].profilePhoto ? (
                          <img src={data[0].profilePhoto} className="w-100" />
                        ) : (
                          <img src={"/img/no_user_pic.png"} className="w-100" />
                        )}
                      </Ratio>
                    </Col>
                    <Col xs={12} sm={8}>
                      {/* <h2 className={Styles.greetings}>ご挨拶</h2> */}
                      <p className={Styles.description}>
                        {/* {data[0].title && <>{data[0].title} ご挨拶〉</>} */}
                        ご挨拶
                        <br></br>
                        {data[0].self_introduction}
                      </p>
                    </Col>

                    <Col sm={9} className={Styles.details}>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>氏名：</p>
                        <p className={Styles.value}>{data[0].name}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>フリガナ：</p>
                        <p className={Styles.value}>{data[0].furigana}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>会員番号</p>
                        <p className={Styles.value}>{data[0].memberId}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>勤務先</p>
                        <p className={Styles.value}>{data[0].place_of_employment}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>事業形態</p>
                        <p className={Styles.value}>{data[0].business_type}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>部署</p>
                        <p className={Styles.value}>{data[0].department}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>肩書</p>
                        <p className={Styles.value}>{data[0].title}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>住所</p>
                        <p className={Styles.value}>
                          {data[0].postal_code}
                          {data[0].prefecture}
                          {data[0].work_address}
                        </p>
                      </div>

                      <div className={Styles.item}>
                        <p className={Styles.lable}>メールアドレス</p>
                        <p className={Styles.value}>{data[0].email}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>電話</p>
                        <p className={Styles.value}>{data[0].phone}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>携帯電話</p>
                        <p className={Styles.value}>{data[0].mobile_phone}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>URL</p>
                        <p className={Styles.value}>{data[0].url}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>専門分野</p>
                        <p className={Styles.value}>{data[0].area_of_expertise}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>職業名 or 職種</p>
                        <p className={Styles.value}>{data[0].occupation}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>所属チーム</p>
                        <p className={Styles.value}>{data[0].team}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>所属エリア</p>
                        <p className={Styles.value}>{data[0].area}</p>
                      </div>

                      {/* <div className={Styles.itemBox}>
                        <p className={Styles.lable}>自己紹介文</p>
                        <div className={Styles.boxValue}>
                          <p className={Styles.value}>{data[0].self_introduction}</p>
                        </div>
                      </div> */}
                    </Col>

                    <Col sm={9} className="text-center">
                      <a href={`mailto: ${data[0].email} `} className={`btn ${Styles.button}`}>
                        {width > 576 ? "仕事依頼" : "仕事を依頼する"}
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
        </>
      ) : (
        <>{!isPending && <NotFound />}</>
      )}
    </>
  );
};

export default Member;
