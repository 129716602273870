import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer8 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        実技試験は、商品のプレゼンテーションに必要な技術と技能の基本を組み合わせたものです。限られた条件下で素養と出来映えを見るために、現実の売り場におけるプレゼンテーションの再現というより、素養と適性が現れる特別なセットになっているようです。各級に求められている、基本的なスキルを十分に習得しておくことが合格のポイントになります。
      </p>
    </div>
  );
};

export default Answer8;
