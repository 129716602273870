import React, { useEffect, useState } from "react";

import _ from "lodash";
import { Helmet, HelmetData } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import styles from "./Admin.module.css";

import AdminDashboard from "./AdminDashboard";
import SideBar from "../../components/SideBar/SideBar";
import MyPageHeader from "../../components/Common/MyPageHeader";
import CreateMember from "./member/create-member/CreateMember";
import CreateMemberCheck from "./member/create-member/CreateMemberCheck";
import CreateMemberComplete from "./member/create-member/CreateMemberComplete";
import CSVUpload from "./member/create-member-csv/CSVUpload";
import Logout from "./Logout";
import ChangePassword from "./common/ChangePassword";
import ChangeEmail from "./common/ChangeEmail";
import MemberList from "./member/MemberList";
import ReOrderMember from "./member/ReOrderMember";
import MemberImageUpload from "./member/image_upload/MemberImageUpload";
import MemberImageUploadComplete from "./member/image_upload/MemberImageUploadComplete";
import AccountDetails from "./member/member-details/AccountDetails";
import EditMember from "./member/edit-member/EditMember";
import EditMemberCheck from "./member/edit-member/EditMemberCheck";
import EditMemberComplete from "./member/edit-member/EditMemberComplete";

import LpSlide from "./SiteSettings/LpSlide.js";

import CreateSupportMember from "./support-member/create-support-member/CreateSupportMember";
import CreateSupportMemberCheck from "./support-member/create-support-member/CreateSupportMemberCheck";
import CreateSupportMemberComplete from "./support-member/create-support-member/CreateSupportMemberComplete";
import SupportAccountDetails from "./support-member/support-member-details/SupportAccountDetails";
import EditSupportMember from "./support-member/edit-support-member/EditSupportMember";
import EditSupportMemberCheck from "./support-member/edit-support-member/EditSupportMemberCheck";
import EditSupportMemberComplete from "./support-member/edit-support-member/EditSupportMemberComplete";
import SupportMemberList from "./support-member/SupportMemberList";
import CreateEvent from "./events/create-event/CreateEvent";
import CreateEventCheck from "./events/create-event/CreateEventCheck";
import CreateEventComplete from "./events/create-event/CreateEventComplete";
import EventList from "./events/EventList";
import EventDetails from "./events/EventDetails";
import SupportMemberCSVUpload from "./support-member/create-support-member-csv/SupportMemberCSVUpload.js";
import SupportMemberImageUploadComplete from "./support-member/image_upload/MemberImageUploadComplete.js";
import SupportMemberImageUpload from "./support-member/image_upload/SupportMemberImageUpload.js";
import ReOrderSupportMember from "./support-member/ReOrderSupportMember.js";
import EditEvent from "./events/edit-event/EditEvent.js";
import EditEventCheck from "./events/edit-event/EditEventCheck.js";
import EditEventComplete from "./events/edit-event/EditEventComplete.js";
import CreateHistory from "./exam-history/CreateHistory.js";
import ReOrderExamHistory from "./exam-history/ReOrderExamHistory.js";

export default function AdminMyPage() {
  const [sideBarShow, setSideBarShow] = useState(false);

  const [activeMenu, setActiveMenu] = useState("settings");
  const [pageTitle, setPageTitle] = useState("一般設定");

  const menus = [
    { menuHeader: true, text: "メインビジュアル設定", bold: true },
    { link: "lp-slide", text: "　スライド画像" },
    { menuHeader: true, text: "会員リスト", bold: true },
    { link: "member-list", text: "　会員リスト一覧" },
    { link: "create-member", text: "　新規作成" },
    { link: "csv-upload", text: "　CSVデータ登録" },
    { link: "member-image-upload", text: "　画像アップロード" },
    { link: "reorder-member", text: "　表示順変更" },

    { menuHeader: true, text: "賛助会員リスト", bold: true },
    { link: "support-member-list", text: "　賛助会員リスト一覧" },
    { link: "create-support-member", text: "　新規作成" },
    { link: "support-member-csv-upload", text: "　CSVデータ登録" },
    { link: "support-member-image-upload", text: "　画像アップロード" },
    { link: "reorder-support-member", text: "　表示順変更" },

    { menuHeader: true, text: "イベント/ニュース", bold: true },

    { link: "event-list", text: "　リスト一覧" },
    { link: "create-event", text: "　新規作成" },
    // { link: "csv-upload", text: "　CSVデータ登録" },
    // { link: "support-member-image-upload", text: "　画像アップロード" },

    { menuHeader: true, text: "技能検定の詳細と履歴", bold: true },
    { link: "create-exam-list", text: "　リスト一覧と表示順変更" },
    { link: "create-exam-history", text: "　新規作成" },

    { link: "change-password", text: "パスワード変更", bold: true },
    { link: "change-email", text: "メールアドレス変更", bold: true },
    { link: "logout", text: "ログアウト", bold: true }
  ];
  const sideBarShowHandlerFn = () => {
    setSideBarShow(!sideBarShow);
  };

  let location = useLocation();

  useEffect(() => {
    const loc = location.pathname.replace(/\/mypage\//g, "");
    if (loc && loc !== "/mypage") {
      setActiveMenu(loc);
      menus.map((menu) => {
        if (menu.link === loc) {
          setPageTitle(menu.titleText || menu.text);
        }
        return true;
      });
    }
  }, [location]);

  const helmetData = new HelmetData({});

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>{pageTitle}</title>
      </Helmet>
      <MyPageHeader
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        pageTitle={pageTitle}
      />
      <SideBar
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        activeMenu={activeMenu}
        menus={menus}
      />
      <div className={styles.mainBody}>
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/lp-slide" element={<LpSlide />} />

          <Route path="member-list" element={<MemberList />} />
          <Route path="create-member" element={<CreateMember />} />
          <Route path="create-member/check" element={<CreateMemberCheck />} />
          <Route path="create-member/completed" element={<CreateMemberComplete />} />

          <Route path="edit-member/:memberId" element={<EditMember />} />

          <Route path="edit-member/check/:memberId" element={<EditMemberCheck />} />
          <Route path="edit-member/completed" element={<EditMemberComplete />} />

          <Route path="reorder-member" element={<ReOrderMember />} />

          <Route path="member-image-upload" element={<MemberImageUpload />} />
          <Route path="member-image-upload-complete" element={<MemberImageUploadComplete />} />

          <Route path="csv-upload" element={<CSVUpload />} />

          <Route path="user-details/:memberId" element={<AccountDetails />} />

          <Route path="support-member-list" element={<SupportMemberList />} />

          <Route path="create-support-member" element={<CreateSupportMember />} />
          <Route path="create-support-member/check" element={<CreateSupportMemberCheck />} />
          <Route path="create-support-member/completed" element={<CreateSupportMemberComplete />} />

          <Route path="edit-support-member/:memberId" element={<EditSupportMember />} />
          <Route path="edit-support-member/check/:memberId" element={<EditSupportMemberCheck />} />
          <Route path="edit-support-member/completed" element={<EditSupportMemberComplete />} />

          <Route path="reorder-support-member" element={<ReOrderSupportMember />} />

          <Route path="support-member-image-upload" element={<SupportMemberImageUpload />} />
          <Route path="support-member-image-upload-complete" element={<SupportMemberImageUploadComplete />} />

          <Route path="support-member-csv-upload" element={<SupportMemberCSVUpload />} />

          <Route path="support-user-details/:memberId" element={<SupportAccountDetails />} />

          {/* EVENTs */}

          <Route path="event-list" element={<EventList />} />
          <Route path="create-event" element={<CreateEvent />} />
          <Route path="create-event/check" element={<CreateEventCheck />} />
          <Route path="create-event/completed" element={<CreateEventComplete />} />
          <Route path="event-details/:id" element={<EventDetails />} />

          <Route path="edit-event/:id" element={<EditEvent />} />
          <Route path="edit-event/check/:id" element={<EditEventCheck />} />
          <Route path="edit-event/completed" element={<EditEventComplete />} />

          {/* exam history */}

          <Route path="create-exam-list" element={<ReOrderExamHistory />} />
          <Route path="create-exam-history" element={<CreateHistory />} />

          <Route path="change-password" element={<ChangePassword />} />
          <Route path="change-email" element={<ChangeEmail />} />

          <Route path="logout" element={<Logout />} />

          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </>
  );
}
