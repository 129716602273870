import React from "react";

import _ from "lodash";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import CreateMemberInput from "./CreateMemberInput";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import {
  email,
  openStringSchema,
  phoneSchemaBoth,
  numberOnlySchema,
  kanaOnlySchema,
} from "../../../../utils/InputSchema";

function CreateMember() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  const helmetData = new HelmetData({});

  const backData = localStorage.getItem("newMemberData");

  let initialValues = {
    memberId: "", //会員番号
    name: "", //名前
    furigana: "", //ふりがな
    place_of_employment: "", //勤務先
    business_type: "", //事業形態
    department: "", //部署
    title: "", //肩書
    postal_code: "", //郵便番号
    prefecture: "", //都道府県
    work_address: "", //勤務先住所
    email: "", //メールアドレス
    phone: "", // 電話
    mobile_phone: "", // 携帯電話
    area_of_expertise: "", //専門分野
    occupation: "", //職業名 or 職種
    url: "", //URL
    team: "", //所属チーム
    area: "", //所属エリア
    self_introduction: "", //自己紹介
  };
  if (backData) {
    console.log("backData", backData);
    initialValues = {
      ...JSON.parse(localStorage.getItem("newMemberData")),
    };
  }
  const schema = yup.object().shape({
    //会員番号
    memberId: numberOnlySchema().required("この欄は必須です").test(
      "is-memberId-unique",
      "ご登録会員番号がすでに登録済み、もしくは使用できない情報です",
      async (value) => {
        if (value) {
          let user = await getSingleDocWithQuery("members", [
            ["memberId", "==", value],
          ]);
          return !user;
        }
        return true;
      }
    ),
    //名前
    name: openStringSchema(32).required("この欄は必須です"),
    //ふりがな
    furigana: kanaOnlySchema(32).required("この欄は必須です"),
    //勤務先
    place_of_employment: openStringSchema(1000),
    //事業形態
    business_type: openStringSchema(1000),
    //部署
    department: openStringSchema(1000),
    //肩書
    title: openStringSchema(1000),
    //郵便番号
    postal_code: openStringSchema(1000),
    //都道府県
    prefecture: openStringSchema(1000),
    //勤務先住所
    work_address: openStringSchema(1000),
    //メールアドレス
    email: email().test(
      "is-unique",
      "ご登録メールアドレスがすでに登録済み、もしくは使用できない情報です。",
      async (value) => {
        if (value) {
          let user = await getSingleDocWithQuery("members", [
            ["email", "==", value],
          ]);
          return !user;
        }
        return true;
      }
    ),
    // 電話
    phone: phoneSchemaBoth(),
    // 携帯電話
    mobile_phone: phoneSchemaBoth(),
    //専門分野
    area_of_expertise: openStringSchema(1000),
    //職業名 or 職種
    occupation: openStringSchema(1000),
    //URL
    url: yup.string().nullable().url("有効なURLを入力してください"),
    //所属チーム
    team: openStringSchema(1000),
    //所属エリア
    area: openStringSchema(1000),
    //自己紹介
    self_introduction: openStringSchema(1000),
  });

  const handleSubmit = (values) => {
    const profilePhoto =
      localStorage.getItem("croppedProfilePhoto") ||
      (_.has(JSON.parse(backData), "profilePhoto")
        ? JSON.parse(backData).profilePhoto
        : null);
    // console.log(profilePhoto);
    if (profilePhoto && profilePhoto !== undefined) {
      values.profilePhoto = profilePhoto;
      localStorage.removeItem("croppedProfilePhoto");
    } else {
      values.profilePhoto = "";
    }

    try {
      const serializedState = JSON.stringify(values);
      console.log("serializedState", serializedState);
      localStorage.setItem("newMemberData", serializedState);
      navigate("/mypage/create-member/check");
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: backData ? "/mypage/group-page-check" : "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={1}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}>
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <CreateMemberInput />

                  <div className="text-center mt-4 mb-3">
                    <Button className="" variant="primary" type="submit">
                      <b>
                        次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                      </b>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateMember;
