import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Container,
  Modal,
  Ratio,
  Spinner,
} from "react-bootstrap";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { doc, writeBatch } from "firebase/firestore";

import { db } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import AddIcon from "@mui/icons-material/Add";
import Styles from "./ReOrderMember.module.css";
import Select from "react-select";
import { useSetDoc } from "../../../hooks/useSetDoc";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const ItemTypes = {
  CARD: "card",
};

const DraggableUserCard = ({
  member,
  index,
  moveCard,
  onDrop,
  getCurrentMembers,
}) => {
  const ref = React.useRef(null);
  const [isDeleting, setIsDeleting] = useState(false);


  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: member.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const currentUsers = getCurrentMembers();
      onDrop(currentUsers);
    },
  });

  drag(drop(ref));

  // Apply the 'dragging' class based on isDragging state
  const cardClassName = `dnd-card ${isDragging ? "dragging" : ""}`;

  return (
    <div ref={ref} className={cardClassName}>
      <Card className={Styles.cardItem}>
        <Card.Header className="d-flex justify-content-between">
          <span>位置: {index + 1} </span>
        </Card.Header>
        <Card.Body className=" p-0">
          <div className={Styles.GroupImg}>
            <Ratio aspectRatio={10 / 24}>
              {member.profilePhoto ? (
                <img
                  src={member.profilePhoto}
                  className="w-100"
                  alt="user"
                />
              ) : (
                <img src="/img/no-img.png" className="w-100" alt="user" />
              )}
            </Ratio>
          </div>
          <div className="px-2 my-2">
            <Link
              target="_blank"
              to={"/mypage/support-user-details/" + member.memberId}
              className={Styles.groupName}>
              [{member.memberId}] {member.name}
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const ReOrderMember = () => {
  const [members, setMembers] = useState([]);
  const {
    documents: membersData,
    getMultipleDocs,
  } = useCollection();
  const { documents: NoTopMembers, getMultipleDocs: getMultipleDocsGroup } =
    useCollection();
  const [showAddModal, setShowAddModal] = useState(false);
  const [noTopGroupOption, setNoTopGroupOption] = useState(false);
  const [selectGroup, setSelectedGroup] = useState(null);
  const { firebaseSetDoc } = useSetDoc();

  const loadData = () => {
    getMultipleDocs({
      _collection: "members",
      _query: [],
      orderByField: "position",
      orderByOption: "asc",
    });
  };

  const loadGroupData = () => {
    getMultipleDocsGroup({
      _collection: "members",
      _query: [],
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (membersData) {
      setMembers(membersData);
    }
  }, [membersData]);

  const moveCard = (dragIndex, hoverIndex) => {
    setMembers((prevUsers) => {
      const newGroups = [...prevUsers];
      const dragCard = newGroups[dragIndex];
      newGroups.splice(dragIndex, 1);
      newGroups.splice(hoverIndex, 0, dragCard);
      return newGroups;
    });
  };

  const getCurrentMembers = () => members;

  const updatePosition = async (newGroups) => {
    const batch = writeBatch(db);
    newGroups.forEach(async (item, index) => {
      const itemRef = doc(db, "members", item.id);
      batch.set(itemRef, { position: index + 1 }, { merge: true });
    });

    await batch.commit();
  };

  const handleClose = () => {
    setShowAddModal(false);
    setSelectedGroup(null);
  };
  const handleOpen = () => {
    setShowAddModal(true);
    setSelectedGroup(null);
    loadGroupData();
  };

  return (
    <>
      <Container>
        <h4>表示順</h4>
        {members && (
          <>
            <DndProvider backend={HTML5Backend}>
              <div className={Styles.groupContainer}>
                {members.map((member, index) => (
                  <DraggableUserCard
                    key={member.id}
                    member={member}
                    index={index}
                    moveCard={moveCard}
                    onDrop={updatePosition}
                    getCurrentMembers={getCurrentMembers}
                  />
                ))}
              </div>
            </DndProvider>
          </>
        )}
      </Container>
    </>
  );
};

export default ReOrderMember;
