import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../../firebase/config";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useUpdateDoc } from "../../../../hooks/useUpdateDoc";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import DeleteIcon from "@mui/icons-material/Delete";

const SupportMemberImageUpload = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showFileInput, setShowFileInput] = useState(true); // Control file input visibility
  const fileInputRef = useRef(null); // Reference for file input

  const helmetData = new HelmetData({});
  const { getSingleDocWithQuery } = useGetDoc();
  const { firebaseUpdateDoc } = useUpdateDoc();
  const { firebaseSetDoc } = useSetDoc();
  const navigate = useNavigate(); // React-router-dom navigate

  // Handle image selection and preview
  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);

    // Check if the user canceled the selection
    if (files.length === 0) return;

    const newPreviewImages = [];
    const newUploadErrors = [];

    for (let file of files) {
      // Check if the file is already selected
      if (
        selectedImages.some(
          (image) => image.name === file.name && image.size === file.size
        )
      ) {
        continue; // Skip duplicate files
      }

      const filenameWithoutExt = file.name.replace(/\.[^/.]+$/, "");
      const memberIdAsNumber = Number(filenameWithoutExt); // Convert filename to number for memberId
      const isNumber = !isNaN(memberIdAsNumber); // Check if the filename is a valid number

      if (!isNumber) {
        newUploadErrors.push({
          file,
          error: `ファイル名「${filenameWithoutExt}」は有効な会員番号ではありません (数字である必要があります)。`,
        });
      } else {
        const memberExists = await checkSupportMemberIdExists(memberIdAsNumber);

        if (!memberExists) {
          newUploadErrors.push({
            file,
            error: `会員番号を持つメンバーは見つかりません。`,
          });
        }
      }

      const imageURL = URL.createObjectURL(file);
      newPreviewImages.push({ file, imageURL, isError: !isNumber });
    }

    // Append the newly selected images and errors instead of replacing them
    setSelectedImages((prevImages) => [...prevImages, ...files]);
    setPreviewImages((prevPreviews) => [...prevPreviews, ...newPreviewImages]);
    setUploadErrors((prevErrors) => [...prevErrors, ...newUploadErrors]);

    // Hide the file input field after selection if files are successfully added
    if (newPreviewImages.length > 0) {
      setShowFileInput(false);
    }
  };

  // Check if memberId exists in Firestore
  const checkSupportMemberIdExists = async (memberId) => {
    const result = await getSingleDocWithQuery("supportmembers", [
      ["memberId", "==", memberId],
    ]);
    return result !== false;
  };

  // Handle upload button click
  const handleUpload = async () => {
    setUploading(true);

    for (let image of selectedImages) {
      const filenameWithoutExt = image.name.replace(/\.[^/.]+$/, "");
      const memberIdAsNumber = Number(filenameWithoutExt);
      const fileExtension = image.name.split(".").pop(); // Extract the file extension

      if (uploadErrors.some((error) => error.file.name === image.name)) {
        continue; // Skip invalid files
      }

      const memberData = await getSingleDocWithQuery("supportmembers", [
        ["memberId", "==", memberIdAsNumber],
      ]);

      if (memberData && memberData.length > 0) {
        const { profilePhoto } = memberData[0];
        const imgRef = ref(
          storage,
          `image/supportmembers/${filenameWithoutExt}.${fileExtension}`
        ); // Upload with extension

        // Delete existing image if any
        if (profilePhoto) {
          const oldImageRef = ref(storage, profilePhoto);

          // Try to delete existing file, skip if not found
          try {
            await deleteObject(oldImageRef);
          } catch (error) {
            if (error.code !== "storage/object-not-found") {
              console.error("Error deleting existing image:", error); // Log any error other than file not found
            }
          }
        }

        // Upload new image
        const uploadTask = uploadBytesResumable(imgRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progressValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progressValue);
          },
          (error) => console.error(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Update Firestore with new profile photo URL
            await firebaseUpdateDoc(
              "supportmembers",
              { profilePhoto: downloadURL },
              memberData[0].id
            );
            setProgress(100);
            setUploading(false);

            // Redirect to finish URL using useNavigate
            navigate("/mypage/support-member-image-upload-complete");
          }
        );
      }
    }
  };

  // Remove image from preview list
  const removeImage = (index) => {
    const newImages = [...previewImages];
    const removedFileName = previewImages[index].file.name; // Get the file name being removed

    newImages.splice(index, 1);
    setPreviewImages(newImages);

    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);

    // Also remove the corresponding error (if any) from uploadErrors
    const newUploadErrors = uploadErrors.filter(
      (error) => error.file.name !== removedFileName
    );
    setUploadErrors(newUploadErrors);

    // If all images are removed, show the file input field again
    if (newSelectedImages.length === 0) {
      setShowFileInput(true);
    }
  };

  // Clear all selected images and show file input
  const clearAllImages = () => {
    setSelectedImages([]);
    setPreviewImages([]);
    setUploadErrors([]);
    setShowFileInput(true); // Show the file input field again
    // Ensure the file input is reset only if it exists
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input field
    }
  };

  // Check if the upload button should be disabled
  const isUploadDisabled = () => {
    return (
      uploadErrors.length === selectedImages.length ||
      selectedImages.length === 0
    );
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>画像アップロード</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "画像アップロード", link: "#" },
            { value: "完了", link: "#" },
          ]}
          activeNo={1}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            {/* Only show the file input field if no files are selected */}
            {showFileInput && (
              <Form.Group>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={handleImageChange}
                  ref={fileInputRef} // Attach file input reference
                />
              </Form.Group>
            )}

            <Row className="image-preview-list">
              {previewImages.map((image, index) => (
                <Col className="mt-4" lg={4} sm={3} xs={2} key={index}>
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      {image.file.name}

                      <DeleteIcon
                        className={"text-danger"}
                        onClick={() => removeImage(index)}
                        disabled={uploading} // Disable remove button while uploading
                      />
                    </Card.Header>
                    <Card.Body className="p-0">
                      <div
                        className={`image-preview ${
                          image.isError ? "imageError" : ""
                        }`}>
                        <Image src={image.imageURL} thumbnail />
                        {uploadErrors.some(
                          (error) => error.file.name === image.file.name
                        ) && (
                          <div>
                            <Alert variant="danger">
                              {
                                uploadErrors.find(
                                  (error) => error.file.name === image.file.name
                                ).error
                              }
                            </Alert>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {uploading && (
              <>
                <ProgressBar now={progress} className="mt-4" />
              </>
            )}
            <br />
            <br />
            {/* Show the upload and clear buttons if there are files to upload */}
            {!isUploadDisabled() && (
              <div className="text-center">
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  disabled={uploading} // Disable if already uploading
                >
                  アップロード
                </Button>
                <Button
                  variant="secondary"
                  onClick={clearAllImages}
                  disabled={uploading}
                  className="ms-2">
                  クリア
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SupportMemberImageUpload;
