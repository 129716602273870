import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer6 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        商品装飾展示技能検定試験を実施する都道府県の職業能力開発協会です。<br></br>
        郵送で申請書を取り寄せることもできます。（送料は自己負担になります）
      </p>

      <Link to={"https://www.javada.or.jp/kyoukai/itiran.html"} target="_blank">
        都道府 県職業能力開発協会 : 中央職業能力開発協会（JAVADA）
      </Link>
    </div>
  );
};

export default Answer6;
