import React, { useState } from "react";
import Styles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const { width } = useWindowDimensions();
  const [menuOpening, setMenuOpening] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation(); // Get the current location
  const handleMenuOpen = () => {
    setShowMobileMenu(true);
    setTimeout(() => {
      setMenuOpening(true);
    }, 50);
  };

  const handleMenuClose = () => {
    setMenuOpening(false);
    setTimeout(() => {
      setShowMobileMenu(false);
    }, 500);
  };

  return (
    <>
      <div className={`${Styles.header} ${menuOpening ? Styles.mobileMenuOpeningHeader : ""}`}>
        <div className={Styles.headerContent}>
          <div className={Styles.brand}>
            <Link to={"/"}>
              <img src="/img/logo.webp" alt="Logo" />
            </Link>
            {width <= 576 && <div className={Styles.menubar} onClick={handleMenuOpen} />}
          </div>

          <div className={Styles.links}>
            <Link
              to={"/about-javma"}
              className={`${Styles.link} ${location.pathname == "/about-javma" ? Styles.active : ""}`}
            >
              <p>About</p>
              <span>VMD協会とは</span>
            </Link>
            {width > 576 && (
              <Link
                to={"/licensure-exam"}
                className={`${Styles.link} ${
                  location.pathname.includes("/licensure-exam") ? Styles.active : ""
                }`}
              >
                <p>LICENSURE EXAM</p>
                <span>商品装飾展示技能検定</span>
              </Link>
            )}
            <Link
              to={"/members"}
              className={`${Styles.link} ${
                location.pathname == "/members" || location.pathname.match(/^\/members\/\d+$/)
                  ? Styles.active
                  : ""
              }`}
            >
              <p>MEMBERS</p>
              <span>正会員リスト</span>
            </Link>
            {width > 576 && (
              <Link
                to={"/supporting-members"}
                className={`${Styles.link} ${
                  location.pathname == "/supporting-members" ||
                  location.pathname.match(/^\/supporting-members\/\d+$/)
                    ? Styles.active
                    : ""
                }`}
              >
                <p>SUPPORTING MEMBERS</p>
                <span>賛助会員リスト</span>
              </Link>
            )}
            <Link
              to={"/membership-resgistration/"}
              className={`${Styles.link} ${
                location.pathname.includes("/membership-resgistration") ? Styles.active : ""
              }`}
            >
              <p>JOIN</p>
              <span>入会資料請求</span>
            </Link>
          </div>
        </div>
      </div>

      {showMobileMenu && (
        <div className={`${Styles.mobileMenu} ${menuOpening ? Styles.mobileMenuOpening : ""}`}>
          <div className={Styles.headerContent}>
            <div className={Styles.brand}>
              <Link to={"/"}>
                <img src="/img/logo_footer.webp" alt="Footer Logo" />
              </Link>
              {width <= 576 && <div className={Styles.menubar} onClick={handleMenuClose} />}
            </div>

            <div className={Styles.mobileLinks}>
              <Link to={"/about-javma"}>VMD協会とは</Link>
              <Link to={"/about-vmd"}>VMDとは</Link>
              <Link to={"/events"}>イベント/ニュース</Link>
              <Link to={"/licensure-exam"}>商品装飾展示技能検定</Link>
              <Link to={"/members"}>正会員リスト</Link>
              <Link to={"/supporting-members"}>賛助会員リスト</Link>
              <Link to={"/publication"}>出版物のご案内</Link>
              <Link to={"/membership"}>入会内容</Link>
              <Link to={"/#contactus"}>お問い合わせ/メルマガ購読</Link>

              <div className={Styles.social}>
                <Link to={"https://www.facebook.com/JAVMA2020/"} target="_blank">
                  <img src={"/img/fbsm.webp"} alt="Social Icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
