import React, { useMemo, useRef, useState } from "react";

import ReactQuill from "react-quill";
import { useFormikContext } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { storage } from "../../../../firebase/config";
import { getRandomString } from "../../../../utils/Utils";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CropModal from "../../../../components/Common/ImageCrop/CropModal";

import "react-quill/dist/quill.snow.css";

function EditEventInput() {
  const { width } = useWindowDimensions();
  const { handleChange, handleBlur, setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext();

  const croppedMainPhotoString = "croppedMainPhoto";
  const croppedSubPhoto1String = "croppedSubPhoto1";
  const croppedSubPhoto2String = "croppedSubPhoto2";
  const croppedSubPhoto3String = "croppedSubPhoto3";

  const [mainPhoto, setMainPhoto] = useState(values.mainPhoto || "");
  const [subPhoto1, setSubPhoto1] = useState(values.subPhoto1 || "");
  const [subPhoto2, setSubPhoto2] = useState(values.subPhoto2 || "");
  const [subPhoto3, setSubPhoto3] = useState(values.subPhoto3 || "");
  const [show, setShow] = useState(false);
  const [ratio, setRatio] = useState(1);
  const [localStorageVar, setLocalStorageVar] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const cropMainPhoto = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(1 / 1);
      setLocalStorageVar(croppedMainPhotoString);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cropSubPhoto1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(undefined);
      setLocalStorageVar(croppedSubPhoto1String);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cropSubPhoto2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(undefined);
      setLocalStorageVar(croppedSubPhoto2String);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cropSubPhoto3 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(undefined);
      setLocalStorageVar(croppedSubPhoto3String);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setCropImage = (type, url) => {
    if (type === croppedMainPhotoString) {
      setMainPhoto(url);
    }
    if (type === croppedSubPhoto1String) {
      setSubPhoto1(url);
    }
    if (type === croppedSubPhoto2String) {
      setSubPhoto2(url);
    }
    if (type === croppedSubPhoto3String) {
      setSubPhoto3(url);
    }
  };


  const quillRef = useRef(null);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ align: [] }],
          [
            {
              color: [
                "#000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "#81D8D0",
              ],
            },
            {
              background: [
                "#000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "#81D8D0",
              ],
            },
          ],
          ["image"],
        ],
        handlers: {
          image: () => imageHandler(),
        },
      },
    }),
    []
  );

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        // Use Firebase storage to upload the file
        const imgRef = ref(storage, `image/events/${getRandomString(23)}`);
        await uploadBytes(imgRef, file);
        const fileUrl = await getDownloadURL(imgRef);

        // Get the current editor instance
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        if (range) {
          // Insert the image URL into the editor
          editor.insertEmbed(range.index, "image", fileUrl);
        }
      }
    };
  };


  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          イメージ写真 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 py-2 border-end border-secondary`}
        >
          {mainPhoto === "" ? (
            <>
              <Form.Control
                type="file"
                value={""}
                accept="image/*"
                onChange={(e) => {
                  cropMainPhoto(e);
                  setFieldValue("mainPhoto", e.target.files[0]); // Update Formik's field
                }}
                isInvalid={touched.mainPhoto && errors.mainPhoto} // Show error if touched and invalid
              />
              <Form.Control.Feedback type="invalid">{errors.mainPhoto}</Form.Control.Feedback>
            </>
          ) : (
            <div className="position-relative mx-lg-0 mx-auto" style={{ width: "500px", maxWidth: "95%" }}>
              <img src={mainPhoto} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedMainPhotoString);
                  setMainPhoto("");
                  setFieldValue("mainPhoto", "");
                  const v = {
                    ...JSON.parse(localStorage.getItem("editEventData")),
                    mainPhoto: ""
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem("editEventData", serializedState);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          イメージ写真 1
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {subPhoto1 === "" ? (
            <Form.Control
              type="file"
              value={""}
              accept="image/*"
              onChange={(e) => {
                cropSubPhoto1(e);
              }}
            />
          ) : (
            <div className="position-relative mx-lg-0 mx-auto" style={{ width: "500px", maxWidth: "95%" }}>
              <img src={subPhoto1} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedSubPhoto1String);
                  setSubPhoto1("");
                  setFieldValue("subPhoto1", "");
                  const v = {
                    ...JSON.parse(localStorage.getItem("editEventData")),
                    subPhoto1: ""
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem("editEventData", serializedState);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          イメージ写真 2
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {subPhoto2 === "" ? (
            <Form.Control
              type="file"
              value={""}
              accept="image/*"
              onChange={(e) => {
                cropSubPhoto2(e);
              }}
            />
          ) : (
            <div className="position-relative mx-lg-0 mx-auto" style={{ width: "500px", maxWidth: "95%" }}>
              <img src={subPhoto2} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedSubPhoto2String);
                  setSubPhoto2("");
                  setFieldValue("subPhoto2", "");
                  const v = {
                    ...JSON.parse(localStorage.getItem("editEventData")),
                    subPhoto2: ""
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem("editEventData", serializedState);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          イメージ写真 3
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {subPhoto3 === "" ? (
            <Form.Control
              type="file"
              value={""}
              accept="image/*"
              onChange={(e) => {
                cropSubPhoto3(e);
              }}
            />
          ) : (
            <div className="position-relative mx-lg-0 mx-auto" style={{ width: "500px", maxWidth: "95%" }}>
              <img src={subPhoto3} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedSubPhoto3String);
                  setSubPhoto3("");
                  setFieldValue("subPhoto3", "");
                  const v = {
                    ...JSON.parse(localStorage.getItem("editEventData")),
                    subPhoto3: ""
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem("editEventData", serializedState);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          タイトル <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            name="title"
            isInvalid={touched.title && errors.title}
            isValid={touched.title && !errors.title}
          />
          <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          詳細
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {/* <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              isInvalid={touched.description && errors.description}
              isValid={touched.description && !errors.description}
            />
            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
          </Form.Group> */}

<ReactQuill
            ref={quillRef}
            theme="snow"
            modules={modules}
            value={values.description}
            onChange={(value, _, __, editor) => {
              // console.log(value)
              if (editor.getLength() === 1) {
                setFieldValue("description", "");
              } else {
                setFieldValue("description", value);
              }
            }}
            onBlur={() => {
              setFieldTouched("description", true);
            }}
            className={
              touched.description && errors.description
                ? "border border-danger"
                : ""
            }
          />
        </Col>
      </Row>

      <CropModal
        show={show}
        setShow={setShow}
        setImage={setCropImage}
        imgSrc={imgSrc}
        aspect={ratio}
        localStorageVar={localStorageVar}
      />
    </>
  );
}

export default EditEventInput;
