import React, { useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "react-bootstrap";

import ProgressStep from "../../../../components/progressStep/ProgressStep";

function CreateMemberComplete() {
  const helmetData = new HelmetData({});

  useEffect(() => {
    localStorage.removeItem("newMemberData");
  }, []);

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex  my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            { value: "確認", link: "#" },
            { value: "完了", link: "#" },
          ]}
          activeNo={3}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Alert variant="success" className="text-center my-3">
              完了しました
            </Alert>

            <div className="text-center mt-4">
              <Link
                className="mb-3 me-3 btn btn-secondary"
                to={"/mypage/create-member"}>
                <b>別の会員新規作成</b>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateMemberComplete;
