import React from 'react'
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Styles from './MessageSent.module.css'; 
const MessageSent = () => {
  return (
    <>
    <Header />
    <Container>
        <Row className="justify-content-center">
            <Col xs={12} sm={10}>

            <div className={Styles.sent}>
            <p>送信完了 </p>
            </div>
            <div className={Styles.thanks}>
            <p>この度はお問い合わせを頂きありがとうございました。<br></br>
            改めて、事務局よりご連絡をさせて頂きますのでしばらくお待ちください。</p>
            </div>
            <div className={Styles.receive}>
              <p>1週間以上経っても事務局から連絡がない場合は、<br></br>
              お手数をおかけいたしますが、以下の事務局までお問い合わせください。</p>
            </div>
            <div className={Styles.email}>
              <p>日本ビジュアルマーチャンダイジング協会 <br></br>
              info@javma.com</p>
            </div>

            </Col>
        </Row>
    </Container>    

    <Footer />
    
    </>
  )
}

export default MessageSent