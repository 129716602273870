export const TITLES = [
  {
    url: "/",
    title: "JAVMA 日本ビジュアルマーチャンダイジング協会",
    description:
      "当協会は、ビジュアルマーチャンダイジング（VMD）に関わる情報の収集と発信を行い、理論に裏付けされた技術の向上、能力開発、人材育成を目的にした会員相互の交流研鑽を行う団体です。 国家資格「商品装飾展示技能検定」支援のほか、各種セミナーの開催や出版を行なっています。",
    ogp: "/img/ogp.webp",
  },
  {
    url: "/about-javma",
    title: "VMD協会とは",
    description: "これは説明です",
  },
  {
    url: "/members",
    title: "会員リスト",
    description: "これは説明です",
  },
  {
    url: "/supporting-members",
    title: "賛助会員リスト",
    description: "これは説明です",
  },
  {
    url: "/events",
    title: "イベントリスト",
    description: "これは説明です",
  },
  {
    url: "/events/:id",
    title: "Events",
    description: "これは説明です",
  },
  {
    url: "/licensure-exam",
    title: "商品装飾展示",
    description: "これは説明です",
  },
  {
    url: "/licensure-exam-faq/",
    title: "技能検定についてよくあるお問い合わせ",
    description: "これは説明です",
  },
  {
    url: "/publication",
    title: "出版物のご案内",
    description: "これは説明です",
  },
  {
    url: "/membership",
    title: "入会内容",
    description: "これは説明です",
  },
  {
    url: "/membership-resgistration",
    title: "新規会員申し込み",
    description: "これは説明です",
  },
  {
    url: "/privacy-policy",
    title: "プライバシーポリシー",
    description: "これは説明です",
  },
  {
    url: "/contact",
    title: "お問い合わせ",
    description: "これは説明です",
  },
  {
    url: "/about-vmd",
    title: "VMDとは|JAVMA 日本ビジュアルマーチャンダイジング協会",
    description:
      "VMDとは|当協会は、ビジュアルマーチャンダイジング（VMD）に関わる情報の収集と発信を行い、理論に裏付けされた技術の向上、能力開発、人材育成を目的にした会員相互の交流研鑽を行う団体です。国家資格「商品装飾展示技能検定」支援のほか、各種セミナーの開催や出版を行なっています。",
  },
];
