import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { storage } from "../../../../firebase/config";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import { useSetDoc } from "../../../../hooks/useSetDoc";

function EditSupportMemberCheck() {
  const params = useParams();
  const backData =
    JSON.parse(localStorage.getItem("editSupportMemberData")) || false;
  // const { addADoc, isPending, error } = useAddDocs();
  const { firebaseSetDocQuery, isPending, error } = useSetDoc();

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const helmetData = new HelmetData({});

  useEffect(() => {
    if (!backData) {
      navigate(`/mypage/edit-support-member/${params.memberId}`);
    }
  }, []);

  async function uploadToStorage(url, type = "") {
    const imgRef = ref(
      storage,
      `image/supportmembers/${backData.memberId}.jpeg`
    );
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  const registerMember = async () => {
    if (
      backData.profilePhoto &&
      backData.profilePhoto.substring(0, 4) === "data"
    ) {
      backData.profilePhoto = await uploadToStorage(backData.profilePhoto);
    }
    await firebaseSetDocQuery(
      "supportmembers",
      {
        ...backData,
        memberId: parseInt(`${backData.memberId}`),
      },
      [["memberId", "==", parseInt(params.memberId)]],
      true
    ).then((res) => {
      navigate("/mypage/edit-support-member/completed");
    });
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={2}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}>
                イメージ写真
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 py-2 border-end border-secondary`}>
                {backData.profilePhoto && (
                  <img
                    src={backData.profilePhoto}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                会員番号<span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.memberId}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                企業名<span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.company_name}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                郵便番号
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.postal_code}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                都道府県
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.prefecture}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                勤務先住所
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.work_address}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                電話
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.phone}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                URL
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.url}
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="text-center mt-4">
          <Link
            to={`/mypage/edit-support-member/${params.memberId}`}
            className="m-2 btn  btn-secondary">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending && (
            <Button className="m-2" variant="primary" onClick={registerMember}>
              登録する <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {isPending && (
            <Button className="m-2" variant="primary" disabled>
              登録する <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}

export default EditSupportMemberCheck;
