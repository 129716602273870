import React, { useEffect, useState } from "react";

import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";

import styles from "./AccountDetails.module.css";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import {
  getFormatedDate,
  getGroupName,
  getPrivateStatus,
  getRandomInt,
} from "../../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function AccountDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowDimensions();

  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();

  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await getSingleDocWithQuery("members", [
        ["memberId", "==", parseInt(params.memberId)],
      ])
        .then((res) => {
          setUserDetails(res);
        })
        .catch((e) => console.log(e));
    }
    fetchData();
  }, []);

  return (
    <>
      {userDetails && userDetails[0] && (
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <span className="text-nowrap">
                    作成日: {getFormatedDate(userDetails[0].createdAt)}
                  </span>
                  <div className="d-flex">
                    {getPrivateStatus(userDetails[0].private)}
                    {userDetails[0].position &&
                      `　表示順:${userDetails[0].position}`}
                  </div>
                  <Button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      localStorage.removeItem("editMemberData");
                      navigate(
                        `/mypage/edit-member/${userDetails[0].memberId}`
                      );
                    }}>
                    <FontAwesomeIcon icon={faEdit} /> 編集
                  </Button>
                </Card.Header>
                <Card.Body className="text-center">
                  <div>
                    {userDetails[0].profilePhoto ? (
                      <img
                        src={userDetails[0].profilePhoto}
                        className={`rounded-circle`}
                        alt="user"
                        style={{ maxWidth: "200px" }}
                      />
                    ) : (
                      <img
                        src="/img/no_user_pic.png"
                        className={`rounded-circle`}
                        alt="user"
                        style={{ maxWidth: "200px" }}
                      />
                    )}
                  </div>
                  <br />
                  <>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 &&
                          `border-bottom border-top border-start `
                        } border-secondary p-2`}>
                        会員番号<span className="text-danger">※</span>
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-top border-start border-bottom border-end`
                        } p-1 py-2 border-end border-secondary`}>
                        {userDetails[0].memberId}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        名前<span className="text-danger">※</span>
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].name}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        フリガナ<span className="text-danger">※</span>
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].furigana}
                      </Col>
                    </Row>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        勤務先
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].place_of_employment}
                      </Col>
                    </Row>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        事業形態
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].business_type}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        部署
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].department}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        肩書
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].title}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        郵便番号
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].postal_code}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        都道府県
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].prefecture}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        勤務先住所
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].work_address}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        メールアドレス
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].email}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        電話
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].phone}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        携帯電話
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].mobile_phone}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        URL
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].url}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        専門分野
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].area_of_expertise}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        職業名 or 職種
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].occupation}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        所属チーム
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].team}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        所属エリア
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].area}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        自己紹介
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails[0].self_introduction && <div
                          dangerouslySetInnerHTML={{
                            __html: userDetails[0].self_introduction.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br>"
                            ),
                          }}></div>}
                      </Col>
                    </Row>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default AccountDetails;
