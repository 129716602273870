import React, { useEffect, useState } from "react";

import { Button, Card, Container } from "react-bootstrap";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { doc, writeBatch } from "firebase/firestore";

import { db } from "../../../firebase/config";
import { useCollection } from "../../../hooks/useCollection";
import Styles from "./ReOrderExamHistory.module.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import EHDeleteModal from "./EHDeleteModal";

const ItemTypes = {
  CARD: "card",
};

const DraggableUserCard = ({
  member,
  index,
  moveCard,
  onDrop,
  getCurrentExamHistory,
  deleteUser,
}) => {
  const navigator = useNavigate();
  const ref = React.useRef(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: member.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const currentUsers = getCurrentExamHistory();
      onDrop(currentUsers);
    },
  });

  drag(drop(ref));

  // Apply the 'dragging' class based on isDragging state
  const cardClassName = `dnd-card ${isDragging ? "dragging" : ""}`;

  return (
    <div ref={ref} className={cardClassName}>
      <Card className={Styles.cardItem}>
        <Card.Header className="d-flex justify-content-between">
          <span>位置: {index + 1} </span>
          <div>
            <Button
              size="sm"
              onClick={() => {
                navigator("/mypage/create-exam-history", {
                  state: {
                    ...member,
                  },
                });
              }}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            　
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                deleteUser(member);
              }}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </Card.Header>
        <Card.Body className=" p-0">
          <div className="px-2 my-2">
            <Link
              target="_blank"
              to={"/licensure-exam-history/" + member.id}
              className={Styles.groupName}>
              {member.title}
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const ReOrderExamHistory = () => {
  const [examHistory, setExamHistory] = useState([]);
  const { documents: examHistoryData, getMultipleDocs } = useCollection();

  const loadData = () => {
    getMultipleDocs({
      _collection: "examHistory",
      _query: [],
      orderByField: "position",
      orderByOption: "desc",
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (examHistoryData) {
      setExamHistory(examHistoryData);
    }
  }, [examHistoryData]);

  const moveCard = (dragIndex, hoverIndex) => {
    setExamHistory((prevUsers) => {
      const newGroups = [...prevUsers];
      const dragCard = newGroups[dragIndex];
      newGroups.splice(dragIndex, 1);
      newGroups.splice(hoverIndex, 0, dragCard);
      return newGroups;
    });
  };

  const getCurrentExamHistory = () => examHistory;

  const updatePosition = async (newGroups) => {
    const batch = writeBatch(db);
    newGroups.forEach(async (item, index) => {
      const itemRef = doc(db, "examHistory", item.id);
      batch.set(itemRef, { position: newGroups.length - index }, { merge: true });
    });

    await batch.commit();
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const deleteUser = async (data) => {
    setDeleteData(data);
    handleDeleteShow();
  };

  const updateByDeleteUser = (userId) => {
    // setExamHistory((prev) => {
    //   let newUsers = { ...prev };
    //   return newUsers;
    // });

    const filteredData = examHistory.filter(item => item.id !== userId);
    setExamHistory(filteredData);
    // console.log(examHistory, userId);

  };

  return (
    <>
      <Container>
        <h4>リスト一覧と表示順変更</h4>
        {examHistory && (
          <>
            <DndProvider backend={HTML5Backend}>
              <div className={Styles.groupContainer}>
                {examHistory.map((member, index) => (
                  <DraggableUserCard
                    key={member.id}
                    member={member}
                    index={index}
                    moveCard={moveCard}
                    onDrop={updatePosition}
                    getCurrentExamHistory={getCurrentExamHistory}
                    deleteUser={deleteUser}
                  />
                ))}
              </div>
            </DndProvider>
          </>
        )}
      </Container>

      <EHDeleteModal
        data={deleteData}
        show={showDelete}
        handleClose={handleDeleteClose}
        updateByDeleteUser={updateByDeleteUser}
      />
    </>
  );
};

export default ReOrderExamHistory;
