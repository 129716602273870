import { useEffect, useState } from "react";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

export const useSendEmail = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const sendMail = (mailOptions) => {
    setIsPending(true);
    setIsConfirmed(false);
    setError(null);
    const sendMail = httpsCallable(functions, "sendMail");
    sendMail({ ...mailOptions })
      .then((res) => {
        setIsPending(false);
        setIsConfirmed(true);
        // console.log("ok", res);
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
        // console.log("err", err);
      });
  };

  return { sendMail, isPending, isConfirmed, error };
};
