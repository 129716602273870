import React from "react";
import Header from "../../components/Universal/Header/Header";
import Styles from "./Publication.module.css";
import Footer from "../../components/Universal/Footer/Footer";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaData from "../../utils/MetaData";
import Purchase from "../Purchase/Purchase";
const Publication = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.publication}>
        <div className={Styles.pageIntro}>
          <Container className={Styles.container}>
            <div className={Styles.title}>PUBLICATIONS</div>
            <div className={Styles.subtitle}>出版物のご案内</div>
          </Container>
        </div>
        {width > 576 && (
          <>
            <Container className="mt-sm-5">
              <Row>
                <Col xs={12} sm={9}>
                  <p className={Styles.introTitle}>「新版VMD用語事典」 日本VMD協会著</p>
                  <p className={Styles.introTitle2}>
                    2023年4月10日に出版されました。<br></br>
                    約1370語を収録！<br></br>
                    ビジュアルマーチャンダイザー、ＶＭＤコーディネーター、デコレーター、<br></br>
                    販促担当者、販売員の方々のための、現場で生きるＶＭＤの知識と技術を満載。
                  </p>

                  <p className={Styles.introTitle3}>
                    リアルショップ、ウェブショップ共に企業戦略活動として必要とされるVMDの関連語を<br></br>
                    幅広く網羅した新版の用語事典です。<br></br>
                    VMDの専門家集団である日本ビジュアルマーチャンダイジング協会が総力を上げて編纂しました。
                  </p>

                  <div className={Styles.boxText}>
                    【本書の特徴】　<br></br>
                    ・商品企画から販売までの一貫した「ビジュアル戦略」に求められる用語に特化した事典。
                    <br></br>
                    ・ビジュアルマーチャンダイジング（VMD）の商品知識、関連情報まで実践的に掲載。<br></br>
                    ・VMDの現場に必要な「時代感」を盛り込んだ解説と分かりやすいイラスト表現。<br></br>
                    ・技能資格の国家検定「商品装飾展示技能検定」学科試験の必読書。<br></br>
                    ・コンパクトで携帯しやすいサイズ感。<br></br>
                  </div>
                  <div className={Styles.newText}>
                    ご購入は、直接、繊研新聞社出版部までメールでご連絡ください。<br></br>
                    申込メールには必ず郵便番号、住所、氏名、電話番号、書名、何冊かをご記入ください。<br></br>
                    メールアドレス：publishing@senken.co.jp
                  </div>
                </Col>
                <Col xs={12} sm={3} className="px-0">
                  <img src="/img/vmdg.webp" className={Styles.vmdImg} />
                  <p className={Styles.vmdtxt}>
                    タイトル／VMD用語事典 最新・売る戦略・見せる技術のキーワード集<br></br>
                    編著／日本ビジュアルマーチャンダイジング協会<br></br>
                    発行／繊研新聞社<br></br>
                    定価／本体3,000円+税
                  </p>
                </Col>
              </Row>
            </Container>

            <div className={Styles.bgdark}>
              <Container>
                <Row>
                  <Col sm={8}>
                    <p className={Styles.intro1}>
                      <span>「</span>国家検定 商品装飾展示技能検定ガイドブック」<br></br>
                      改訂第3版 日本VMD協会編著
                    </p>
                    <p className={Styles.intro2}>
                      本書は、全国から寄せられた多くの受検者のご要望にお応えするために企画され、
                      <br />
                      2006 年に初版が発行されました。
                      <br></br>
                      国家検定「商品装飾展示技能検定」受検希望の方のみならず、
                      <br />
                      同時に商品装飾展示（マーチャンダイズプレゼンテーション）にかかわる
                      <br></br>
                      すべての方々が必要とする知識と技法の基本をまとめた書でもあります。
                    </p>

                    <div className={Styles.greenBG}>
                      <span>【</span>本書の特徴】　<br></br>
                      デコレーター、ビジュアルコーディネイター、ビジュアルマーチャンダイザー、ショップディレクター、販売スタッフ、マーチャンダイザー、販促担当者、VMD教育関係者、関連学校の指導者や講師の方々にとって必携のガイドブックです。
                      <br></br>
                      改訂第3版として本のサイズもＢ5版と大きくなり大幅にリニューアルされた本書は、「学科編」と「実技編」を分けた2部（2冊）構成となっています。そして新しい時代性も踏まえた基礎知識と技法に加え、平成27年度から平成30年度まで4年分の各級学科試験・実技試験の過去問題と、わかりやすいポイント解説が収録されています。
                    </div>
                  </Col>
                  <Col sm={4} className="px-0">
                    <img src="/img/magazine_vmd.webp" className={Styles.magazine_vmd} />
                    <p className={Styles.vmdtxt}>
                      タイトル／改訂第3版 国家検定 商品装飾展示技能検定ガイドブック「学科編」
                      Ｂ5版　全194ページ
                      <br></br>
                      編著／日本ビジュアルマーチャンダイジング協会<br></br>
                      発行／繊研新聞社<br></br>
                      定価／本体￥2,200＋税<br></br>
                      タイトル／改訂第3版 国家検定 商品装飾展示技能検定ガイドブック「実技編」
                      Ｂ5版　全214ページ
                      <br></br>
                      編著／日本ビジュアルマーチャンダイジング協会<br></br>
                      発行／繊研新聞社<br></br>
                      定価／本体￥2,200＋税
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
            <Purchase />
          </>
        )}

        {width < 576 && (
          <>
            <Container className="mt-5">
              <div className={Styles.mobileVmdG}>
                <img src="/img/vmdg.webp" className={Styles.vmdImg} />
              </div>
              <Row className="px-2">
                <Col xs={7}>
                  <p className={Styles.mbileIntro}>
                    <span>「</span>新版VMD用語事典」<br></br>　日本VMD協会著
                  </p>
                </Col>
                <Col xs={5}>
                  <p className={Styles.mbileIntroDate}>2023年4月10日</p>
                </Col>

                <Col xs={12}>
                  <p className={Styles.mobileIntro}>
                    約1370語を収録！<br></br>
                    ビジュアルマーチャンダイザー、ＶＭＤコーディネーター、デコレーター、販促担当者、販売員の方々のための、現場で生きるＶＭＤの知識と技術を満載。
                  </p>

                  <p className={Styles.mobileIntro}>
                    リアルショップ、ウェブショップ共に企業戦略活動として必要とされるVMDの関連語を幅広く網羅した新版の用語事典です。VMDの専門家集団である日本ビジュアルマーチャンダイジング協会が総力を上げて編纂しました。
                  </p>
                </Col>
              </Row>
            </Container>

            <div className={Styles.mobileGreen}>
              【本書の特徴】<br></br>
              ・商品企画から販売までの一貫した「ビジュアル戦略」に求められる用語に特化した事典。<br></br>
              <br></br>
              ・ビジュアルマーチャンダイジング（VMD）の商品知識、関連情報まで実践的に掲載。<br></br>
              <br></br>
              ・VMDの現場に必要な「時代感」を盛り込んだ解説と分かりやすいイラスト表現。<br></br>
              <br></br>
              ・技能資格の国家検定「商品装飾展示技能検定」学科試験の必読書。<br></br>
              <br></br>
              ・コンパクトで携帯しやすいサイズ感。
            </div>

            <div className={Styles.newTextMobile}>
              ご購入は、直接、繊研新聞社出版部までメールでご連絡ください。<br></br>
              <br></br>
              申込メールには必ず郵便番号、住所、氏名、電話番号、書名、何冊かをご記入ください。<br></br>
              <br></br>
              メールアドレス：publishing@senken.co.jp
              <br></br>
            </div>

            <Container>
              <p className={Styles.mobileVmdTxt}>
                タイトル/VMD用語事典 最新・売る戦略・見せる技術のキーワード集<br></br>
                編著/日本ビジュアルマーチャンダイジング協会<br></br>
                発行/繊研新聞社<br></br>
                定価/本体3,000円+税
              </p>
            </Container>

            <div className={Styles.mobileDarkBg}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={11}>
                    <div className={Styles.imgContainer}>
                      <img src="/img/magazine_vmd.webp" className={Styles.magazine_vmd_mobile} />
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col xs={11}>
                    <p className={`${Styles.mbileIntro} mb-0`}>
                      <span>「国家検定 商品装飾展示技能検定</span>
                      <br></br>
                      ガイドブック」<br></br>
                      改訂第3版 日本VMD協会編著
                    </p>
                  </Col>

                  <Col xs={11} className="d-flex">
                    <p className={Styles.mbileIntro}>日本VMD協会著</p>
                    <p className={`${Styles.mbileIntro} ms-auto`}>2019年5月31日</p>
                  </Col>

                  <Col xs={11} className="mt-2">
                    <p className={Styles.mobileContentText}>
                      2019年5月31日、日本ビジュアルマーチャンダイジング協会編著となる「改訂第3版 国家検定
                      商品装飾展示技能検定 ガイドブック」が繊研新聞社より発売されました。
                    </p>
                    <p className={Styles.mobileContentText}>
                      本書は、全国から寄せられた多くの受検者のご要望にお応えするために企画され、2006年に初版が発行されました。国家検定「商品装飾展示技能検定」受検希望の方のみならず、同時に商品装飾展示（マーチャンダイズプレゼンテーション）にかかわるすべての方々が必要とする知識と技法の基本をまとめた書でもあります。
                      <br></br>
                      なお、初めてご購入の方は２冊セットでのご購入をお勧めします。
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className={`${Styles.mobileGreen} ${Styles.movetoTop}`}>
              【本書の特徴】<br></br>
              デコレーター、ビジュアルコーディネイター、ビジュアルマーチャンダイザー、ショップディレクター、販売スタッフ、マーチャンダイザー、販促担当者、VMD教育関係者、関連学校の指導者や講師の方々にとって必携のガイドブックです。改訂第3版として本のサイズもＢ5版と大きくなり大幅にリニューアルされた本書は、「学科編」と「実技編」を分けた2部（2冊）構成となっています。そして新しい時代性も踏まえた基礎知識と技法に加え、平成27年度から平成30年度まで4年分の各級学科試験・実技試験の過去問題と、わかりやすいポイント解説が収録されています。
            </div>

            <Container>
              <p className={Styles.mobileVmdTxt}>
                タイトル/改訂第3版 国家検定 商品装飾展示技能検定ガイドブック<br></br>
                編著/「学科編」 Ｂ5版　全194ページ<br></br>
                編著/日本ビジュアルマーチャンダイジング協会<br></br>
                発行/繊研新聞社<br></br>
                定価/本体￥2,200+税
              </p>

              <p className={Styles.mobileVmdTxt}>
                タイトル/改訂第3版 国家検定 商品装飾展示技能検定ガイドブック<br></br>
                「実技編」 Ｂ5版　全214ページ<br></br>
                編著/日本ビジュアルマーチャンダイジング協会<br></br>
                発行/繊研新聞社<br></br>
                定価/本体￥2,200+税
              </p>
            </Container>

            <Purchase />
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Publication;
