import React from "react";

import _ from "lodash";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import CreateSupportMemberInput from "./CreateSupportMemberInput";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import {
  email,
  openStringSchema,
  phoneSchemaBoth,
  numberOnlySchema,
  kanaOnlySchema,
} from "../../../../utils/InputSchema";

function CreateSupportMember() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  const helmetData = new HelmetData({});

  const backData = localStorage.getItem("newSupportMemberData");

  let initialValues = {
    memberId: "", //会員番号
    company_name: "", //企業名
    postal_code: "", //郵便番号
    prefecture: "", //都道府県
    work_address: "", //勤務先住所
    phone: "", // 電話
    url: "", //URL
  };
  if (backData) {
    console.log("backData", backData);
    initialValues = {
      ...JSON.parse(localStorage.getItem("newSupportMemberData")),
    };
  }
  const schema = yup.object().shape({
    //会員番号
    memberId: numberOnlySchema().required("この欄は必須です").test(
      "is-memberId-unique",
      "ご登録会員番号がすでに登録済み、もしくは使用できない情報です",
      async (value) => {
        if (value) {
          let user = await getSingleDocWithQuery("supportmembers", [
            ["memberId", "==", value],
          ]);
          return !user;
        }
        return true;
      }
    ),
    //名前
    company_name: openStringSchema(32).required("この欄は必須です"),
    postal_code: openStringSchema(1000),
    //都道府県
    prefecture: openStringSchema(1000),
    //勤務先住所
    work_address: openStringSchema(1000),
    // 電話
    phone: phoneSchemaBoth(),
    //専門分野
    url: yup.string().nullable().url("有効なURLを入力してください"),
  });

  const handleSubmit = (values) => {
    const profilePhoto =
      localStorage.getItem("croppedProfilePhoto") ||
      (_.has(JSON.parse(backData), "profilePhoto")
        ? JSON.parse(backData).profilePhoto
        : null);
    // console.log(profilePhoto);
    if (profilePhoto && profilePhoto !== undefined) {
      values.profilePhoto = profilePhoto;
      localStorage.removeItem("croppedProfilePhoto");
    } else {
      values.profilePhoto = "";
    }

    try {
      const serializedState = JSON.stringify(values);
      console.log("serializedState", serializedState);
      localStorage.setItem("newSupportMemberData", serializedState);
      navigate("/mypage/create-support-member/check");
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: backData ? "/mypage/group-page-check" : "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={1}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}>
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <CreateSupportMemberInput />

                  <div className="text-center mt-4 mb-3">
                    <Button className="" variant="primary" type="submit">
                      <b>
                        次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                      </b>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateSupportMember;
