import React from "react";
import Styles from "./Footer.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Footer = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={Styles.footer}>
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <Link to={"/"} className={Styles.brand}>
              <img src="/img/logo_footer.webp" />
            </Link>
          </Col>

          {width <= 576 && (
            <Col sm={12} className="mt-4 py-2">
              <div className={Styles.mobileItem}>
                <p>ABOUT</p>
                <p>
                  <Link to={"/about-javma"}>VMD協会とは</Link>
                  <Link to={"/about-vmd"} className="ms-3">
                    VMDとは
                  </Link>
                </p>
              </div>

              <Link to={"/events"} className={Styles.mobileItem}>
                <p>EVENTS/NEWS</p>
                <p>イベント/ニュース</p>
              </Link>

              <Link to={"/licensure-exam"} className={Styles.mobileItem}>
                <p>LICENSURE EXAM</p>
                <p>商品装飾展示技能検定</p>
              </Link>

              <Link to={"/members"} className={Styles.mobileItem}>
                <p>MEMBERSHIP LIST</p>
                <p>正会員リスト</p>
              </Link>

              <Link to={"/supporting-members"} className={Styles.mobileItem}>
                <p>SUPPORTING MEMBER</p>
                <p>賛助会員リスト</p>
              </Link>

              <Link to={"/publication"} className={Styles.mobileItem}>
                <p>PUBLICATIONS</p>
                <p>出版物のご案内</p>
              </Link>

              <Link to={"/membership"} className={Styles.mobileItem}>
                <p>MEMBERSHIP</p>
                <p>入会内容</p>
              </Link>

              <Link to={"/#contactus"} className={Styles.mobileItem}>
                <p>CONTACT US</p>
                <p>お問い合わせ/メルマガ購読</p>
              </Link>
            </Col>
          )}

          {width <= 576 && (
            <Col sm={12} className={Styles.social}>
              <Link to={"https://www.facebook.com/JAVMA2020/"} target="_blank" className={Styles.socialLink}>
                <img src="/img/fb.webp" />
              </Link>
            </Col>
          )}

          <Col sm={12} className={Styles.links}>
            <Link to={"/contact"} className={Styles.link}>
              お問い合わせ
            </Link>
            <Link to={"/privacy-policy"} className={Styles.link}>
              プライバシーポリシー
            </Link>
          </Col>

          {width > 576 && (
            <Col sm={12} className={Styles.social}>
              <Link to={"https://www.facebook.com/JAVMA2020/"} className={Styles.socialLink}>
                <img src="/img/fb.webp" />
              </Link>
            </Col>
          )}
        </Row>
      </Container>

      <hr className={Styles.hr} />
      <p className={Styles.copyright}>Copyright ©JAVMA All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
