import React, { useEffect } from "react";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { storage } from "../../../../firebase/config";
import { useAggregation } from "../../../../hooks/useAggregation";

function CreateMemberCheck() {
  const backData = JSON.parse(localStorage.getItem("newMemberData")) || false;
  const { addADoc, isPending, error } = useAddDocs();
  const { countWithQuery } = useAggregation();

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const helmetData = new HelmetData({});

  useEffect(() => {
    if (!backData) {
      navigate("/mypage/create-member");
    }
  }, []);

  async function uploadToStorage(url, type = "") {
    const imgRef = ref(storage, `image/members/${backData.memberId}.jpeg`);
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  const registerMember = async () => {
    const count = await countWithQuery("members");
    if (
      backData.profilePhoto &&
      backData.profilePhoto.substring(0, 4) === "data"
    ) {
      backData.profilePhoto = await uploadToStorage(backData.profilePhoto);
    }
    await addADoc("members", {
      ...backData,
      memberId: parseInt(`${backData.memberId}`),
      view: 0,
      private: false,
      status: "PUBLISHED",
      position: count + 1,
    }).then((res) => {
      navigate("/mypage/create-member/completed");
    });
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={2}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}>
                イメージ写真
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 py-2 border-end border-secondary`}>
                {backData.profilePhoto && (
                  <img
                    src={backData.profilePhoto}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                会員番号<span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.memberId}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                名前<span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.name}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                フリガナ<span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.furigana}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                勤務先
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.place_of_employment}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                事業形態
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.business_type}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                部署
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.department}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                肩書
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.title}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                郵便番号
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.postal_code}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                都道府県
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.prefecture}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                勤務先住所
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.work_address}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                メールアドレス
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.email}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                電話
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.phone}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                携帯電話
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.mobile_phone}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                URL
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.url}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                専門分野
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.area_of_expertise}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                職業名 or 職種
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.occupation}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                所属チーム
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.team}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                所属エリア
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.area}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                自己紹介
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.self_introduction && <div
                  dangerouslySetInnerHTML={{
                    __html: backData.self_introduction.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br>"
                    ),
                  }}></div>}
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="text-center mt-4">
          <Link to={"/mypage/create-member"} className="m-2 btn  btn-secondary">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending && (
            <Button className="m-2" variant="primary" onClick={registerMember}>
              登録する <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {isPending && (
            <Button className="m-2" variant="primary" disabled>
              登録する <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}

export default CreateMemberCheck;
