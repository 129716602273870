import React from "react";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Header />
      <div className={styles.privacy}>
        <div className={styles.pageIntro}>
          <Container className={styles.container}>
            <div className={styles.title}>PRIVACY POLICY</div>
            <div className={styles.subtitle}>プライバシーポリシー</div>
          </Container>
        </div>

        <Container className={styles.container}>
          <Row className="justify-content-center">
            <Col xs={11} sm={10}>
              <div className={styles.introTitle}>
                <p>
                  日本ビジュアルマーチャンダイジング協会（以下、当協会）のお客様情報（以下「個人情報」）の利用目的については下記の通りです。
                  <br />
                  当協会では、今後ともお客様のお役に立つ情報を提供したいと考えています。お客様の個人情報を取り扱うに当たって、その重要性を認識し、個人情報保護に関する法律・法令などを遵守して、個人情報を適切に取り扱います。
                </p>
              </div>

              <div>
                <Row className="justify-content-center mb-5">
                  <Col sm={12}>
                    <h5 className={styles.privacyHeading}>
                      {" "}
                      プライバシーポリシーについての考え方が適用される範囲
                    </h5>
                    <p className={styles.privacySubtitle}>
                      当協会が個人情報を取得する利用目的は次の通りです。ここに定めのない目的で取得する場合は、利用者の個人情報を取得するときに、あらかじめ利用目的を明示いたします。
                    </p>
                    <ul className={styles.listInfo}>
                      <li>1.利用者に、供給情報および、これらに関連した活動を連絡するため。</li>
                      <li>2.利用者の個人認証および審査をするため。</li>
                      <li>
                        3.個人情報を統計的に集計・分析し、個人を識別・特定できない形態に加工した統計データを作成するため。
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div>
                <Row className="justify-content-center mb-5">
                  <Col sm={12}>
                    <h5 className={styles.privacyHeading}> 適正な取得</h5>
                    <p className={styles.privacySubtitle}>
                      当協会が個人情当協会は利用者の個人情報を偽り、その他不正な手段で取得することはいたしません。
                      当協会は利用者の個人情報を「利用目的」で定めた範囲内で利用します。
                    </p>
                  </Col>
                </Row>
              </div>

              <div>
                <Row className="justify-content-center mb-5">
                  <Col sm={12}>
                    <h5 className={styles.privacyHeading}> 第三者への提供</h5>
                    <p className={styles.privacySubtitle}>
                      当協会は、次の場合を除き個人情報を第三者に提供することはいたしません。
                    </p>
                    <ul className={styles.listInfo}>
                      <li>1.利用者によりあらかじめ同意を得ている会社・団体に提供する場合。</li>
                      <li>2.法令に基づく場合。</li>
                      <li>
                        3.人の生命、身体または財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき。
                      </li>
                      <li>
                        4.公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき。
                      </li>
                      <li>
                        5.国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障をおよぼす恐れがあるとき。
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div>
                <Row className="justify-content-center mb-5">
                  <Col sm={12}>
                    <h5 className={styles.privacyHeading}> 安全管理</h5>
                    <p className={styles.privacySubtitle}>
                      当協会は取り扱う個人情報の漏えい、滅失または、き損の防止その他の安全管理のための必要かつ適切な措置を講じます。
                    </p>
                  </Col>
                </Row>
              </div>

              <div>
                <Row className="justify-content-center mb-5">
                  <Col sm={12}>
                    <h5 className={styles.privacyHeading}> その他</h5>
                    <p className={styles.privacySubtitle}>
                      当協会では利用者の個人情報の保護を図るために、法令、その他の規範などの変更に伴い、プライバシーポリシーを改定する場合があります。改定があった場合はホームページにてお知らせいたします。
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
