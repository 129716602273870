import React, { useMemo, useRef } from "react";

import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

import ReactQuill from "react-quill";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../firebase/config";
import { getRandomString } from "../../../utils/Utils";
import "react-quill/dist/quill.snow.css";

function CreateHistoryInput() {
  const quillRef = useRef(null);
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
  } = useFormikContext();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ align: [] }],
          [
            {
              color: [
                "#000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "#81D8D0",
              ],
            },
            {
              background: [
                "#000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "#81D8D0",
              ],
            },
          ],
          ["image"],
        ],
        handlers: {
          image: () => imageHandler(),
        },
      },
    }),
    []
  );

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        // Use Firebase storage to upload the file
        const imgRef = ref(storage, `image/examHistory/${getRandomString(23)}`);
        await uploadBytes(imgRef, file);
        const fileUrl = await getDownloadURL(imgRef);

        // Get the current editor instance
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        if (range) {
          // Insert the image URL into the editor
          editor.insertEmbed(range.index, "image", fileUrl);
        }
      }
    };
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>タイトル</Form.Label>
        <Form.Control
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          name="title"
          maxLength={250}
          isInvalid={touched.title && errors.title}
          isValid={touched.title && !errors.title}
        />
        <Form.Control.Feedback type="invalid">
          {errors.title}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Label>コンテンツ</Form.Label>
      <div>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          modules={modules}
          value={values.content}
          onChange={(value, _, __, editor) => {
            if (editor.getLength() === 1) {
              setFieldValue("content", "");
            } else {
              setFieldValue("content", value);
            }
          }}
          onBlur={() => {
            setFieldTouched("content", true);
          }}
          className={
            touched.content && errors.content ? "border border-danger" : ""
          }
        />
      </div>
    </>
  );
}

export default CreateHistoryInput;
