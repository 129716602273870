import React from "react";
import { Intro } from "./Intro/Intro";
import Events from "./Events/Events";
import About from "./About/About.js";
import Footer from "../../components/Universal/Footer/Footer.js";
import Header from "../../components/Universal/Header/Header.js";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";
import MetaData from "../../utils/MetaData.js";
import ContactHome from "./ContactHome/ContactHome.js";

const Home = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <MetaData />
      {width <= 576 && <Header />}
      <Intro />
      <Events />
      <About />
      <ContactHome showTitle={true} />
      <Footer />
    </div>
  );
};

export default Home;
