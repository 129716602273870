import React, { useEffect, useState } from "react";
import { Col, Ratio, Row } from "react-bootstrap";
import Styles from "./SupportingMembers.module.css";
import { Link } from "react-router-dom";
import { useCollectionSimple } from "../../hooks/useCollectionSimple";

const SupportingMemberList = ({ members, placeholders, isPending }) => {
  return (
    <>
      <Row>
        {isPending && placeholders}

        {Object.values(members).length > 0 &&
          Object.values(members).map((item, i) => (
            <Col key={i} xs={6} sm={4} className="px-3 px-sm-3 my-sm-3">
              <Link to={`/supporting-members/${item.memberId}`} className={Styles.link}>
                <Ratio aspectRatio="1x1" className="mt-4">
                  <div className={Styles.imgContainer}>
                    <img src={item.profilePhoto} />
                  </div>
                </Ratio>
                <p className={Styles.memeberName}>{item.company_name}</p>
              </Link>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default SupportingMemberList;
