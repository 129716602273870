import React from "react";

import { Spinner } from "react-bootstrap";
import { Helmet, HelmetData } from "react-helmet-async";

// import OwnerMyPage from "./OwnerMyPage";
// import { useUserDetailsContext } from "../../context/UserDetailsContext";
import AdminMyPage from "./admin/AdminMyPage";
// import OwnerMyPage from "./owner/OwnerMyPage";
// import AuthorMyPage from "./author/AuthorMyPage";

export default function MyPage() {
  const helmetData = new HelmetData({});
  // const { userData, isPending } = useUserDetailsContext();

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>マイページ</title>
      </Helmet>
      {/* {isPending && (
        <div className="position-absolute top-50 w-100 text-center">
          <Spinner className="" animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )} */}

      {/* {userData && userData.group === "ADMIN" && <AdminMyPage />} */}
      <AdminMyPage />
    </>
  );
}
