import React from "react";
import Styles from "./WhatIsVmd.module.css";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Col, Container, Row } from "react-bootstrap";
import MetaData from "../../utils/MetaData";

const WhatIsVmd = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.WhatIsVmd}>
        <div className={Styles.pageIntro}>
          <div className={Styles.title}>What is VMD?</div>
          <div className={Styles.subtitle}>
            <span className={Styles.vmdText}>VMD</span>とは
          </div>
        </div>
        <div className={Styles.firstDescriptionContainer}>
          <Container>
            <Row className="justify-content-center">
              <Col xs={11} sm={7} className="text-center">
                <p className={Styles.firstDescriptionTitle}>はじめに</p>
                {width > 576 ? (
                  <p className={Styles.firstDescription}>
                    時代と共に消費者の購買方法は大きく変化してきました。<br></br>
                    それと共に購買心理も変化し、人々の消費に対する価値観そのものが変わりはじめています。
                    <br></br>
                    しかし、どのような購買方法であっても売上に直結した有効手段として<br></br>
                    常にV M Dが必要とされるのは理由があるからです。
                  </p>
                ) : (
                  <p className={Styles.firstDescription}>
                    時代と共に消費者の購買方法は大きく変化してきました。<br></br>
                    それと共に購買心理も変化し、人々の消費に対する価値観そのものが変わりはじめています。しかし、どのような購買方法であっても売上に直結した有効手段として常にVMDが必要とされるのは理由があるからです。
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        <div className={Styles.secondDescriptionContainer}>
          <Container>
            <Row>
              <Col className="text-center">
                <p className={Styles.secondDescriptiontitle}>
                  {width > 576 ? (
                    "ビジュアルマーチャンダイジング（VMD）の概念"
                  ) : (
                    <>
                      ビジュアル
                      <br />
                      マーチャンダイジング
                      <br />
                      （VMD）の概念
                    </>
                  )}
                </p>
                {width > 576 ? (
                  <p className={Styles.secondDescription}>
                    ビジュアルマーチャンダイジングとは、マーケティングの一環として行われる小売業の戦略活動の一つです。
                    店舗というメディアにおいて顧客の立場にたち、マーチャンダイジングと商空間の視覚的表現を一体化することによって、
                    見やすく、選びやすく、買いやすく、魅力的で快適な売場環境を提供するのと同時に、
                    企業やブランドの独自性、販促等のコンセプトを伝える仕組みと方法です。
                    <br />
                    その結果、ビジュアルマーチャンダイジングは収益の質的向上をはかり、企業やブランドの価値向上とロイヤルティーの確立をもたらします。
                    <br />
                    <br />
                    ビジュアルマーチャンダイジングの概念は、時代とともに変化しています。
                    <br />
                    消費者心理・消費者動向を深く分析した上で見る人を刺激し、体験価値を最大化させて「共感」を生み出し、
                    <br />
                    売上に直結するマーケティング活動の一端を担うという点においては、今後さらにその必要性と重要度は増していくでしょう。
                    <br />
                    より視覚的情報が優位化されたバーチャルな世界においても、サイト流入や購買のモチベーションを高める上でビジュアルマーチャンダイジングは有効で大きな効果をもたらすと考えられます。
                    <br />
                    <br />
                    近年、デジタル環境特に、SNSの普及により消費者行動は著しく変化を遂げ、Eコマースという利便性の高い購買環境が構築されたことにより、顧客がいつでもどこからでも商品を購入できるようになりました。
                    ビジュアルマーチャンダイジングの概念は、リアル店舗だけではなくバーチャル店舗においても重要であり、OMOに対応するビジュアルマーチャンダイジングも今、求められています。
                    <br />
                    <br />
                    ＊OMOとは、Online Merges with Oﬄineの略
                    <br />
                    ＊ビジュアルマーチャンダイジングは英語圏ではVMと略されていますが、日本ではVMDが用いられています。
                  </p>
                ) : (
                  <p className={Styles.secondDescription}>
                    ビジュアルマーチャンダイジングとは、<br></br>
                    マーケティングの一環として行われる小売業の戦略活動の一つです。<br></br>
                    店舗というメディアにおいて顧客の立場に<br></br>
                    たち、マーチャンダイジングと商空間の<br></br>
                    視覚的表現を一体化することによって、<br></br>
                    見やすく、選びやすく、買いやすく、魅力的で<br></br>
                    快適な売場環境を提供すると同時に、<br></br>
                    企業やブランドの独自性、販促等のコンセプ<br></br>
                    トを伝える仕組みと方法です。<br></br>
                    その結果、ビジュアルマーチャンダイジング<br></br>
                    は収益の質的向上をはかり、企業や<br></br>
                    ブランドの価値向上とロイヤルティーの<br></br>
                    醸成をもたらします。<br></br>
                    <br></br>
                    ビジュアルマーチャンダイジングの概念は、<br></br>時代とともに変化しています。<br></br>
                    消費者心理・消費者動向を深く分析した<br></br>
                    上で見る人を刺激し、体験価値を最大化させ<br></br>て「共感」を生み出し、<br></br>
                    売上に直結するマーケティング活動の一端<br></br>を担うという点においては、今後さらにその
                    <br></br>必要性と重要度は増していくでしょう。<br></br>
                    より視覚的情報が優位化されたバーチャル<br></br>な世界においても、サイト流入や購買のモチ
                    <br></br>ベーションを高める上でビジュアルマーチャ<br></br>
                    ンダイジングは有効で大きな効果をもたらす<br></br>と考えられます。<br></br>
                    <br></br>
                    近年、デジタル環境特に、SNSの普及により<br></br>
                    消費者行動は著しく変化を遂げ、<br></br>
                    Eコマースという利便性の高い購買環境が<br></br>
                    構築されたことにより、顧客がいつでも<br></br>
                    どこからでも商品を購入できるように<br></br>
                    なりました。<br></br>
                    ビジュアルマーチャンダイジングの概念は、<br></br>リアル店舗だけではなくバーチャル店舗に
                    <br></br>おいても重要であり、OMOに対応する<br></br>
                    ビジュアルマーチャンダイジングも今、<br></br>
                    求められています。<br></br>
                    <br></br>
                    ＊OMOとは、Online Merges with Oﬄineの略<br></br>
                    ＊ビジュアルマーチャンダイジングは英語圏<br></br>ではVMと略されていますが、日本ではVMD
                    <br></br>が用いられています。
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className={Styles.thirdDescriptionContainer}>
          <div className={Styles.thirdDescTitle}>
            <p>
              {width > 576 ? (
                "ビジュアルマーチャンダイジングの仕事と職業"
              ) : (
                <>
                  ビジュアル <br />
                  マーチャンダイジングの <br />
                  仕事と職業
                </>
              )}
            </p>
          </div>
          <div className={Styles.thirdDescDetails}>
            <p>
              小売企業の経営目的達成に向けて、顧客に快適な買い物を提供する魅力ある店づくり・売り場演出をするために顧客の目に映るあらゆる要素をデザインし、コントロールする業務は、全てビジュアルマーチャンダイジングに関わると言えます。
              <br></br>
              <br></br>
              MDに直接関わる小売業の経営陣・商品担当者・現場スタッフは勿論、商空間における視覚的表現をサポートする人と企業が、マーケティングのコンセプトを共有して取り組むことが、経営目標達成のキーポイントの一つです。
              <br></br>
              その業務に関わる全ての人はビジュアルマーチャンダイジングの当事者と言えるでしょう。その中でビジュアルマーチャンダイザーは、自らが売場における商品の魅力を演出する知見と技術を発揮することは勿論、小売業と視覚的表現に関わる企業と人の業務が機能するよう、つなぐ役割をも果たします。
              <br></br>
              昨今では、デジタル領域における基本的な知識やマーケティング理論なども必要とされます。特にソーシャルネットワーク上での消費者マインドをより理解する事でO
              M Oにおける効果をもたらす仕事の1つでもあります。
            </p>
          </div>
        </div>

        <div className={Styles.vmdContainer}>
          <div className={Styles.vmdTitle}>
            <p>
              {width > 576 ? (
                "ビジュアルマーチャンダイジングに関わる業務"
              ) : (
                <>
                  ビジュアル
                  <br />
                  マーチャンダイジングに
                  <br />
                  関わる業務
                </>
              )}
            </p>
          </div>
          <img className={Styles.chartImg} src="/img/chart.webp" alt="chartImg" />
        </div>

        <div className={Styles.vmdListItemContainer}>
          <div className={Styles.vmdListTitle}>
            <p>
              {width > 576 ? (
                "ビジュアルマーチャンダイジングに関わる職種・職能"
              ) : (
                <>
                  ビジュアル
                  <br />
                  マーチャンダイジングに
                  <br />
                  関わる職種・職能
                </>
              )}
            </p>
          </div>
          <Container>
            <Row className={Styles.listItems}>
              <Col xs={12} md={6} lg={4}>
                <ul className={Styles.styledList}>
                  <li>◆リテイルマネージャー</li>
                  <li>◆マーケッター</li>
                  <li>◆プロダクトデザイナー</li>
                  <li>◆マーチャンダイザー</li>
                  <li>◆ディストリビューター</li>
                  <li>◆ショップアシスタント</li>
                  <li>◆ビジュアルマーチャンダイザー</li>
                  <li>◆デコレーター</li>
                  <li>◆ストアプランナー</li>
                  <li>◆クリエイティブディレクター</li>
                  <li>◆アートディレクター</li>
                  <li>◆建築家</li>
                  <li>◆空間デザイナー</li>
                  <li>◆照明デザイナー</li>
                  <li>◆家具・フィクスチュアデザナー</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <ul className="styled-list">
                  <li>◆ディスプレイデザイナー</li>
                  <li>◆グラフィックデザイナー</li>
                  <li>◆イラストレーター</li>
                  <li>◆フォトグラファー</li>
                  <li>◆ロゴデザイナー</li>
                  <li>◆色彩コーディネーター</li>
                  <li>◆フラワーアーティスト</li>
                  <li>◆パッケージデザイナー</li>
                  <li>◆アーティスト</li>
                  <li>◆動画クリエイター</li>
                  <li>◆WEB マーケッター</li>
                  <li>◆WEB デザイナー</li>
                </ul>
                <p className={Styles.etc}>etc...</p>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid className={Styles.vmdContainerLast}>
          <div className={Styles.containerSize}>
            <Row className="justify-content-center text-center my-5">
              <Col>
                <p className={Styles.mainTitle}>
                  {width > 576 ? (
                    "VMD の資格「商品装飾展示技能検定」について"
                  ) : (
                    <>
                      VMDの資格 <br></br>「商品装飾展示技能検定」<br></br>について
                    </>
                  )}
                </p>
                <p className={Styles.mainSubtitle}>
                  {width > 576 ? (
                    "マーチャンダイズプレゼンテーションの専門家のための国家資格検定"
                  ) : (
                    <>
                      マーチャンダイズプレゼンテーションの<br></br>専門家のための国家資格検定
                    </>
                  )}
                </p>
                <p className={Styles.border}></p>
              </Col>
            </Row>
            <div className={Styles.contentSection}>
              {width > 576 ? (
                <>
                  <div className={Styles.textSection}>
                    <p>
                      日本ビジュアルマーチャンダイジング協会が支援をする<br></br>
                      「商品装飾展示技能検定」は、VMDの考え方をもとにマーチャンダイズプレゼンテーションを担当するスペシャリストの技能・知識を一定の基準によって検定し公証する国家検定で、1986年から労働省（当時）が実施し、現在では厚生労働省が毎年実施しています。
                      <br />
                      この技能検定は試験の難易度によって1 級、2 級、3
                      級に区分され、実技試験と学科試験により実施されます。 両方の試験に合格した人には、1
                      級は厚生労働省大臣名の、2 級・3 級は東京都知事名の合格証と技能士章が交付され、
                      「技能士」と称することができます。
                    </p>
                  </div>
                  <div>
                    <img src="/img/fourth.png" alt="img" className={Styles.imgFluid} />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <img src="/img/fourth.png" alt="img" className={Styles.imgFluid} />
                  </div>
                  <div className={Styles.textSection}>
                    <p>
                      日本ビジュアルマーチャンダイジング協会が支援をする
                      「商品装飾展示技能検定」は、VMDの考え方をもとにマーチャンダイズプレゼンテーションを担当するスペシャリストの技能・知識を一定の基準によって検定し公証する国家検定で、1986年から労働省（当時）が実施し、現在では厚生労働省が毎年実施しています。
                      <br />
                      この技能検定は試験の難易度によって1級、2級、3級に区分され、実技試験と学科試験により実施されます。
                      両方の試験に合格した人には、1級は厚生労働省大臣名の、2級・3級は東京都知事名の合格証と技能士章が交付され、
                      「技能士」と称することができます。
                    </p>
                  </div>
                </>
              )}
            </div>

            <Row className="justify-content-center text-center my-5">
              <Col>
                <div className={Styles.product}>
                  <p className={Styles.productTitle}>商品装飾展示とは</p>
                  <p className={Styles.border}></p>
                  <div className={Styles.productDescription}>
                    {width > 576 ? (
                      <p>
                        ・「商品装飾展示」とは、ビジュアルマーチャンダイジング（VMD）の考え方をもとに、
                        <br></br>
                        商業施設においてマーチャンダイジングを的確にお客様に視覚伝達するための、マーチャンダイズプレゼンテーションのことです。
                        <br></br>
                        <br></br>
                        ・「商品装飾展示技能士」はマーチャンダイズプレゼンテーションのプロフェッショナルです。
                        <br></br>
                        店舗や企業におけるVMD担当者として、常に柔軟で効果的な売り場作りと商品プレゼンテーションを担う
                        <br></br>
                        重要かつ専門的な存在と言えます。<br></br>
                        <br></br>
                        ・「商品装飾展示」技能検定試験は、商品を的確かつ効果的に表現して<br></br>
                        見せるために必要な技能・知識を対象としています。その内容は、マーチャンダイズプレゼンテーションのデザイン、
                        <br></br>
                        商品特性に基づくさまざまなプレゼンテーション手法（フォーミング・ピニング・テグスワーク・ハンギングなど）に
                        <br></br>
                        関する技能・知識と、併せて、ビジュアルマーチャンダイジング、デザイン、商業施設、
                        <br></br>
                        ライフスタイル、什器・器具、照明、色彩、材料、関係法規、安全衛生などに関する知識も含まれます。
                        <br></br>
                        <br></br>
                        ・1級実技試験は、ビジュアルプレゼンテーションのデザイン、イメージスケッチ（一点透視図）などの
                        <br></br>
                        作成が含まれた高度技能者のレベルです。<br></br>
                        ・2級実技試験は、商品プレゼンテーションのデザインを含みますが、イメージスケッチ（一点透視図）などの
                        <br></br>
                        作成が含まれない中級技能者のレベルです。<br></br>
                        ・3級実技試験は、商品特性に基づくさまざまなプレゼンテーション手法<br></br>
                        （フォーミング・ピニング・テグスワーク・ハンギング等）に関する基礎的な技能・知識を対象とし、
                        <br></br>
                        ２級に含まれている商品プレゼンテーションのデザインなどは含まれていないレベルです。
                      </p>
                    ) : (
                      <p>
                        ・「商品装飾展示」とは、ビジュアルマーチャンダ
                        イジング（VMD）の考え方をもとに、商業施設に
                        おいてマーチャンダイジングを的確にお客様に視
                        覚伝達するための、マーチャンダイズプレゼンテ ーションのことです。<br></br>
                        <br></br>
                        ・「商品装飾展示技能士」はマーチャンダイズプ
                        レゼンテーションのプロフェッショナルです。 店舗や企業におけるVMD担当者として、常に柔軟
                        で効果的な売り場作りと商品プレゼンテーション を担う重要かつ専門的な存在と言えます。
                        <br></br>
                        <br></br>
                        ・「商品装飾展示」技能検定試験は、商品を的確 かつ効果的に表現して見せるために必要な
                        技能・知識を対象としています。 その内容は、マーチャンダイズプレゼンテーショ
                        ンのデザイン、商品特性に基づくさまざまなプレ
                        ゼンテーション手法（フォーミング・ピニング・テ
                        グスワーク・ハンギングなど）に関する技能・知識
                        と、併せて、ビジュアルマーチャンダイジング、 デザイン、商業施設、ライフスタイル、
                        什器・器具、照明、色彩、材料、関係法規、 安全衛生などに関する知識も含まれます。
                        <br></br>
                        <br></br>
                        ・1級実技試験は、ビジュアルプレゼンテーション
                        のデザイン、イメージスケッチ（一点透視図）など
                        の作成が含まれた高度技能者のレベルです。 ・2級実技試験は、商品プレゼンテーションの
                        デザインを含みますが、イメージスケッチ （一点透視図）などの作成が含まれない中級技能
                        者のレベルです。<br></br>
                        <br></br>
                        ・3級実技試験は、商品特性に基づくさまざまなプ
                        レゼンテーション手法フォーミング・ピニング・テ
                        グスワーク・ハンギング等）に関する基礎的な技
                        能・知識を対象とし、２級に含まれている商品プ
                        レゼンテーションのデザインなどは含まれていな いレベルです。<br></br>
                        <br></br>
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default WhatIsVmd;
