import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";

import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetDoc } from "../../hooks/useGetDoc";

function SupportMemberCSVDownload() {
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getSingleDocWithQuery, data: members } = useGetDoc();

  useEffect(() => {
    getCsvData();
  }, []);

  //csvdata
  const getCsvData = async () => {
    const res = await getSingleDocWithQuery("supportmembers", []);
    if (res && Array.isArray(res) && res.length > 0) {
      const CSVDD = res.map((m) => {
        return {
          memberId: m.memberId,
          company_name: `"${m.company_name || ""}"`,
          postal_code: `"${m.postal_code || ""}"`,
          prefecture: `"${m.prefecture || ""}"`,
          work_address: `"${m.work_address || ""}"`,
          phone: `"${m.phone || ""}"`,
          url: `"${m.url || ""}"`,
        };
      });
      setCsvData(CSVDD);
    }
    setIsLoading(false);
  };

  const header = [
    { key: "memberId", label: "会員番号" },
    { key: "company_name", label: "企業名" },
    { key: "postal_code", label: "郵便番号" },
    { key: "prefecture", label: "都道府県" },
    { key: "work_address", label: "勤務先住所" },
    { key: "phone", label: "電話" },
    { key: "url", label: "URL" },
  ];

  return (
    <div>
      {isLoading ? (
        <p>Loading CSV data...</p>
      ) : (
        <CSVLink
          className="btn btn-secondary"
          data={csvData}
          headers={header}
          filename={`賛助会員リスト_${moment(new Date()).format(
            "YYYYMMDD_HHMMSS"
          )}.csv`}
          enclosingCharacter={``}>
          <FontAwesomeIcon icon={faFileCsv} /> CSVをダウンロード
        </CSVLink>
      )}
    </div>
  );
}

export default SupportMemberCSVDownload;
