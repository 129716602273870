import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer4 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        3級は毎年1回、1級、2級は隔年1回、下記のスケジュールで実技試験、筆記試験をそれぞれ別日に実施しています。
        <br></br>
        3月上旬　試験実施要項公示（受検申請書配布）<br></br>
        4月上旬　受検申請<br></br>
        6月上旬　受検票、受検のしおり、3級は実技試験問題、1級・2級は実技試験概要　発送<br></br>
        7月　　　3級試験実施<br></br>
        8月　　　１級、2級試験実施。
      </p>
    </div>
  );
};

export default Answer4;
