import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
import Answer10SVG from "./Answer10SVG";
const Answer10 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        1級、2級は1987年度～2021年度、3級は1998年度～2022年度の合格者数、合格率の全国総数です
      </p>
      <div className={Styles.tableContainer}>
        <Answer10SVG />
      </div>
      <p>
        ※申請数には、実際には受検しなかった方も含まれています。<br></br>
        特に3級は、受検辞退者数が多い年がありましたので実際の合格率は85％を超えていると考えられます。
      </p>
    </div>
  );
};

export default Answer10;
