import React, { useEffect, useState } from "react";

import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";

import { useGetDoc } from "../../../hooks/useGetDoc";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  getFormatedDate,
  getGroupName,
  getPrivateStatus,
  getRandomInt,
} from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function EventDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowDimensions();

  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();

  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await firebaseGetDoc("events", params.id)
        .then((res) => {
          setUserDetails(res);
        })
        .catch((e) => console.log(e));
    }
    fetchData();
  }, []);

  return (
    <>
      {userDetails && (
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <Card.Body className="">
                  <>
                    <div className="text-end">
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          localStorage.removeItem("editEventData");
                          navigate(`/mypage/edit-event/${params.id}`);
                        }}>
                        <FontAwesomeIcon icon={faEdit} /> 編集
                      </Button>
                    </div>
                    <br />
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 &&
                          `border-bottom border-top border-start `
                        } border-secondary p-2`}>
                        イメージ写真 <span className="text-danger">※</span>
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-top border-start border-bottom border-end`
                        } p-1 py-2 border-end border-secondary`}>
                        {userDetails.mainPhoto && (
                          <img
                            src={userDetails.mainPhoto}
                            className="shadow rounded"
                            alt=""
                            style={{ width: "500px", maxWidth: "95%" }}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        イメージ写真 1
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails.subPhoto1 && (
                          <img
                            src={userDetails.subPhoto1}
                            className="shadow rounded"
                            alt=""
                            style={{ width: "500px", maxWidth: "95%" }}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        イメージ写真 2
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails.subPhoto2 && (
                          <img
                            src={userDetails.subPhoto2}
                            className="shadow rounded"
                            alt=""
                            style={{ width: "500px", maxWidth: "95%" }}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        イメージ写真 3
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails.subPhoto3 && (
                          <img
                            src={userDetails.subPhoto3}
                            className="shadow rounded"
                            alt=""
                            style={{ width: "500px", maxWidth: "95%" }}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        タイトル <span className="text-danger">※</span>
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails.title}
                      </Col>
                    </Row>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}>
                        詳細
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}>
                        {userDetails.description && (
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html: userDetails.description.replace(
                          //       /(?:\r\n|\r|\n)/g,
                          //       "<br>"
                          //     ),
                          //   }}></div>

                          <div className={`ql-snow ql-container border-0`}>
                            <div
                              className={` ql-editor px-0`}
                              dangerouslySetInnerHTML={{
                                __html: userDetails.description,
                              }}></div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default EventDetails;
