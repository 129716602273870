import React, { useEffect, useState } from "react";

import Select from "react-select";
import { Button, Container } from "react-bootstrap";

import { useCollection } from "../../../hooks/useCollection";

const FilterContentUser = ({
  handleFilterSubmit,
  selectStatus,
  setSelectStatus,
  optionStatus,
}) => {

  return (
    <Container className="p-4 w-full">
      {optionStatus && optionStatus.length > 0 && (
        <div className="py-2">
          <p className="mb-0">ステータス</p>
          <Select
            className="w-100"
            options={optionStatus}
            name="groupTags"
            placeholder="▼選択してください"
            value={selectStatus}
            onChange={setSelectStatus}
            components={{
              IndicatorSeparator: () => null
            }}
            isMulti
            // noOptionsMessage={({ inputValue }) => `No result found for "${inputValue}"`}
          />
        </div>
      )}

    
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="pt-4">
        <Button variant="primary" onClick={handleFilterSubmit}>
          適用する
        </Button>
      </div>
    </Container>
  );
};

export default FilterContentUser;
