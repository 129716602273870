import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";

import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetDoc } from "../../hooks/useGetDoc";

function MemberCSVDownload() {
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getSingleDocWithQuery, data: members } = useGetDoc();

  useEffect(() => {
    getCsvData();
  }, []);

  //csvdata
  const getCsvData = async () => {
    const res = await getSingleDocWithQuery("members", []);
    if (res && Array.isArray(res) && res.length > 0) {
      const CSVDD = res.map((m) => {
        return {
          position: m.position,
          memberId: m.memberId,
          name: `"${m.name || ""}"`,
          furigana: `"${m.furigana || ""}"`,
          place_of_employment: `"${m.place_of_employment || ""}"`,
          business_type: `"${m.business_type || ""}"`,
          department: `"${m.department || ""}"`,
          title: `"${m.title || ""}"`,
          postal_code: `"${m.postal_code || ""}"`,
          prefecture: `"${m.prefecture || ""}"`,
          work_address: `"${m.work_address || ""}"`,
          email: `"${m.email || ""}"`,
          phone: `"${m.phone || ""}"`,
          mobile_phone: `"${m.mobile_phone || ""}"`,
          area_of_expertise: `"${m.area_of_expertise || ""}"`,
          occupation: `"${m.occupation || ""}"`,
          url: `"${m.url || ""}"`,
          team: `"${m.team || ""}"`,
          area: `"${m.area || ""}"`,
          self_introduction: `"${m.self_introduction || ""}"`,
        };
      });
      // Sort by position (assuming position is a numeric value)
      const sortedCSVDD = CSVDD.sort((a, b) => a.position - b.position);

      // Set the sorted data
      setCsvData(sortedCSVDD);
    }
    setIsLoading(false);
  };

  const header = [
    { key: "position", label: "位置" },
    { key: "memberId", label: "会員番号" },
    { key: "name", label: "氏名" },
    { key: "furigana", label: "ヨミ" },
    { key: "place_of_employment", label: "勤務先" },
    { key: "business_type", label: "事業形態" },
    { key: "department", label: "部署" },
    { key: "title", label: "肩書" },
    { key: "postal_code", label: "郵便番号" },
    { key: "prefecture", label: "都道府県" },
    { key: "work_address", label: "勤務先住所" },
    { key: "email", label: "メルアド" },
    { key: "phone", label: "電話" },
    { key: "mobile_phone", label: "携帯" },
    { key: "area_of_expertise", label: "専門分野" },
    { key: "occupation", label: "職業名 or 職種" },
    { key: "url", label: "URL" },
    { key: "team", label: "所属チーム" },
    { key: "area", label: "所属エリア" },
    {
      key: "self_introduction",
      label: "詳細プロフィールに掲載する自己紹介文（130字程度）",
    },
  ];

  return (
    <div>
      {isLoading ? (
        <p>Loading CSV data...</p>
      ) : (
        <CSVLink
          className="btn btn-secondary"
          data={csvData}
          headers={header}
          filename={`会員リスト_${moment(new Date()).format(
            "YYYYMMDD_HHMMSS"
          )}.csv`}
          enclosingCharacter={``}>
          <FontAwesomeIcon icon={faFileCsv} /> CSVをダウンロード
        </CSVLink>
      )}
    </div>
  );
}

export default MemberCSVDownload;
