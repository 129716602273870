import { useState } from "react";
import { db } from "../firebase/config";

//firebase imports
import { collection, orderBy, query, limit, startAfter, where, getDocs } from "firebase/firestore";

export const useCollectionSimple = () => {
  const [documents, setDocuments] = useState(null);
  //   const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [isLast, setIsLast] = useState(false);

  const getMultipleDocs = async ({
    _collection,
    _query,
    orderByField,
    orderByOption = "desc",
    _limit,
    _startAfter
  }) => {
    setDocuments(null);
    setIsLast(false);
    setIsPending(true);
    let ref = collection(db, _collection);
    //set the query
    if (_query && Object.values(_query)) {
      _query.forEach((queryitem) => {
        if (queryitem[2] === "true" || queryitem[2] === "false") {
          queryitem[2] = queryitem[2] === "true";
        }

        ref = query(ref, where(...queryitem));
      });
    }
    if (orderByField) {
      ref = query(ref, orderBy(orderByField, orderByOption));
    }
    if (_limit) {
      ref = query(ref, limit(_limit));
    }

    if (_startAfter) {
      ref = query(ref, startAfter(_startAfter));
    }

    let results = [];
    let docSnap = await getDocs(ref);
    docSnap.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    setIsPending(false);
    setDocuments(results);
    setLastDoc(docSnap.docs[docSnap.docs.length - 1]);

    if (docSnap.empty || (_limit && docSnap.docs.length < _limit)) {
      setIsLast(true);
    }

    return () => docSnap();
  };
  return { documents, isPending, lastDoc, isLast, getMultipleDocs };
};
