import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer7 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        現在、講習会は実施されておりません。<br></br>
        実技試験、学科試験の過去問題や解説を掲載した
        <Link to={"/publication"} target="_blank">
          「国家検定 商品装飾展示技能検定ガイドブック」
        </Link>
        実技編、学科編　を活用して勉強される方がほとんどです。<br></br>
        学科試験の参考書として
        <Link to={"/publication"} target="_blank">
          「新版VMD用語事典」
        </Link>
        もお勧めしています。<br></br>
        <br></br>
        3級の実技試験は、受検票に問題が同封されますので、資材を100均などで手配し、実際に繰り返し練習することをお勧めします。1級、2級の実技も、過去問題を実際に制作してみることをお勧めしています。数年間の過去問題から、必要なスキルや傾向を推測することができます。仕上がりの美しさや時間配分などにも気を配り練習すると、試験会場でも落ち着いて実力が発揮できます。
      </p>
    </div>
  );
};

export default Answer7;
