import React, { useRef, useState } from "react";

import ReactCrop from "react-image-crop";
import { Button, Modal } from "react-bootstrap";

import getCroppedImgPercent, { centerAspectCrop } from "../../../utils/CropperPercent";

import "react-image-crop/dist/ReactCrop.css";

function CropModal({ show, setShow, setImage, imgSrc, aspect, localStorageVar, onClose = null }) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(undefined);
  const [completedCrop, setCompletedCrop] = useState();
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    if (aspect !== undefined) {
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCrop({
        unit: "%", // Using percentages to match `ReactCrop`
        x: 0,
        y: 0,
        width: 100,
        height: 100
      });
    }
  };

  const onDownloadCropClick = async () => {
    await getCroppedImgPercent(imgSrc, crop).then((url) => {
      setImage(localStorageVar, url);
      localStorage.setItem(localStorageVar, url);
      setShow(false);
    });
  };

  const handleClose = () => {
    setShow(false);
    console.log("handle close");
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      onHide={handleClose}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!imgSrc && (
          <div className="d-flex justify-content-center align-items-center">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} className="w-100" />
            </ReactCrop>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>閉じる</Button>
        {!!completedCrop && (
          <Button
            onClick={() => {
              onDownloadCropClick();
            }}
          >
            切り抜き
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CropModal;
