import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer9 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        1級と2級は、試験日が同日ですので、同年の受検はできません。実施する都道府県によって試験日が異なる2級と3級を同年に受検申請することは可能です。
      </p>
    </div>
  );
};

export default Answer9;
