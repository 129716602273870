import React, { useEffect, useRef } from "react";
import Styles from "./ContactHome.module.css";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useSendEmail } from "../../../hooks/useSendMail";
import { useAddDocs } from "../../../hooks/useAddDocs";

const ContactHome = ({ showTitle = true }) => {
  const { addADoc } = useAddDocs();

  const { sendMail, isConfirmed, isPending, error } = useSendEmail();
  const navigate = useNavigate();
  const titleRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      name_furi: "",
      company_name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("お名前は必須です")
        .test(
          "not-only-numbers",
          "お名前は数字のみでは入力できません",
          (value) => isNaN(value) || value.match(/[a-zA-Z]/)
        ),

      name_furi: Yup.string()
        .matches(/^[\u30A1-\u30FF]*$/, "全角カタカナのみ使用できます") // This allows all full-width Katakana characters
        .required("全角カタカナは必須です"),

      company_name: Yup.string()
        .required("会社名は必須です")
        .test(
          "not-only-numbers",
          "会社名は数字のみでは入力できません",
          (value) => isNaN(value) || value.match(/[a-zA-Z]/)
        ),
      email: Yup.string()
        .matches(
          /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "有効なメールアドレスを入力してください。"
        )
        .email("有効なメールアドレスを入力してください")
        .required("メールアドレスは必須です"),

      phone: Yup.string()
        .matches(
          /^(0\d{1,4})-?(\d{1,4})-?(\d{4})$/,
          "有効な電話番号を入力してください (例: 03-0000-1111 または 090-0000-1111)"
        )
        .required("電話番号は必須です"),
      message: Yup.string().required("お問い合わせ内容は必須です"),

      privacyPolicy: Yup.boolean()
        .oneOf([true], "プライバシーポリシーに同意してください")
        .required("プライバシーポリシーに同意してください"),
    }),
    onSubmit: async (values) => {
      if (values.subscribe_to_newsletter) {
        await addADoc("newsletter", {
          name: values.name,
          name_furi: values.name_furi,
          company_name: values.company_name,
          email: values.email,
          phone: values.phone,
        });
      }
      sendMail({
        mailOption: {
          from: "日本ビジュアルマーチャンダイジング協会 <info@javma.com>",
          to: values.email,
          cc: "info@javma.com",
          subject: "【日本ビジュアルマーチャンダイジング協会】お問い合わせ送信完了のお知らせ",
          html: `
          ${values.name}様 <br>
          <br>
          ※このメールはメッセージの送信が完了した時点で自動で配信されるメールです。<br>
          <br>
          この度は、日本ビジュアルマーチャンダイジング協会へ<br>
          お問い合わせをいただき誠にありがとうございました。<br>
          下記の内容で承りましたのでご確認ください。<br>
          改めて、事務局よりご連絡をさせて頂きますのでしばらくお待ちください。<br>
          <br>
          <hr>
          [氏名　　　　　]　${values.name}<br>
          [フリガナ　　　]　${values.name_furi}<br>
          [勤務先　　　　]　${values.company_name}<br>
          [メールアドレス]　${values.email}<br>
          [電話　　　　　]　${values.phone}<br>
          [お問合わせ内容]　${values.message}<br>
          <hr>
          ${values.subscribe_to_newsletter ? "<br>メルマガ購読希望<br>" : ""}
          <br>
          ※1週間以上経っても事務局から連絡がない場合は、<br>
          お手数をおかけいたしますが、以下の事務局までお問い合わせください。<br>
          ※本メールにお心当たりのない場合は、誠に恐れ入りますが、<br>
          破棄していただくようにお願い申し上げます。<br>
          <hr>
          日本ビジュアルマーチャンダイジング協会<br>
          <a href="mailto: info@javma.com">info@javma.com</a>
          <hr>
          `,
        },
      });

      //   formik.resetForm();
    },
  });

  const handleReset = () => {
    formik.resetForm();
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (isConfirmed) {
      navigate("/message-sent");
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ block: "start" });
        }, [200]);
      }
    }
  }, [window.location.hash]);

  return (
    <div className={Styles.contactus} id="contactus">
      <Container>
        {showTitle && (
          <Row>
            <Col xs={12} className="text-center">
              <p className={Styles.title} ref={titleRef}>
                CONTACT US
              </p>
              <p className={Styles.subtitle}>お問い合わせ</p>
            </Col>
          </Row>
        )}

        <Row className="mt-5 d-flex justify-content-center">
          <Col xs={12} sm={8}>
            <div className={Styles.introTitle}>
              <p>以下のお問い合わせ内容でよろしければ送信ボタンをクリックしてください。</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  氏名
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="氏名"
                    className={`${Styles.customInput} border-0 ${
                      formik.touched.name && formik.errors.name ? "is-invalid" : ""
                    }`}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className={Styles.error}>{formik.errors.name}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  フリガナ
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="name_furi"
                    placeholder="フリガナ"
                    className={`${Styles.customInput} border-0 ${
                      formik.touched.name_furi && formik.errors.name_furi ? "is-invalid" : ""
                    }`}
                    {...formik.getFieldProps("name_furi")}
                  />
                  {formik.touched.name_furi && formik.errors.name_furi ? (
                    <div className={Styles.error}>{formik.errors.name_furi}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  勤務先
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="勤務先"
                    className={`${Styles.customInput} border-0 ${
                      formik.touched.company_name && formik.errors.company_name ? "is-invalid" : ""
                    }`}
                    {...formik.getFieldProps("company_name")}
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <div className={Styles.error}>{formik.errors.company_name}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  メールアドレス
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="yamamotoichiro@vmd.com"
                    className={`${Styles.customInput} border-0 ${
                      formik.touched.email && formik.errors.email ? "is-invalid" : ""
                    }`}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className={Styles.error}>{formik.errors.email}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  電話番号
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="03-0000-1111"
                    className={`${Styles.customInput} border-0 ${
                      formik.touched.phone && formik.errors.phone ? "is-invalid" : ""
                    }`}
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className={Styles.error}>{formik.errors.phone}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.textinput}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  お問い合わせ内容
                </Form.Label>
                <div className="p-0">
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="ここにテキストが入ります。"
                    className={`${Styles.customTextarea}  `}
                    {...formik.getFieldProps("message")}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className={Styles.textareaError}>{formik.errors.message}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.agrement} border-0 mb-1`}>
                <Col sm={12}>
                  <div className={Styles.checkboxContainer}>
                    <Form.Check
                      type="checkbox"
                      name="subscribe_to_newsletter"
                      id="subscribe_to_newsletter"
                      className={`${Styles.customCheckbox}`}
                      {...formik.getFieldProps("subscribe_to_newsletter")}
                      checked={formik.values.subscribe_to_newsletter}
                    />
                    <span className={Styles.trems}>メルマガ購読希望</span>
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className={`align-items-center ${Styles.agrement}`}>
                <Form.Label column sm={3} className={Styles.label}>
                  個人情報について
                </Form.Label>
                <Col sm={12}>
                  <div className={Styles.checkboxContainer}>
                    <Form.Check
                      type="checkbox"
                      name="privacyPolicy"
                      id="privacyPolicy"
                      className={`${Styles.customCheckbox}`}
                      {...formik.getFieldProps("privacyPolicy")}
                      checked={formik.values.privacyPolicy} //checkbox unchecked
                    />
                    <span className={Styles.trems}>
                      当協会の
                      <Link
                        to="/privacy-policy"
                        target="_blank"
                        style={{ color: "#17a2b8", textDecoration: "none" }}
                      >
                        プライバシーポリシー
                      </Link>
                      に同意して送信します。
                    </span>
                  </div>
                  {formik.touched.privacyPolicy && formik.errors.privacyPolicy ? (
                    <div className={Styles.privacyerror}>{formik.errors.privacyPolicy}</div>
                  ) : null}
                </Col>
              </Form.Group>

              <div className={`w-100 ${Styles.CustomBtnContainer}`}>
                <Button
                  type="submit"
                  className={Styles.send}
                  disabled={!(formik.isValid && formik.dirty) || isPending}
                >
                  {isPending ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    <>送信する</>
                  )}
                </Button>
                <Button type="button" className={Styles.cancel} onClick={handleReset}>
                  戻る
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactHome;
