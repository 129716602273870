import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Helmet, HelmetData } from "react-helmet-async";
import { Alert, Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { useLogin } from "../../hooks/useLogin";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { error, isPending, login } = useLogin();

  const handelSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>ログイン</title>
      </Helmet>
      <Header />
      <div className="main py-4 dotted_bg mt-5">
        <Container className="my-lg-5 bg-white">
          <h4 className="text-center py-5 ff-zen fw-bold text-brown">ログイン</h4>
          <Row className="justify-content-center">
            <Col xs={11} lg={5} sm={6}>
              {error && (
                <Alert variant="danger" dismissible className="text-center">
                  {error && <p className="m-0">{error}</p>}
                </Alert>
              )}
              <Form onSubmit={handelSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="gray_border common__input p-3"
                    type="email"
                    placeholder="メールアドレス"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3 gray_border">
                  <InputGroup>
                    <Form.Control
                      className="gray_border common__input p-3"
                      placeholder="パスワード"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <InputGroup.Text
                      className="gray_border bg-white p-3"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <div className="text-center">
                  {!isPending && (
                    <Button className="mb-3 p-3" type="submit">
                      ログイン
                    </Button>
                  )}

                  {isPending && (
                    <Button className="mb-3 p-3" disabled>
                      ログイン
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>
                  )}
                </div>
              </Form>

              {/* <p className="text-center">
                <Link className=" text-link-blue fw-bold" to="/forget-password">
                  パスワードを忘れた場合はこちら
                </Link>
              </p> */}
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}
