import React from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
const Answer5 = () => {
  return (
    <div className={Styles.answer}>
      <p className="mb-2">
        商品装飾展示技能検定は、国（厚生労働省）が定めた実施計画に基づいて、試験問題等の作成については中央職業能力開発協会が、試験の実施については都道府県の職業能力開発協会が行っています。
      </p>
      <p>
        実施する都道府県は3月上旬に発表されます。（すべての都道府県で実施されるわけではありません）<br></br>
        受検申請は、実施する都道府県の職業能力開発協会で期間内に受け付けています。
      </p>
      <Link to={"https://www.javada.or.jp/kyoukai/itiran.html"} target="_blank">
        都道府県職業能力開発協会 : 中央職業能力開発協会（JAVADA）
      </Link>
    </div>
  );
};

export default Answer5;
