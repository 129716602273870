import React, { useEffect, useState } from "react";
import { Col, Ratio, Row } from "react-bootstrap";
import Styles from "./Members.module.css";
import { Link } from "react-router-dom";
import { useCollectionSimple } from "../../hooks/useCollectionSimple";

const MemberList = ({ members, placeholders, isPending }) => {
  return (
    <>
      <Row>
        {isPending && placeholders}

        {Object.values(members).length > 0 &&
          Object.values(members).map((item, i) => (
            <Col key={i} xs={6} sm={3} className="px-2 px-sm-3 my-sm-3">
              <Link to={`/members/${item.memberId}`} className={Styles.link}>
                <Ratio aspectRatio="1x1">
                  <img
                    src={item.profilePhoto || "/img/no_user_pic.png"}
                    className="w-100 mt-4"
                    alt={item.name}
                  />
                </Ratio>
                <p className={Styles.memeberName}>{item.name}</p>
                <p className={Styles.memeberTitle}>{item.place_of_employment}</p>
              </Link>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default MemberList;
