import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SlideUpload from "./SlideUpload";

const LpSlide = () => {
  return (
    <>
      <SlideUpload platform={"pc"} />
      <div className="mt-5"> {"　"}</div>
      <SlideUpload platform={"sp"} />
    </>
  );
};

export default LpSlide;
