import React, { useEffect, useState } from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
const Answer2 = () => {
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState("#");

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      const storage = getStorage();
      const fileRef = ref(storage, "pdf/qualifying_examination_list_2012.pdf");

      try {
        const url = await getDownloadURL(fileRef);
        setPdfDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);

  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        3級合格後2級を受検する場合は、在学生も含め、他の要件なく受検できます。
        2級を直接受検される場合は、実務経験2年以上が必要です。ただし、関連の学科を専攻し
        た方は実務経験0年度も受検できます。詳しくは、下記をご覧ください。
      </p>

      <Link to={pdfDownloadUrl} target="_blank">
        Microsoft Word - 受検資格一覧表_H25改正.doc (javma.com)
      </Link>
      <p>※実務経験の内容については、Q1社会人の場合をご参照ください。</p>
    </div>
  );
};

export default Answer2;
