import React, { useState, useEffect } from "react";
import Styles from "./About.module.css";
import { Col, Container, Ratio, Row } from "react-bootstrap";
import MoreLink from "../../../components/Universal/More/MoreLink";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const About = () => {
  const { width } = useWindowDimensions();

  const [pdfDownloadUrl, setPdfDownloadUrl] = useState("#");

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      const storage = getStorage();
      const fileRef = ref(storage, "pdf/30years.pdf");

      try {
        const url = await getDownloadURL(fileRef);
        setPdfDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);

  return (
    <div>
      <div className={Styles.about}>
        <Container>
          <Row>
            <Col xs={12} className="text-end">
              <p className={Styles.title}>ABOUT JAVMA</p>
              <p className={Styles.subtitle}>VMD協会とは</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} className="text-sm-end">
              <p className={Styles.info}>
                VMD協会の国家資格検定や当協会で取り組んでいる活動内容など紹介します。
              </p>
              <div className={Styles.linkMore}>
                <MoreLink raw={true} url={"/about-javma"} />
              </div>
            </Col>
          </Row>

          <div className={Styles.members}>
            {width > 576 && <p className={Styles.memberTitle}>MEMBERS</p>}
            {width <= 576 && <p className={Styles.hiroGreetings}>協会理事長　ご挨拶</p>}

            <Row className="justify-content-center mt-2 mt-sm-4 px-4">
              <div className={Styles.hiroImgContainter}>
                <Ratio aspectRatio="1x1">
                  <img src={"/img/hiro.webp"} className="w-100 object-fit-cover" />
                </Ratio>

                <p className={Styles.nameLabel}>楫義明</p>
                <p className={Styles.role}>協会理事長</p>
              </div>

              <div className={Styles.hiroDetails}>
                {width > 576 && <p className={Styles.hiroGreetings}>協会理事長　ご挨拶</p>}
                <p className={Styles.hiroDescription}>
                  ビジュアルマーチャンダイジングの概念を経営戦略のキーワードとして企業が導入を図り始めて、約40年が経過しようとしています。この間に、情報技術の革新によって売り場環境と買い物の仕方そのものが目まぐるしく劇的に進化し、それと共にビジュアルマーチャンダイジングの包含する要素の増大と、新たな媒体に相応しい知識と技術が求められるようになりました。当協会は1987年の創立以来、専門性に優れた会員による情報の収集と発信、多様なビジュアル化の研究、人材の育成と能力の開発など、商業の場における時代の要請に即したビジュアルマーチャンダイジングの普及発展に努め、関連分野はもとより、社会の繁栄に貢献できる組織として確信を持って活動を進めて参りました。
                  <br></br>
                  今日のような情報と技術の急速な変化を直視するとき、専門家集団としての協会という場で、専門領域を超えて多様な交換を行い、直面する共通の課題を追求することは、個人的な問題解決の糸口を得るためにも重要な体験となります。企業に属する方、個人で活動される方、お立場の違いが其々貴重な情報となって蓄積され、深い交流に繋がっていきます。ビジュアルマーチャンダイジング協会の活動にご期待ください。
                </p>
              </div>
            </Row>
          </div>

          <Row>
            <Col xs={12} className="d-flex mt-5 mb-2">
              <Link to={"/members"} className={Styles.button}>
                正会員リスト
              </Link>
            </Col>
            <Col xs={12} className="d-flex mt-4">
              <Link to={"/supporting-members"} className={`${Styles.button}`}>
                賛助会員リスト
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={Styles.banners}>
        <div className={Styles.banner}>
          <img
            src={width > 576 ? "/img/banner/banner2.webp" : "/img/banner/banner2-sm.webp"}
            className="w-100"
          />
          <div className={`${width > 576 ? Styles.moreItemL : Styles.moreItemR}`}>
            <MoreLink raw={true} url={"/licensure-exam/"} type={2} />
          </div>
        </div>

        <div className={Styles.banner}>
          <img
            src={width > 576 ? "/img/banner/banner1.webp" : "/img/banner/banner1-sm.webp"}
            className="w-100"
          />
          <div className={`${Styles.moreItemR}`}>
            <MoreLink raw={true} url={"/publication/"} type={2} />
          </div>
        </div>

        <div className={Styles.banner}>
          <img
            src={width > 576 ? "/img/banner/banner3.webp" : "/img/banner/banner3-sm.webp"}
            className="w-100"
          />
          <div className={`${width > 576 ? Styles.moreItemL : Styles.moreItemR}`}>
            <MoreLink raw={true} url={"/membership/"} type={2} />
          </div>
        </div>

        <div className={Styles.banner}>
          <a href={pdfDownloadUrl} download target="_blank">
            <img
              src={width > 576 ? "/img/banner/banner4.webp" : "/img/banner/banner4-sm.webp"}
              className="w-100"
            />
          </a>
          {/* {width > 576 && (
            <div className={Styles.moreItemR}>
              <MoreLink url={"#"} type={2} />
            </div>
          )} */}
          {/* {width <= 576 && (
            <Link to={pdfDownloadUrl} className={Styles.imgbtnBanner}>
              <img src="/img/banner/bannerbtn.webp" alt="Download PDF"></img>
            </Link>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default About;
