import React, { useEffect, useState } from "react";

import Styles from "./AboutJavma.module.css";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Col, Collapse, Container, Ratio, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import MetaData from "../../utils/MetaData";

const CollapseItem = ({ item, count }) => {
  const [open, setOpen] = useState(count === 0 ? true : false);

  return (
    <>
      <div className={Styles.collapsItem}>
        <div
          className={`${Styles.ItemTitleIcon} ${open ? Styles.rotate90 : ""}`}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <p>{item.name}</p>
          <img src="/img/rarrow.webp" />
        </div>
        <Collapse in={open}>
          <div className={Styles.collapseDetails} id="example-collapse-text">
            <p>{item.details}</p>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const AboutJavma = () => {
  const { width } = useWindowDimensions();
  const [activityItem, setActivityItem] = useState([
    {
      name: "技能検定支援",
      details:
        "厚生労働省の国家試験「商品装飾展示技能検定」に対し、ビジュアルマーチャンダイジングに関する人材育成と能力開発の見地で総合的な支援活動を行い、試験に対応した技能検定ガイドブックやビジュアルマーチャンダイジングの知識と技能をまとめたVMD用語事典も出版しています。",
    },
    {
      name: "JAPAN SHOP会場セミナー",
      details:
        "東京ビッグサイトで開催されるJAPAN SHOP会場において、毎回「売る戦略・見せる技術」シリーズとして今日的なトピックをビジュアルマーチャンダイジングの視点で捉えたテーマに選び、それに相応しいゲストを招いて講演するセミナーイベントです。",
    },
    {
      name: "情報セミナー",
      details:
        "海外や国内のビジュアルマーチャンダイジングの最新情報を取材・編集し、プランニング・表現・フィニッシュワークなどについてVMDの視点で分析し、協会の内外にリポートしています。特にクリスマスシーズンには世界で取材したビジュアルトークセッションが好評です。",
    },
    {
      name: "TERAKOYA",
      details:
        "ビジュアルマーチャンダイジングのプロフェッショナルとして必要とされる「知識と技術」の向上を目的に、会員相互によって、あるいは外部の専門家を招き研鑽を行う勉強会です。タイムリーなテーマに絞り、一般にも公開して共に学ぶ場となっています。",
    },
    {
      name: "エリア制とチーム制",
      details:
        "会員組織には、全国を６分割して会員の所在地で所属が決まる＜エリア制＞と、情報・ビジネス・教育・社会連携・広報の５チームに各会員が希望して所属する＜チーム制＞があります。会員は協会の事業活動の他、エリアネットワークや所属チームの活動を通して、交流や情報交換など多くのチャンスを生み出します。",
    },
  ]);

  const [pdfDownloadUrl, setPdfDownloadUrl] = useState("#");

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      const storage = getStorage();
      const fileRef = ref(storage, "pdf/30years.pdf");

      try {
        const url = await getDownloadURL(fileRef);
        setPdfDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);
  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.about}>
        <div className={Styles.pageIntro}>
          <div className={Styles.title}>ABOUT JAVMA</div>
          <div className={Styles.subtitle}>
            {width > 576
              ? "一般社団法人 日本ビジュアルマーチャンダイジング協会とは"
              : "一般社団法人 VMD協会とは"}
          </div>
        </div>

        <div className={`${width > 576 ? "container" : ""}`}>
          <div className={Styles.items}>
            <div className={`${Styles.item} ${Styles.green}`}>
              <Ratio aspectRatio={width > 576 ? "1x1" : 0.85}>
                <div className={Styles.itemContent}>
                  <div className={Styles.itemImg}>
                    <Ratio aspectRatio={"1x1"}>
                      <img src="/img/about-mission.webp" />
                    </Ratio>
                  </div>

                  <h2 className={Styles.itemTitle}>MISSION</h2>
                  <p className={Styles.itemDescription}>
                    私たちは、企業と商品の魅⼒と、お客様の求める価値観を繋げるために、視覚的表現を創造する全ての職種の⼈が繋がり、⾼め合うことで、経済的にも精神的にも豊かな市場を作ることに貢献します。
                  </p>
                </div>
              </Ratio>
            </div>

            <div className={`${Styles.item} ${Styles.white}`}>
              <Ratio aspectRatio={width > 576 ? "1x1" : 0.85}>
                <div className={Styles.itemContent}>
                  <div className={Styles.itemImg}>
                    <Ratio aspectRatio={"1x1"}>
                      <img src="/img/about-purpose.webp" />
                    </Ratio>
                  </div>

                  <h2 className={Styles.itemTitle}>PURPOSE</h2>
                  <p className={Styles.itemDescription}>
                    私たちは、情報・技術の急速な変化に
                    伴う問題提起と解決の⽷⼝を得ます。関連分野に従事する⼈の専⾨性を深めたり、⼈材の育成を図ります。
                  </p>
                </div>
              </Ratio>
            </div>

            {width > 576 ? (
              <>
                <div className={`${Styles.item} ${Styles.white}`}>
                  <Ratio aspectRatio={"1x1"}>
                    <div className={Styles.itemContent}>
                      <div className={Styles.itemImg}>
                        <Ratio aspectRatio={"1x1"}>
                          <img src="/img/about-value.webp" />
                        </Ratio>
                      </div>

                      <h2 className={Styles.itemTitle}>VALUE</h2>
                      <p className={Styles.itemDescription}>
                        私たちは、協会の内外を問わず、業種・業態・職種・職位を超えたネットワークにより、会員個々ではできない活動の実現を図れます。また、個々の持つ情報・能⼒・技術・技能を交換し、共有し発展する場となります。
                      </p>
                    </div>
                  </Ratio>
                </div>

                <div className={`${Styles.item} ${Styles.green}`}>
                  <Ratio aspectRatio={"1x1"}>
                    <div className={Styles.itemContent}>
                      <div className={Styles.itemImg}>
                        <Ratio aspectRatio={"1x1"}>
                          <img src="/img/about-policy.webp" />
                        </Ratio>
                      </div>

                      <h2 className={Styles.itemTitle}>POLICY</h2>
                      <p className={Styles.itemDescription}>
                        私たちは、常に社会的未来を見据えて事業活動を展開します。各会員は協会からのメリットによって得た成果を協会に還元し、共有します。
                      </p>
                    </div>
                  </Ratio>
                </div>
              </>
            ) : (
              <>
                <div className={`${Styles.item} ${Styles.green}`}>
                  <Ratio aspectRatio={0.8}>
                    <div className={Styles.itemContent}>
                      <div className={Styles.itemImg}>
                        <Ratio aspectRatio={"1x1"}>
                          <img src="/img/about-policy.webp" />
                        </Ratio>
                      </div>

                      <h2 className={Styles.itemTitle}>POLICY</h2>
                      <p className={Styles.itemDescription}>
                        私たちは、常に社会的未来を見据えて事業活動を展開します。各会員は協会からのメリットによって得た成果を協会に還元し、共有します。
                      </p>
                    </div>
                  </Ratio>
                </div>
                <div className={`${Styles.item} ${Styles.white}`}>
                  <Ratio aspectRatio={0.8}>
                    <div className={Styles.itemContent}>
                      <div className={Styles.itemImg}>
                        <Ratio aspectRatio={"1x1"}>
                          <img src="/img/about-value.webp" />
                        </Ratio>
                      </div>

                      <h2 className={Styles.itemTitle}>VALUE</h2>
                      <p className={Styles.itemDescription}>
                        私たちは、協会の内外を問わず、業種・業態・職種・職位を超えたネットワークにより、会員個々ではできない活動の実現を図れます。また、個々の持つ情報・能⼒・技術・技能を交換し、共有し発展する場となります。
                      </p>
                    </div>
                  </Ratio>
                </div>
              </>
            )}
          </div>

          <div className={Styles.activityAbout}>
            <div className={Styles.itemImgabout}>
              <Ratio aspectRatio={"1x1"}>
                <img className={Styles.qsnImg} src="/img/qsn.webp" />
              </Ratio>
            </div>

            <h2 className={Styles.aboutTitle}>ABOUT VMD</h2>
            <div className={Styles.aboutContainer}>
              <Link to={"/about-vmd"} className={Styles.aboutText}>
                VMD（ビジュアルマーチャン<br className="d-block d-sm-none"></br>ダイジング）とは?{" "}
                <img className={Styles.arroIconClass} src="/img/arrowIcon.webp" />
              </Link>
            </div>
          </div>

          <div className={Styles.activity}>
            <div className={Styles.itemImg}>
              <Ratio aspectRatio={"1x1"}>
                <img src="/img/about-activity.webp" />
              </Ratio>
            </div>

            <h2 className={Styles.itemTitle}>ACTIVITY</h2>
            <p className={Styles.itemDescription}>
              専⾨領域を極め、専⾨領域を越えて多様な交換を⾏い、直⾯する共通の課題を追究するとともに、⽬的に即した情報発信のプロジェクト活動を⾏います。ビジュアルマーチャンダイジングに関わる基本的な教育の場として、学びの機会や資格取得の支援などを行います。
            </p>

            <div className={Styles.collapsItems}>
              {activityItem.map((item, i) => {
                return <CollapseItem count={i} key={i} item={item} />;
              })}
            </div>
          </div>

          <div className={Styles.history}>
            <div className={Styles.itemImg}>
              <Ratio aspectRatio={"1x1"}>
                <img src="/img/about-history.webp" />
              </Ratio>
            </div>

            <h2 className={Styles.itemTitle}>HISTORY</h2>
            <p className={Styles.history_text}>
              ビジュアルマーチャンダイジングの概念は、現在、経営戦略の主要なキーワードとして欠くことのできないものになっています。売り場の活性化を図る点で、経済や消費のあり方、あるいは消費者の価値観の変化に伴い、商品政策に基づいた企画から販売までの一貫した考え方を、売り場での商品プレゼンテーションに表現することが重要になってきました。そのために、関連分野に従事する人の専門性を深め、情報の収集、理論と技術の向上、そして人材の育成が社会的な展望として期待されるようになり、これらの目的を叶えるために、1987年、日本ビジュアルマーチャンダイジング協会が設立されました。
            </p>
          </div>

          <div className={Styles.moreDeatails}>
            <div className={Styles.section}>
              <div className={Styles.infoTitle}>
                <h4>事務局</h4>
                <p>OFFICE</p>
              </div>
              <div className={`${Styles.infoDetails} ${width < 576 ? "text-center" : ""}`}>
                〒104-0061<br></br>
                東京都中央区銀座2-14-5{width > 576 ? "　" : <br></br>}銀座27中央ビル8階<br></br>
                TEL. 03-3476-1410<br></br>
                {width > 576 ? (
                  <>電話応信時間：月曜～金曜（土・日・祭日休み）10:00～15:00</>
                ) : (
                  <>
                    電話応信時間：月曜～金曜<br></br>
                    （土・日・祭日休み）10:00～15:00
                  </>
                )}
              </div>
            </div>

            <div className={Styles.organizationContainer}>
              <div className={Styles.infoTitle}>
                <h4>組織図</h4>
                <p>ORGANIZATION CHART</p>
              </div>
              <div className={Styles.organization}>
                <img className={Styles.organizationImg} src="/img/newAsset.webp" />
              </div>
            </div>

            <div className={Styles.section}>
              <div className={Styles.infoTitle}>
                <h4>会員</h4>
                <p>MEMBER</p>
              </div>
              <div className={Styles.infoDetails}>
                {width > 576 ? (
                  <>
                    百貨店/専門店/チェーンストア/ファッションビル/メーカー商社/代理店/企画設計施工社/<br></br>
                    VMD関連機器材社/プロダクション/イベント社/教育機関などでVMDに関わる組織所属の方/<br></br>
                    あるいはフリーランスの方
                  </>
                ) : (
                  <>
                    百貨店/専門店/チェーンストア/<br></br>
                    ファッションビル/メーカー商社/<br></br>
                    代理店/企画設計施工社/<br></br>
                    VMD関連機器材社/プロダクション/<br></br>
                    イベント社/教育機関などで<br></br>
                    VMDに関わる組織所属の方/<br></br>
                    あるいはフリーランスの方
                  </>
                )}
              </div>
            </div>

            <div className={Styles.section}>
              <div className={Styles.infoTitle}>
                <h4>賛助会員</h4>
                <p>SUPPORTING MEMBER</p>
              </div>
              <div className={Styles.infoDetails}>
                <Link to="https://www.ibuki-w.com/" target="_blank">
                  株式会社 息吹工藝社
                </Link>
                <Link to="http://www.e-sin.co.jp/" target="_blank">
                  株式会社 英進
                </Link>
                <Link to="https://www.aim-create.co.jp/" target="_blank">
                  株式会社 エイムクリエイツ
                </Link>
                <Link to="https://www.tokyo-onishi.com/" target="_blank">
                  東京大西造花装飾 株式会社
                </Link>
                <Link to="https://www.sugartaste.co.jp/" target="_blank">
                  株式会社 シュガーテイスト
                </Link>
                <Link to="https://www.tomane.co.jp/" target="_blank">
                  株式会社 トーマネ
                </Link>
                <Link to="https://www.nanasai.co.jp/" target="_blank">
                  株式会社 七彩
                </Link>
                <Link to="https://www.parco-space.co.jp/" target="_blank">
                  株式会社 パルコスペースシステムズ
                </Link>
                <Link to="https://heiwa-mq.co.jp/" target="_blank">
                  株式会社 平和マネキン
                </Link>
                <Link to="http://www.modern-paradise.co.jp/" target="_blank">
                  株式会社 モダンパラダイス
                </Link>
                <Link to="https://www.yamato-mannequin.com/" target="_blank">
                  株式会社 ヤマトマネキン
                </Link>
                <Link to="https://yky.co.jp/" target="_blank">
                  株式会社 YKY
                </Link>
              </div>
            </div>

            <a href={pdfDownloadUrl} download target="_blank" className={Styles.anniversary}>
              {width > 576 ? <img src="/img/30anniversary.webp" /> : <img src="/img/30anniversary-sm.webp" />}
            </a>

            <div className="w-100 d-flex justify-content-center mt-5">
              <Link to={"/members"} className={`${Styles.button} btn`}>
                会員リストへ
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutJavma;
