import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Styles from "./Intro.module.css";
import { useCollectionSnap } from "../../../hooks/useCollectionSnap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TopSlide = ({ isTopPending }) => {
  const { width } = useWindowDimensions();
  const { documents, getMultipleDocs, isPending } = useCollectionSnap();
  const [mobileImage, setMobileImage] = useState([]);
  const [pcImage, setPcImage] = useState([]);

  const loadData = () => {
    getMultipleDocs({
      _collection: "slides",
      orderByField: "position",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    isTopPending(isPending);
  }, [isPending]);

  useEffect(() => {
    if (documents && Array.isArray(documents) && documents.length > 0) {
      const mobile = documents.filter((item) => item.type === "sp").map((item) => item.url);
      setMobileImage(mobile);
      const pc = documents.filter((item) => item.type === "pc").map((item) => item.url);
      setPcImage(pc);
    }
  }, [documents]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: false,
    arrows: false
  };

  return (
    <div className={Styles.topSlide}>
      {width > 576 ? (
        <Slider {...settings}>
          {pcImage.map((image, index) => (
            <div key={index} className={Styles.slide}>
              <img src={image} alt={`Slide ${index + 1}`} className="w-100" />
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...settings}>
          {mobileImage.map((image, index) => (
            <div key={index} className={Styles.slide}>
              <img src={image} alt={`Slide ${index + 1}`} className="w-100" />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default TopSlide;
