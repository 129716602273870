import React, { useState } from "react";
import Footer from "../../components/Universal/Footer/Footer";
import Header from "../../components/Universal/Header/Header";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import Styles from "./LicensureExamFaq.module.css";
import { Link } from "react-router-dom";
import Answer1 from "./FaqAnswer/Answer1";
import Sidebar from "../../components/Universal/UComponent/Sidebar";
import Answer2 from "./FaqAnswer/Answer2";
import Answer3 from "./FaqAnswer/Answer3";
import Answer4 from "./FaqAnswer/Answer4";
import Answer5 from "./FaqAnswer/Answer5";
import Answer6 from "./FaqAnswer/Answer6";
import Answer7 from "./FaqAnswer/Answer7";
import Answer8 from "./FaqAnswer/Answer8";
import Answer9 from "./FaqAnswer/Answer9";
import Answer10 from "./FaqAnswer/Answer10";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaData from "../../utils/MetaData";

const CollapseItem = ({ question, Answer, show = false }) => {
  const [open, setOpen] = useState(show);

  return (
    <div className={Styles.collapsItem}>
      <div
        className={`${Styles.question} ${open ? Styles.open : Styles.close}`}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        <img src="/img/qIcon.webp" />
        <p className={Styles.ques} dangerouslySetInnerHTML={{ __html: question }}></p>
        <img src={open ? "/img/faqminus.webp" : "/img/faqplus.webp"} className={Styles.collaspsIcon} />
      </div>

      <Collapse in={open}>
        <div className={Styles.collapseDetails} id="example-collapse-text">
          <Answer />
        </div>
      </Collapse>
    </div>
  );
};

const LicensureExamFaq = () => {
  const { width } = useWindowDimensions();

  return (
    <>
    <MetaData />
      <Header />
      <div className={Styles.licensure}>
        <div className={Styles.pageIntro}>
          <Container>
            <div className={Styles.title}>LICENSURE EXAM FAQ</div>
            <div className={Styles.subtitle}>
              {width > 576 ? "技能検定についてよくあるお問い合わせ" : "よくあるお問い合わせ"}
            </div>
          </Container>
        </div>

        <div className={Styles.detailsContainer}>
          <Container>
            <Row>
              <Col sm={9}>
                <p className={Styles.introTitle}>
                  商品装飾展示技能検定についてよくいただく{width > 576 ? "" : <br></br>}
                  お問い合わせと回答をまとめました。
                </p>

                <div className={Styles.questionAnswer}>
                  <CollapseItem question={" 3級の受検資格は？学生でも受検できますか？"} Answer={Answer1} />
                  <CollapseItem question={" 2級の受検資格は？"} Answer={Answer2} />
                  <CollapseItem question={" 1級の受検資格は？"} Answer={Answer3} />
                  <CollapseItem question={" 受検スケジュールは？"} Answer={Answer4} />
                  <CollapseItem
                    question={" 商品装飾展示技能検定は、どこで実施していますか？ "}
                    Answer={Answer5}
                  />
                  <CollapseItem question={" 受検申請書は、どこで入手できますか？ "} Answer={Answer6} />
                  <CollapseItem
                    question={
                      ' 受検に向けての勉強方法は？<br class="d-block d-sm-none">受検向けの講習会は実施されていますか？ '
                    }
                    Answer={Answer7}
                  />
                  <CollapseItem question={" 実技試験の問題のポイントは？ "} Answer={Answer8} />
                  <CollapseItem
                    question={" １級と２級、2級と3級を同年に受検できますか？ "}
                    Answer={Answer9}
                  />
                  <CollapseItem question={" 合格者数や合格率は？ "} Answer={Answer10} />
                </div>
              </Col>
              <Col sm={3} className="px-0">
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LicensureExamFaq;
