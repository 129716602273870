import React from "react";
import Styles from "./Membership.module.css";
import Footer from "../../components/Universal/Footer/Footer";
import Header from "../../components/Universal/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaData from "../../utils/MetaData";

const Membership = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.membership}>
        <div className={Styles.pageIntro}>
          <Container className={Styles.container}>
            <div className={Styles.title}>MEMBERSHIP</div>
            <div className={Styles.subtitle}>入会内容</div>
          </Container>
        </div>

        <Container className={Styles.container}>
          <Row className="justify-content-center mb-5">
            <Col sm={12}>
              <p className={Styles.introTitle}>
                協会メンバー同士で情報交換する{width > 576 ? "" : <br></br>}絶好の場となっています
              </p>
              <p className={Styles.introTitleD}>
                {width > 576 ? (
                  <>
                    日本ビジュアルマーチャンダイジング協会のメンバーはバラエティーに富んでいます。<br></br>
                    期せずして異業種の交歓もできるといわれています。現場で問題解決にあたっている人、<br></br>
                    長期的な企画に携わっている人、基本的な研究にいそしんでいる人、経営の中枢にいる人などさまざまです。
                    <br></br>
                    こうした協会員名簿はそのままVMD界フーズフーの役割も担っています。
                  </>
                ) : (
                  <>
                    日本ビジュアルマーチャンダイジング協会のメンバーはバラエティーに富んでいます。期せずして異業種の交歓もできるといわれています。
                    <br></br>
                    現場で問題解決にあたっている人、長期的な企画に携わっている人、基本的な研究にいそしんでいる人、経営の中枢にいる人などさまざまです。
                    <br></br>
                    こうした協会員名簿はそのままVMD界フーズフーの役割も担っています。
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col xs={12} sm={10}>
              <Row className="justify-content-between my-0 py-0 my-sm-5 pb-sm-4">
                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>協会員のメリット</p>
                    <p>Benefits of Membership</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    協会員は、活動組織に加わることで、エリアのネットワークに属しての交流や情報交換など、さまざまな機会を創り出すチャンスがあります。協会はその舞台を提供し機会を準備いたします。
                    <br></br>
                    本来、会員であることそのものが優れたVMDプロフェッショナルの証しとなります。協会活動を通じて貴重な情報に触れたり、さまざまな人脈が形成されたりすることは、必ずや個々の仕事に有益な効果をもたらします。
                    <br></br>
                    協会はその機会を提供し準備いたします。
                  </div>
                </Col>

                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>会員とその分布</p>
                    <p>Members and their distribution</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    百貨店、専門店、チェーンストア、ファッションビル、メーカー、商社、代理店、企画設計施工社、VMD関連機器材社、プロダクション、イベント社、教育機関などでVMDに関わる組織所属の方、あるいはフリーランスの方などで構成されています。関東地域をはじめ、北海道地域、中京地域、関西地域、中国地域、九州地域、海外にも登録会員が分布しています。
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-between my-0 py-0 my-sm-5 py-sm-4">
                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>会員の種類</p>
                    <p>Type of Membershipmbership</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    <table>
                      <tbody>
                        <tr>
                          <td>正会員</td>
                          <td>
                            本会の目的に賛同して入会した個人<br></br>
                            <span className={Styles.spaceRemover}>（</span>
                            会費を法人負担とする場合も含まれます）
                          </td>
                        </tr>
                        <tr>
                          <td>賛助会員</td>
                          <td>本会の事業に賛助する法人および団体</td>
                        </tr>
                        <tr>
                          <td>名誉会員</td>
                          <td>本会に功績のあった個人会員</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>

                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>会員の条件</p>
                    <p>Membership Requirementsn</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    <div>
                      <span>正会員</span>
                      <br></br>
                      ビジュアルマーチャンダイジングに関する実務または<br></br>
                      研究を行ない相応の経験と知識と技量を有する方。
                    </div>
                    <div className="mt-3">
                      <span>賛助会員</span>
                    </div>
                    本会の趣旨目的に理解を関心を持つ企業。
                    <br></br>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-between my-0 py-0 mt-sm-5 py-sm-4">
                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>会員の登録</p>
                    <p>Member Registration</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    正会員2名の推薦を得て、入会申込書に必要事項をご記入の上、必要資料とともに当会に提出していただき、当会による所定の手続きの後、入会金納入、登録となります。詳細および正会員の知己がない場合は連絡事務局にご相談ください。
                  </div>
                </Col>

                <Col xs={12} sm={5}>
                  <div className={Styles.cardTitle}>
                    <p>入会金と会費</p>
                    <p>Admission Fee and Dues</p>
                  </div>

                  <div className={Styles.cardDetails}>
                    <span>正会員</span>
                    <br></br>
                    入会金10,000円　　年会費24,000円<br></br>
                    中途入会者は月割り計算にて納入していただきます。<br></br>
                    <span className={Styles.spaceRemover}>（</span>年度開始は毎年５月）<br></br>
                    <br></br>
                    <span>賛助会員</span>
                    <br></br>
                    年会費50,000円（1口）
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={Styles.links}>
            <Link to={"/licensure-exam-faq"} className={Styles.button}>
              {width > 576 ? "検定についてよくあるお問合せ" : "検定についてよくあるお問合せ"}
            </Link>

            <Link to={"/membership-resgistration/"} className={Styles.button}>
              入会資料請求
            </Link>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Membership;
