import { useState, useEffect } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { verifyBeforeUpdateEmail } from "firebase/auth";

export const useUpdateEmail = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const updateEmailFn = (email) => {
    setError(null);
    setMessage(null);
    setIsPending(true);

    verifyBeforeUpdateEmail(auth.currentUser, email)
      .then((res) => {
        console.log(res);
        setMessage(
          "新しいメールアドレス宛に、アカウントを有効にする為のメールをお送りいたしました"
        );
        setIsPending(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };
  return { error, isPending, message, isConfirmed, updateEmailFn };
};
