import _ from "lodash";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";

import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase/config";

export function getAlphaNumericString() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const minLength = 8;
  let result = "";

  // Ensure at least one number
  const randomNumber = Math.floor(Math.random() * 10).toString();
  result += randomNumber;

  for (let i = 1; i < minLength; i++) {
    const randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));
    result += randomCharacter;
  }

  // Shuffle the characters in the result
  result = result.split("");
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result.join("");
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result.trim();
}

export function getGroupName(group) {
  if (group === "OWNER") {
    return "オーナー";
  } else if (group === "AUTHOR") {
    return "投稿者";
  } else if (group === "ADMIN") {
    return "管理者";
  }
}

export function getFormatedDate(time, formate = "YYYY/MM/DD") {
  return moment(new Timestamp(time.seconds, time.nanoseconds).toDate()).format(formate);
}

const publicTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    公開中
  </Tooltip>
);

const privateTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    非公開中
  </Tooltip>
);

export function getPrivateStatus(status) {
  return (
    <div>
      {!status ? (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={publicTooltip}>
          <FontAwesomeIcon className="text-success" icon={faLockOpen} />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={privateTooltip}>
          <FontAwesomeIcon className="text-warning" icon={faLock} />
        </OverlayTrigger>
      )}
    </div>
  );
}

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none"
  }),
  menu: (provided) => ({ ...provided, zIndex: 3 })
};

export const getGroupAuthDesignation = async (item) => {
  let schoolsData = item.schools ? item.schools : [];
  let schoolsPositionData = item.schools_position ? item.schools_position : [];

  let schools_position = [];
  Object.entries(schoolsPositionData).map(([key, value]) => {
    if (key !== "undefined" && value !== "undefined" && key !== undefined && value !== undefined) {
      let obj = {};
      obj[key] = value;
      schools_position.push(obj);
    }
  });

  // console.log(schools_position);

  let school_data = [];
  Object.entries(schoolsData).map(([key, value]) => {
    if (key !== "undefined" && value !== "undefined" && key !== undefined && value !== undefined) {
      let obj = {};
      obj[key] = value;
      school_data.push({
        id: key,
        designation: searchKeyAndGetvalue(key, schools_position)
      });
    }
  });

  let schoolsWithPosition = [];
  school_data = await Promise.all(
    school_data.map(async (item) => {
      const data = await firebaseGetDoc(`group_page`, item.id);
      if (data) {
        schoolsWithPosition.push({ designation: item.designation, ...data });
      }
    })
  );

  return schoolsWithPosition;
};

export function searchKeyAndGetvalue(key, itemOBJ) {
  for (let i = 0; i < itemOBJ.length; i++) {
    const position = itemOBJ[i];
    const keys = Object.keys(position);
    if (keys.includes(key)) {
      return position[key];
    }
  }
  return ""; // Key not found
}

const firebaseGetDoc = async (c, id) => {
  let ref = doc(db, c, id);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return { ...docSnap.data() };
  } else {
    return false;
  }
};

export const selectTagData = (datas, type = "MULTI") => {
  if (!datas) {
    if (type === "MULTI") {
      return [];
    } else {
      return "";
    }
  }
  if (type === "MULTI") {
    return datas.map((data) => {
      return {
        value: data.id,
        label: data.lebel
      };
    });
  } else {
    const data = _.head(datas);
    return {
      value: data.id,
      label: data.lebel
    };
  }
};

export const selectCategoryData = (datas, type = "MULTI") => {
  if (type === "MULTI") {
    return datas.map((data) => {
      return {
        value: data.id,
        label: data.lebel
      };
    });
  } else {
    const data = _.head(datas);
    return {
      value: data.id,
      label: data.lebel
    };
  }
};

export const selectGroupData = (datas, type = "MULTI") => {
  if (type === "MULTI") {
    return datas.map((data) => {
      return {
        value: data.id,
        label: data.group_name
      };
    });
  } else {
    const data = _.head(datas);
    return {
      value: data.id,
      label: data.group_name
    };
  }
};

export const stringTruncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};

export const formatFirebaseDate = (firebaseTimestamp) => {
  const date = firebaseTimestamp.toDate(); // Convert Firebase Timestamp to JS Date object
  const formattedDate =
    date.getFullYear() +
    "." +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." + // Ensure 2 digits for month
    ("0" + date.getDate()).slice(-2); // Ensure 2 digits for day

  return formattedDate;
};
