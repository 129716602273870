import React, { useEffect } from "react";

import _ from "lodash";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Form,
  OverlayTrigger,
  Placeholder,
  Tooltip,
} from "react-bootstrap";

import { documentId } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faLock,
  faLockOpen,
  faRepeat,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AccountList.module.css";
import { useToast } from "../../../hooks/useToast";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { getGroupName, getRandomInt } from "../../../utils/Utils";

function MemberListItem({
  doc,
  getUpdatedMCDatabyID,
  ownerView = false,
  groupChangeModal = () => {},
  selectedUsersIds,
  setSelectedUsersIds,
  from = "",
  deleteUser,
}) {
  const toast = useToast();
  const { firebaseSetDoc, isPending, isConfirmed, error } = useSetDoc();
  const {
    getSingleDocWithQuery,
    data: fetchedGroups,
    isPending: groupPending,
    error: groupError,
  } = useGetDoc();
  // const { documents: fetchedGroups, isPending: groupPending, lastDoc, isLast, getMultipleDocs } = useCollection();

  const updateProfile = async (setData, id) => {
    await firebaseSetDoc("members", setData, id, true);
    await getUpdatedMCDatabyID(id);
    toast("success", "完了しました");
  };

  const publicTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      公開中
    </Tooltip>
  );

  const privateTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      非公開中
    </Tooltip>
  );
  const handleUsersCheckboxChange = (e) => {
    const articleId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedUsersIds.includes(articleId)) {
      // Add the article ID if it's not already in the array
      setSelectedUsersIds((prevIds) => [...prevIds, articleId]);
    } else if (!isChecked) {
      // Remove the article ID from the array
      setSelectedUsersIds((prevIds) =>
        prevIds.filter((id) => id !== articleId)
      );
    }
  };
  return (
    <>
      <tr className="mt-4">
        <td className="border border-end-0 rounded-start align-middle text-center">
          {from !== "dashboard" && selectedUsersIds !== undefined && (
            <Form.Check
              type="checkbox"
              value={doc.id}
              checked={selectedUsersIds.includes(doc.id)}
              onChange={handleUsersCheckboxChange}
            />
          )}
        </td>
        <td className="border-top border-bottom align-middle ">
          {doc.profilePhoto ? (
            <img
              src={doc.profilePhoto}
              className={`rounded-circle ${styles.user_photo}`}
              alt="user"
            />
          ) : (
            <img
              src="/img/no_user_pic.png"
              className={`rounded-circle ${styles.user_photo}`}
              alt="user"
            />
          )}
        </td>
        <td
          className="border-top border-bottom align-middle text-nowrap"
          data-bs-toggle="tooltip"
          data-bs-title="公開中">
          {!doc.private ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={publicTooltip}>
              <FontAwesomeIcon className="text-success" icon={faLockOpen} />
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={privateTooltip}>
              <FontAwesomeIcon className="text-warning" icon={faLock} />
            </OverlayTrigger>
          )}{" "}
          <Link to={`/mypage/user-details/${doc.memberId}`}>
            {doc.memberId}
          </Link>
        </td>
        <td className="border-top border-bottom align-middle text-break">
          {doc.email}
        </td>
        <td className="border border-start-0 rounded-end align-middle text-center">
          {from !== "dashboard" && (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                className="p-0 text-decoration-none text-dark">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {doc.private ? (
                  <>
                    <Dropdown.Item
                      className="btn text-success fw-bold"
                      onClick={() => updateProfile({ private: false }, doc.id)}>
                      <FontAwesomeIcon icon={faLockOpen} /> 公開
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item
                      className="btn  text-warning fw-bold"
                      onClick={() => updateProfile({ private: true }, doc.id)}>
                      <FontAwesomeIcon icon={faLock} /> 非公開
                    </Dropdown.Item>
                  </>
                )}

                <Dropdown.Divider />
              
                <Dropdown.Item
                  className="btn text-danger fw-bold"
                  onClick={() => deleteUser(doc)}>
                  <FontAwesomeIcon icon={faTrashAlt} /> 削除
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      </tr>

      <tr className="spacer">
        <td></td>
      </tr>
    </>
  );
}

export default MemberListItem;
