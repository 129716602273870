import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Ratio, Row, Spinner } from "react-bootstrap";
import CropModal from "../../../components/Common/ImageCrop/CropModal";
import { uploadString, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase/config"; // Import Firebase storage config
import { useAddDocs } from "../../../hooks/useAddDocs"; // Import custom hook
import { getRandomString } from "../../../utils/Utils";
import { useAggregation } from "../../../hooks/useAggregation";
import { useCollectionSnap } from "../../../hooks/useCollectionSnap";
import { useDeleteDocs } from "../../../hooks/useDeleteDocs";
import { useUpdateDoc } from "../../../hooks/useUpdateDoc";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getFirestore, writeBatch, doc } from "firebase/firestore"; // Import Firestore batch functionality

// Drag item types
const ItemType = "SLIDE";

// Draggable item view
const ItemView = ({ platform, item, ratio, position, moveItem }) => {
  const { deleteDocument, isPending } = useDeleteDocs();
  const { firebaseUpdateDoc } = useUpdateDoc();

  const deleteItem = async () => {
    await deleteDocument("slides", item.id);
  };

  useEffect(() => {
    if (item.position !== position) {
      firebaseUpdateDoc("slides", { position }, item.id); // Update position in Firestore
    }
  }, [position]);

  // Make the item draggable
  const [, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, position }
  });

  // Make the item droppable
  const [, drop] = useDrop({
    accept: ItemType,
    hover(draggedItem) {
      if (draggedItem.position !== position) {
        moveItem(draggedItem.position, position); // Update positions in local state
        draggedItem.position = position; // Update the dragged item's position
      }
    }
  });

  return (
    <div ref={(node) => drag(drop(node))}>
      <Card style={{ cursor: "grab" }}>
        <Card.Header className="d-flex justify-content-space-between align-items-center">
          <h5>
            <Badge bg="secondary">{position}</Badge>
          </h5>
          <Button variant="danger" onClick={deleteItem} disabled={isPending} className="ms-auto btn-sm">
            {!isPending ? "削除" : <Spinner size="sm" animation="border" />}
          </Button>
        </Card.Header>
        <Card.Body>
          <Ratio aspectRatio={platform == "pc" ? 1 / ratio : 186}>
            <img src={item.url} className="w-100" />
          </Ratio>
        </Card.Body>
      </Card>
    </div>
  );
};

const SlideUpload = ({ platform }) => {
  const [showCropModal, setShowCropModal] = useState(false);
  const [ratio, setRatio] = useState(1920 / 1080);
  const [imgSrc, setImgSrc] = useState("");
  const [uploading, setUploading] = useState(false);
  const [fileKey, setFileKey] = useState(Date.now());
  const { documents, getMultipleDocs } = useCollectionSnap();
  const db = getFirestore(); // Initialize Firestore

  const { addADoc, isPending, isConfirmed, error } = useAddDocs();
  const { countWithQuery } = useAggregation();

  useEffect(() => {
    if (platform === "pc") {
      setRatio(1920 / 1080);
    } else {
      setRatio(1125 / 2097);
    }
  }, [platform]);

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result.toString()); // Load image to display in crop modal
        setShowCropModal(true); // Open crop modal
      };
      reader.readAsDataURL(e.target.files[0]); // Read image file
    }
  };

  const handleCropImage = async (type, croppedUrl) => {
    setShowCropModal(false);
    const firebaseUrl = await uploadCroppedImage(croppedUrl);
    const count = await countWithQuery("slides", [["type", "==", platform]]);
    await addADoc("slides", {
      url: firebaseUrl,
      type: platform,
      position: count + 1
    });
    setUploading(false);
  };

  async function uploadCroppedImage(url) {
    setUploading(true);
    const imgRef = ref(storage, `image/slides/${getRandomString(10)}.webp`);
    await uploadString(imgRef, url, "data_url");
    setUploading(false);
    return await getDownloadURL(imgRef);
  }

  const loadData = () => {
    getMultipleDocs({
      _collection: "slides",
      orderByField: "position",
      orderByOption: "asc",
      _query: [["type", "==", platform]]
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (platform == "sp") {
      console.log(documents);
    }
  }, [documents]);

  // Function to move the item and update batch
  const moveItem = async (fromPosition, toPosition) => {
    const updatedDocuments = [...documents];
    const draggedItem = updatedDocuments.splice(fromPosition - 1, 1)[0]; // Remove the dragged item
    updatedDocuments.splice(toPosition - 1, 0, draggedItem); // Insert it at the new position

    // Update local positions
    updatedDocuments.forEach((doc, index) => {
      doc.position = index + 1;
    });

    // Perform batch update in Firestore
    const batch = writeBatch(db);
    updatedDocuments.forEach((docItem) => {
      const docRef = doc(db, "slides", docItem.id); // Correct usage of the doc function
      batch.update(docRef, { position: docItem.position });
    });

    try {
      await batch.commit(); // Commit the batch update
    } catch (error) {
      console.error("Error updating positions:", error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="mb-5">
        <Row>
          <Col xs={12}>
            <h3>{platform === "pc" ? "パソコン用" : "スマホ用"}</h3>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          {documents && Array.isArray(documents) && documents.length > 0 && (
            <>
              {documents.map((item, i) => {
                return (
                  <Col key={i} xs={12} sm={4} className="mt-3">
                    <ItemView
                      platform={platform}
                      item={item}
                      position={i + 1}
                      ratio={ratio}
                      moveItem={moveItem}
                    />
                  </Col>
                );
              })}
            </>
          )}

          <Col xs={12} sm={4}>
            <Card className="mt-3">
              <Card.Body>
                <Ratio aspectRatio={platform == "pc" ? 1 / ratio : ratio}>
                  <div className="d-flex px-2 align-items-center">
                    {uploading ? (
                      <Spinner animation="border" />
                    ) : (
                      <Form.Control key={fileKey} type="file" accept="image/*" onChange={handleImageUpload} />
                    )}
                  </div>
                </Ratio>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <CropModal
        show={showCropModal}
        setShow={setShowCropModal}
        setImage={handleCropImage}
        imgSrc={imgSrc}
        aspect={ratio}
        onClose={() => {
          setFileKey(Date.now());
        }}
      />
    </DndProvider>
  );
};

export default SlideUpload;
