import React, { useState } from "react";

import { useFormikContext } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CropModal from "../../../../components/Common/ImageCrop/CropModal";

function EditMemberInput() {
  const { width } = useWindowDimensions();
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
  } = useFormikContext();

  const croppedProfilePhotoString = "croppedProfilePhoto";
  const groupPageString = "editGroupPage";

  const [profilePhoto, setProfilePhoto] = useState(values.profilePhoto || "");
  const [show, setShow] = useState(false);
  const [ratio, setRatio] = useState(1);
  const [localStorageVar, setLocalStorageVar] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const cropProfilePhoto = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(1);
      setLocalStorageVar(croppedProfilePhotoString);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setCropImage = (type, url) => {
    if (type === croppedProfilePhotoString) {
      setProfilePhoto(url);
    }
  };

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}>
          イメージ写真
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 py-2 border-end border-secondary`}>
          {profilePhoto === "" ? (
            <Form.Control
              type="file"
              value={""}
              accept="image/*"
              onChange={(e) => {
                cropProfilePhoto(e);
              }}
            />
          ) : (
            <div
              className="position-relative mx-lg-0 mx-auto"
              style={{ width: "500px", maxWidth: "95%" }}>
              <img src={profilePhoto} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedProfilePhotoString);
                  setProfilePhoto("");
                  setFieldValue("profilePhoto", "");
                  const v = {
                    ...values,
                    ...JSON.parse(localStorage.getItem(groupPageString)),
                    profilePhoto: "",
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem(groupPageString, serializedState);
                }}>
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          会員番号<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.memberId}
              name="memberId"
              required
              autoComplete="off"
              isInvalid={touched.memberId && errors.memberId}
              isValid={touched.memberId && !errors.memberId}
            />
            <Form.Control.Feedback type="invalid">
              {errors.memberId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          名前<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            name="name"
            isInvalid={touched.name && errors.name}
            isValid={touched.name && !errors.name}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          フリガナ<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.furigana}
            name="furigana"
            isInvalid={touched.furigana && errors.furigana}
            isValid={touched.furigana && !errors.furigana}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.furigana}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          勤務先
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.place_of_employment}
            name="place_of_employment"
            isInvalid={
              touched.place_of_employment && errors.place_of_employment
            }
            isValid={touched.place_of_employment && !errors.place_of_employment}
          />
          <Form.Control.Feedback type="invalid">
            {errors.place_of_employment}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          事業形態
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.business_type}
            name="business_type"
            isInvalid={touched.business_type && errors.business_type}
            isValid={touched.business_type && !errors.business_type}
          />
          <Form.Control.Feedback type="invalid">
            {errors.business_type}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          部署
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.department}
            name="department"
            isInvalid={touched.department && errors.department}
            isValid={touched.department && !errors.department}
          />
          <Form.Control.Feedback type="invalid">
            {errors.department}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          肩書
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            name="title"
            isInvalid={touched.title && errors.title}
            isValid={touched.title && !errors.title}
          />
          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Col>
      </Row>


      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          郵便番号
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postal_code}
            name="postal_code"
            isInvalid={touched.postal_code && errors.postal_code}
            isValid={touched.postal_code && !errors.postal_code}
          />
          <Form.Control.Feedback type="invalid">
            {errors.postal_code}
          </Form.Control.Feedback>
        </Col>
      </Row>


      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          都道府県
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prefecture}
            name="prefecture"
            isInvalid={touched.prefecture && errors.prefecture}
            isValid={touched.prefecture && !errors.prefecture}
          />
          <Form.Control.Feedback type="invalid">
            {errors.prefecture}
          </Form.Control.Feedback>
        </Col>
      </Row>


      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          勤務先住所
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.work_address}
            name="work_address"
            isInvalid={touched.work_address && errors.work_address}
            isValid={touched.work_address && !errors.work_address}
          />
          <Form.Control.Feedback type="invalid">
            {errors.work_address}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          メールアドレス
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              isInvalid={touched.email && errors.email}
              isValid={touched.email && !errors.email}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          電話
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              name="phone"
              isInvalid={touched.phone && errors.phone}
              isValid={touched.phone && !errors.phone}
              placeholder="ハイフンを入力してください"
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          携帯電話
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile_phone}
              name="mobile_phone"
              isInvalid={touched.mobile_phone && errors.mobile_phone}
              isValid={touched.mobile_phone && !errors.mobile_phone}
              placeholder="ハイフンを入力してください"
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobile_phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          URL
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              name="url"
              isInvalid={touched.url && errors.url}
              isValid={touched.url && !errors.url}
            />
            <Form.Control.Feedback type="invalid">
              {errors.url}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          専門分野
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.area_of_expertise}
            name="area_of_expertise"
            isInvalid={touched.area_of_expertise && errors.area_of_expertise}
            isValid={touched.area_of_expertise && !errors.area_of_expertise}
          />
          <Form.Control.Feedback type="invalid">
            {errors.area_of_expertise}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          職業名 or 職種 
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.occupation}
            name="occupation"
            isInvalid={touched.occupation && errors.occupation}
            isValid={touched.occupation && !errors.occupation}
          />
          <Form.Control.Feedback type="invalid">
            {errors.occupation}
          </Form.Control.Feedback>
        </Col>
      </Row>
      

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          所属チーム
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.team}
            name="team"
            isInvalid={touched.team && errors.team}
            isValid={touched.team && !errors.team}
          />
          <Form.Control.Feedback type="invalid">
            {errors.team}
          </Form.Control.Feedback>
        </Col>
      </Row>


      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          所属エリア
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.area}
            name="area"
            isInvalid={touched.area && errors.area}
            isValid={touched.area && !errors.area}
          />
          <Form.Control.Feedback type="invalid">
            {errors.area}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          自己紹介
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.self_introduction}
              name="self_introduction"
              isInvalid={touched.self_introduction && errors.self_introduction}
              isValid={touched.self_introduction && !errors.self_introduction}
            />
            <Form.Control.Feedback type="invalid">
              {errors.self_introduction}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <CropModal
        show={show}
        setShow={setShow}
        setImage={setCropImage}
        imgSrc={imgSrc}
        aspect={ratio}
        localStorageVar={localStorageVar}
      />
    </>
  );
}

export default EditMemberInput;
