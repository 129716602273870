import React, { useEffect } from "react";
import Styles from "./LicensureExam.module.css";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import SupportPcSVG from "./SupportPcSVG";
import SupportSpSVG from "./SupportSpSVG";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Universal/UComponent/Sidebar";
import MetaData from "../../utils/MetaData";
import { useCollection } from "../../hooks/useCollection";

export const LicensureExam = () => {
  const { width } = useWindowDimensions();
  const { documents: examHistoryData, getMultipleDocs } = useCollection();
  const loadData = () => {
    getMultipleDocs({
      _collection: "examHistory",
      _query: [],
      orderByField: "position",
      orderByOption: "desc",
      _limit: 1
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.licensure}>
        <div className={Styles.pageIntro}>
          <Container className={Styles.container}>
            <div className={Styles.title}>LICENSURE EXAM</div>
            <div className={Styles.subtitle}>技能検定とは</div>
          </Container>
        </div>

        <div className={`mt-5 pt-2 mt-sm-5 pt-sm-5 ${width > 576 ? "container" : "container"}`}>
          <Row>
            <Col xs={12} sm={9}>
              <p className={Styles.introtext}>
                技能検定とは、職業能力開発促進法に基づき、受検者が持っている技能や知識を一定の基準により検定し、公証する試験です。受検者の技能と社会的・経済的地位の向上を図ることを目的とした国家検定制度です。
                <br></br>
                この技能能検定は試験の難易度によって1級、2級、3級に区分され、実技試験と学科試験により実施されます。両方の試験に合格した人には、1級は厚生労働省大臣名の、2級・3級は各都道府県知事名の合格証書と技能士章が交付され、
                <span>「</span>技能士」と称することができます。
                <br></br>
                技能検定は1959年に実施されて以来、現在130職種で実施されています。<br></br>
                <span>「</span>
                商品装飾展示」技能検定は、1986年度に新設され、2021年度までに1級240名、2級2,489名の合格者
                <span>（</span>
                商品装飾展示技能士）<span>が</span>
                誕生しました。また、3級は1998年度に新設され、2022年度までに5,419名の方が合格されています。
              </p>

              <div className={Styles.crImg}>
                <img src="/img/licensure_cr1.webp" />
                <img src="/img/licensure_cr2.webp" />
              </div>

              <div className={Styles.support}>
                <h4 className={Styles.itemTitle}>OUR SUPPORT</h4>
                <h4 className={Styles.itemTitle_jp}>VMD協会の支援概要</h4>
                <p className={Styles.description}>
                  日本ビジュアルマーチャンダイジング協会では、厚生労働省「商品装飾展示」技能検定に対し、VMDのための能力開発、および人材育成の見地から、事業活動の一環として支援活動を行っています。
                  <br className="d-block d-sm-none"></br>
                  「商品装飾展示」技能検定に対する、協会の支援活動は以下のとおりです。
                </p>

                <div className={Styles.supportsvg}>{width > 576 ? <SupportPcSVG /> : <SupportSpSVG />}</div>
              </div>

              {/* <div className={Styles.addressBook}>
                <table className={Styles.addressTable}>
                  <thead>
                    <tr>
                      <th>協会名</th>
                      <th>郵便番号</th>
                      <th>住所</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>北海道</td>
                      <td>003-0005</td>
                      <td>札幌市白石区東札幌5条1-1-2北海道立職業能力　開発支援センター内</td>
                    </tr>
                    <tr>
                      <td>山形県</td>
                      <td>990-2473</td>
                      <td>山形市松栄2-2-1</td>
                    </tr>
                    <tr>
                      <td>群馬県</td>
                      <td>372-0801</td>
                      <td>伊勢崎市宮子町1211-1</td>
                    </tr>
                    <tr>
                      <td>埼玉県</td>
                      <td>330-0074</td>
                      <td>さいたま市浦和区北浦和5-6-5 埼玉県浦和地方庁舎5F</td>
                    </tr>
                    <tr>
                      <td>東京都</td>
                      <td>101-8527</td>
                      <td>東京都千代田区内神田1-1-5 東京都産業労働局神田庁舎5階</td>
                    </tr>
                    <tr>
                      <td>新潟県</td>
                      <td>950-0965</td>
                      <td>新潟市中央区新光町15-2 新潟県公社総合ビル4F</td>
                    </tr>
                    <tr>
                      <td>石川県</td>
                      <td>920-0862</td>
                      <td>金沢市芳斉1-15-15 石川県職業能力開発プラザ3F</td>
                    </tr>
                    <tr>
                      <td>福井県</td>
                      <td>910-0003</td>
                      <td>福井市松本3-16-10 福井県職員会館ビル4階</td>
                    </tr>
                    <tr>
                      <td>静岡県</td>
                      <td>424-0881</td>
                      <td>静岡市清水区楠160</td>
                    </tr>
                    <tr>
                      <td>愛知県</td>
                      <td>451-0035</td>
                      <td>名古屋市西区浅間2-3-14</td>
                    </tr>
                    <tr>
                      <td>大阪府</td>
                      <td>550-0011</td>
                      <td>大阪市西区阿波座2-1-1 大阪本町西第一ビルディング6F</td>
                    </tr>
                    <tr>
                      <td>岡山県</td>
                      <td>700-0824</td>
                      <td>岡山市内山下2-3-10</td>
                    </tr>
                    <tr>
                      <td>愛媛県</td>
                      <td>791-1101</td>
                      <td>松山市久米窪田町487-2愛媛県産業技術研究所管理棟2F</td>
                    </tr>
                    <tr>
                      <td>高知県</td>
                      <td>781-5101</td>
                      <td>高知市布師田3992-4宮子町1211-1</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Add the Related Organizations Section here */}
              <div className={Styles.relatedOrganizations}>
                <div className={Styles.headingContent}>
                  <h3 className={Styles.relatedtitle}>Related Organization</h3>
                  <h4 className={Styles.relatedsubtitle}>関連団体リンク</h4>
                </div>
                <div className={Styles.ministries}>
                  <h4 className={Styles.organization}>関連省庁/団体</h4>
                  <a
                    href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/koyou_roudou/jinzaikaihatsu/ability_skill/ginoukentei/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Row className={`mb-4 ${Styles.information}`}>
                      <Col xs={12} sm={9}>
                        <p className="mb-0">厚生労働省 ホームページ</p>
                        <p className="mb-0 mt-0">(職業能力開発局 技能振興課 関係情報)</p>
                        <img src="/img/force_link_img01.webp" className="img-fluid p-0 img-organization" />
                      </Col>
                    </Row>
                  </a>
                  <a
                    href="https://www.javada.or.jp/jigyou/gino/giken.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Row className={`mb-4 ${Styles.information}`}>
                      <Col xs={12} sm={9}>
                        <p className="mb-0 mt-0">中央職業能力開発協会ホームページ</p>
                        <img
                          src="/img/force_link_img02.webp"
                          className={`img-fluid ${Styles.forceLinkImage}`}
                        />
                      </Col>
                    </Row>
                  </a>
                  <a href="https://www.tokyo-vada.or.jp/" target="_blank" rel="noopener noreferrer">
                    <Row className={`mb-4 ${Styles.information}`}>
                      <Col xs={12} sm={9}>
                        <p className="mb-0 mt-0">東京都職業能力開発協会ホームページ</p>
                        <img
                          src="/img/force_link_img03.webp"
                          className={`img-fluid ${Styles.forceLinkImage}`}
                        />
                      </Col>
                    </Row>
                  </a>
                  <Row className="mb-4 align-items-center">
                    <Col xs={12} sm={9}>
                      <div className={Styles.development}>
                        <p className={Styles.vocationalMain}>職業能力開発協会</p>
                        <a
                          href="https://www.javada.or.jp/kyoukai/itiran.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className={Styles.vocationalSub}>都道府県職業能力開発協会</p>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className={Styles.links}>
                <Link to={"/licensure-exam-faq"} className={Styles.button}>
                  {width > 576 ? "検定についてよくあるお問合せ" : "検定についてよくあるお問合せ"}
                </Link>
                {examHistoryData && Object.values(examHistoryData).length > 0 && (
                  <Link to={`/licensure-exam-history/${examHistoryData[0].id}`} className={Styles.button}>
                    検定の詳細・履歴
                  </Link>
                )}
              </div>
            </Col>
            <Col sm={3} className="px-0 mt-5 mt-sm-0">
              <Sidebar showMobile={true} />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};
