import React, { useEffect, useState } from "react";
import { Col, Ratio, Row } from "react-bootstrap";
import Styles from "./Event.module.css";
import { Link } from "react-router-dom";
import { useCollectionSimple } from "../../hooks/useCollectionSimple";
import { formatFirebaseDate } from "../../utils/Utils";

const EventList = () => {
  const limit = 9;
  const { documents, isPending, lastDoc, isLast, getMultipleDocs } = useCollectionSimple();
  const [eveents, setEvents] = useState({});

  const loadEvents = async () => {
    await getMultipleDocs({
      _collection: "events",
      orderByField: "createdAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false,
      _query: [
        ["status", "==", "PUBLISHED"],
        ["private", "==", "false"]
      ]
    });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  useEffect(() => {
    if (documents) {
      setEvents((prevEvents) => {
        const newEvents = { ...prevEvents };
        documents.forEach((doc) => {
          newEvents[doc.id] = doc;
        });
        return newEvents;
      });
    }
  }, [documents]);

  const placeholders = Array.from({ length: limit }, (_, i) => (
    <div key={i} className="placeholder-glow px-3 px-sm-3 my-sm-3 col-sm-4 col-xs-6 mt-4">
      <div className="align-middle p-0">
        <Ratio aspectRatio="1x1" className="placeholder">
          <img src={"/img/no_img.png"} className="w-100" alt="Placeholder" />
        </Ratio>
        <p className={`placeholder ${Styles.memeberName}`}>お名前お名前お名前</p>
        <br></br>
        <p className={`placeholder ${Styles.memeberTitle}`}>協会理事長</p>
      </div>
    </div>
  ));

  return (
    <>
      <Row>
        {isPending && placeholders}

        {Object.values(eveents).length > 0 &&
          Object.values(eveents).map((item, i) => (
            <Col key={i} xs={6} sm={4} className="px-1 px-sm-3 my-sm-3 mt-5 mt-sm-4">
              <Link to={`/events/${item.id}`} className={Styles.link}>
                <Ratio aspectRatio="1x1">
                  <img src={item.mainPhoto} className="w-100" />
                </Ratio>
                <p className={Styles.eventTitle}>{item.title}</p>
                <p className={Styles.eventDate}>{formatFirebaseDate(item.createdAt)}</p>
              </Link>
            </Col>
          ))}

        {/* {!isLast && !isPending && (
          <div className="d-flex w-100 justify-content-center mt-3 mt-sm-5">
            <button className={Styles.loadMore} onClick={loadMoreMembers}>
              もっと見る
            </button>
          </div>
        )} */}
      </Row>
    </>
  );
};

export default EventList;
