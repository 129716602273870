import { useState, useEffect } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { updatePassword } from "firebase/auth";

export const useUpdatePassword = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const updatePasswordFn = (password) => {
    setError(null);
    setMessage(null);
    setIsPending(true);

    updatePassword(auth.currentUser, password)
      .then((res) => {
        setMessage("パスワードを変更しました");
        setIsPending(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };
  return { error, isPending, message, isConfirmed, updatePasswordFn };
};
