import React, { useEffect, useState } from "react";

import _ from "lodash";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import EditMemberInput from "./EditMemberInput";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import {
  email,
  openStringSchema,
  phoneSchemaBoth,
  numberOnlySchema,
  kanaOnlySchema,
} from "../../../../utils/InputSchema";

function EditMember() {
  const params = useParams();
  const navigate = useNavigate();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  const helmetData = new HelmetData({});

  const [initialValues, setInitialValues] = useState(null);

  const backData = localStorage.getItem("editMemberData");

  useEffect(() => {
    async function fetchData() {
      await getSingleDocWithQuery("members", [
        ["memberId", "==", parseInt(params.memberId)],
      ]).then((res) => {
        if (res && res[0]) {
          const init = {
            memberId: res[0].memberId || "", //会員番号
            name: res[0].name || "", //名前
            furigana: res[0].furigana || "", //ふりがな
            place_of_employment: res[0].place_of_employment || "", //勤務先
            business_type: res[0].business_type || "", //事業形態
            department: res[0].department || "", //部署
            title: res[0].title || "", //肩書
            postal_code: res[0].postal_code || "", //郵便番号
            prefecture: res[0].prefecture || "", //都道府県
            work_address: res[0].work_address || "", //勤務先住所
            email: res[0].email || "", //メールアドレス
            phone: res[0].phone || "", // 電話
            mobile_phone: res[0].mobile_phone || "", // 携帯電話
            area_of_expertise: res[0].area_of_expertise || "", //専門分野
            occupation: res[0].occupation || "", //職業名 or 職種
            url: res[0].url || "", //URL
            team: res[0].team || "", //所属チーム
            area: res[0].area || "", //所属エリア
            self_introduction: res[0].self_introduction || "", //自己紹介
            profilePhoto: res[0].profilePhoto || "", //自己紹介
          };
          setInitialValues(init);
        }
      });
    }
    if (!backData) {
      fetchData();
    } else {
      console.log(JSON.parse(localStorage.getItem("editMemberData")));
      setInitialValues(JSON.parse(localStorage.getItem("editMemberData")));
    }
  }, [params.memberId]);

  const schema = yup.object().shape({
    //会員番号
    memberId: numberOnlySchema()
      .required("この欄は必須です")
      .test(
        "is-memberId-unique",
        "ご登録会員番号がすでに登録済み、もしくは使用できない情報です",
        async (value) => {
          if (value && value != params.memberId) {
            let user = await getSingleDocWithQuery("members", [
              ["memberId", "==", value],
            ]);
            return !user;
          }
          return true;
        }
      ),
    //名前
    name: openStringSchema(32).required("この欄は必須です"),
    //ふりがな
    furigana: kanaOnlySchema(32).required("この欄は必須です"),
    //勤務先
    place_of_employment: openStringSchema(1000),
    //事業形態
    business_type: openStringSchema(1000),
    //部署
    department: openStringSchema(1000),
    //肩書
    title: openStringSchema(1000),
    //郵便番号
    postal_code: openStringSchema(1000),
    //都道府県
    prefecture: openStringSchema(1000),
    //勤務先住所
    work_address: openStringSchema(1000),
    //メールアドレス
    email: email().test(
      "is-unique",
      "ご登録メールアドレスがすでに登録済み、もしくは使用できない情報です。",
      async (value, context) => {
        if (value) {
          let user = await getSingleDocWithQuery("members", [
            ["email", "==", value],
            ["memberId", "!=", context.parent.memberId],
          ]);
          return !user;
        }
        return true;
      }
    ),
    // 電話
    phone: phoneSchemaBoth(),
    // 携帯電話
    mobile_phone: phoneSchemaBoth(),
    //専門分野
    area_of_expertise: openStringSchema(1000),
    //職業名 or 職種
    occupation: openStringSchema(1000),
    //URL
    url: yup.string().nullable().url("有効なURLを入力してください"),
    //所属チーム
    team: openStringSchema(1000),
    //所属エリア
    area: openStringSchema(1000),
    //自己紹介
    self_introduction: openStringSchema(1000),
  });

  const handleSubmit = (values) => {
    console.log(values);
    const profilePhoto =
      localStorage.getItem("croppedProfilePhoto") ||
      (_.has(JSON.parse(backData), "profilePhoto")
        ? JSON.parse(backData).profilePhoto
        : null);
    if (profilePhoto && profilePhoto !== undefined) {
      values.profilePhoto = profilePhoto;
      localStorage.removeItem("croppedImg");
    }

    try {
      const serializedState = JSON.stringify(values);
      console.log("serializedState", serializedState);
      localStorage.setItem("editMemberData", serializedState);
      navigate(`/mypage/edit-member/check/${params.memberId}`);
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: backData
                ? `/mypage/edit-member/check/${params.memberId}`
                : "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={1}
        />
      </div>
      <br />
      {initialValues && (
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg={10} md={8}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <EditMemberInput />

                    <div className="text-center mt-4 mb-3">
                      <Button className="" variant="primary" type="submit">
                        <b>
                          次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                        </b>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default EditMember;
