import React, { useEffect, useState } from "react";
import Styles from "./Events.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventItem from "./EventItem";
import MoreLink from "../../../components/Universal/More/MoreLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useCollectionSimple } from "../../../hooks/useCollectionSimple";

const Events = () => {
  const [eventItems, setEventItems] = useState([]);
  const { width } = useWindowDimensions();

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return <div className={` ${Styles.NextArrow}`} onClick={onClick} />;
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return <div className={` ${Styles.PrevArrow}`} onClick={onClick} />;
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const limit = 6;
  const { documents, isPending, lastDoc, isLast, getMultipleDocs } =
    useCollectionSimple();
  const [eveents, setEvents] = useState({});

  const loadEvents = async () => {
    await getMultipleDocs({
      _collection: "events",
      orderByField: "createdAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false,
      _query: [["status", "==", "PUBLISHED"]],
    });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  useEffect(() => {
    if (documents) {
      setEvents((prevEvents) => {
        const newEvents = { ...prevEvents };
        documents.forEach((doc) => {
          newEvents[doc.id] = doc;
        });
        return newEvents;
      });
    }
  }, [documents]);

  const placeholders = Array.from({ length: limit }, (_, i) => (
    <div key={i} className="placeholder-glow ">
      <div className="align-middle p-0">
        <div className="placeholder">
          <EventItem
            data={{
              img: "/img/frontend/event1.webp",
              title: "イベントタイトル",
              date: "2024.06.25",
              description:
                "イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。",
            }}
            key={i}
          />
        </div>
      </div>
    </div>
  ));

  return (
    <div className={Styles.events}>
      <Container>
        <Row>
          <Col xs={12}>
            <p className={Styles.title}>EVENTS/NEWS</p>
            <p className={Styles.subtitle}>イベント/ニュース</p>
          </Col>
        </Row>

        {width > 576 && (
          <div className={Styles.moreLink}>
            <MoreLink url={"/events"} />
          </div>
        )}

        <div className={Styles.slide}>
          <Slider {...settings}>
            {isPending && placeholders}
            {!isPending &&
              Object.values(eveents).length > 0 &&
              Object.values(eveents).map((item, i) => {
                return <EventItem data={item} key={i} />;
              })}
          </Slider>
        </div>

        {width <= 576 && (
          <div className={Styles.moreLink}>
            <MoreLink url={"/events"} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Events;
