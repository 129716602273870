import React, { useEffect } from "react";
import Styles from "./MembershipRegistration.module.css";
import Header from "../../components/Universal/Header/Header";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Footer from "../../components/Universal/Footer/Footer";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MetaData from "../../utils/MetaData";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSendEmail } from "../../hooks/useSendMail";

const MembershipRegistration = () => {
  const { width } = useWindowDimensions();
  const { sendMail, isConfirmed, isPending, error } = useSendEmail();
  const navigate = useNavigate();

  let initialValues = {
    name: "", // 名前
    furigana: "", // ふりがな\
    postal_code: "", // 郵便番号
    prefecture: "", // 都道府県
    work_address: "", // 勤務先住所
    email: "", // メールアドレス
    phone: "", // 電話
    place_of_employment: "", // 勤務先
    business_type: "", // 事業形態
    department: "", // 部署
    title: "", // 肩書
    mobile_phone: "", // 携帯電話
    area_of_expertise: "", // 専門分野
    occupation: "" // 所属エリア
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("お名前は必須です")
      .test(
        "not-only-numbers",
        "お名前は数字のみでは入力できません",
        (value) => isNaN(value) || value.match(/[a-zA-Z]/)
      ),
    furigana: Yup.string()
      .matches(/^[\u30A1-\u30FF]*$/, "全角カタカナのみ使用できます")
      .required("フリガナは必須です"),
    postal_code: Yup.string()
      .matches(/^\d{3}-\d{4}$/, "有効な郵便番号を入力してください (例: 123-4567)")
      .required("郵便番号は必須です"),
    prefecture: Yup.string().required("都道府県 は必須です"),
    work_address: Yup.string().required("市区・町村・番地・ビル名 は必須です"),
    email: Yup.string().email("有効なメールアドレスを入力してください").required("メールアドレスは必須です"),
    phone: Yup.string()
      .matches(
        /^(0\d{1,4})-?(\d{1,4})-?(\d{4})$/,
        "有効な電話番号を入力してください (例: 03-0000-1111 または 090-0000-1111)"
      )
      .required("電話番号は必須です"),

    place_of_employment: Yup.string().required("勤務先は必須です"),
    business_type: Yup.string().required("事業形態は必須です"),

    department: Yup.string(),
    title: Yup.string(),
    mobile_phone: Yup.string(),
    url: Yup.string().url("無効なURLです"),
    area_of_expertise: Yup.string(),

    occupation: Yup.string().required("職業名 or 職種は必須です"),
    privacyPolicy: Yup.boolean()
      .oneOf([true], "プライバシーポリシーに同意してください")
      .required("プライバシーポリシーに同意してください")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("Form Values:", values);
      sendMail({
        mailOption: {
          from: "日本ビジュアルマーチャンダイジング協会 <info@javma.com>",
          to: values.email,
          cc: "info@javma.com",
          subject: "【日本ビジュアルマーチャンダイジング協会】お問い合わせ送信完了のお知らせ",
          html: `
            ${values.name}様 <br>
          <br>
          ※このメールはメッセージの送信が完了した時点で自動で配信されるメールです。<br>
          <br>
          この度は、日本ビジュアルマーチャンダイジング協会へ<br>
          入会資料のご請求をいただき誠にありがとうございました。<br>
          下記の内容で承りましたのでご確認ください。<br>
          改めて、事務局よりご連絡をさせて頂きますのでしばらくお待ちください。<br>
          <br>
          <hr>
          [氏名　　　　　]　${values.name}<br>
          [フリガナ　　　]　${values.furigana}<br>
          [郵便番号　　　]　${values.postal_code}<br>
          [都道府県　　　]　${values.prefecture}<br>
          [市区・町村・番地・ビル名 ]　${values.work_address}<br>
          [メールアドレス]　${values.email}<br>
          [電話　　　　　]　${values.phone}<br>
          [勤務先　　　　]　${values.place_of_employment}<br>
          [事業形態　   ]　${values.business_type}<br>
          [部署　　　　　]　${values.department}<br>
          [肩書　　　　　]　${values.title}<br>
          [携帯電話　　　]　${values.mobile_phone}<br>
          [URL　　　　　]　${values.url}<br>
          [専門分野　　　]　${values.area_of_expertise}<br>
          [職業名 or 職種 ]　${values.occupation}<br>
          <hr>
          <br>
          ※1週間以上経っても事務局から連絡がない場合は、<br>
          お手数をおかけいたしますが、以下の事務局までお問い合わせください。<br>
          ※本メールにお心当たりのない場合は、誠に恐れ入りますが、<br>
          破棄していただくようにお願い申し上げます。<br>
          <hr>
          日本ビジュアルマーチャンダイジング協会<br>
          <a href="mailto: info@javma.com">info@javma.com</a>
          <hr>
          `
        }
      });
    }
  });

  useEffect(() => {
    if (isConfirmed) {
      navigate("/message-sent");
    }
  }, [isConfirmed]);

  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.membership}>
        <div className={Styles.pageIntro}>
          <Container className={Styles.container}>
            <div className={Styles.title}>REQUEST FOR INFORMATION</div>
            <div className={Styles.subtitle}>入会資料請求</div>
          </Container>
        </div>

        <Container className={`${Styles.container} mt-5 mt-sm-5`}>
          <Row className="d-flex justify-content-center">
            <Col xs={11} sm={8} className="mt-sm-5">
              <p className={Styles.introtext}>
                下記入力の上、よろしければ{width <= 576 ? <br></br> : <></>}確認ボタンをクリックしてください
              </p>
            </Col>

            <Col sm={8} className="mt-sm-2">
              <Row>
                <form onSubmit={formik.handleSubmit}>
                  <Col sm={12}>
                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.name && formik.errors.name ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>氏名：</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          {...formik.getFieldProps("name")}
                          className={formik.touched.name && formik.errors.name ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.name && formik.errors.name && (
                        <div className={Styles.formError}>{formik.errors.name}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.furigana && formik.errors.furigana ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>フリガナ：</Form.Label>
                        <Form.Control
                          type="text"
                          name="furigana"
                          {...formik.getFieldProps("furigana")}
                          className={formik.touched.furigana && formik.errors.furigana ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.furigana && formik.errors.furigana && (
                        <div className={Styles.formError}>{formik.errors.furigana}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.postal_code && formik.errors.postal_code
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>郵便番号：</Form.Label>
                        <Form.Control
                          type="text"
                          name="postal_code"
                          {...formik.getFieldProps("postal_code")}
                          className={
                            formik.touched.postal_code && formik.errors.postal_code ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.postal_code && formik.errors.postal_code && (
                        <div className={Styles.formError}>{formik.errors.postal_code}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.prefecture && formik.errors.prefecture ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>都道府県：</Form.Label>
                        <Form.Control
                          type="text"
                          name="prefecture"
                          {...formik.getFieldProps("prefecture")}
                          className={
                            formik.touched.prefecture && formik.errors.prefecture ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.prefecture && formik.errors.prefecture && (
                        <div className={Styles.formError}>{formik.errors.prefecture}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.work_address && formik.errors.work_address
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>市区・町村・番地・ビル名：</Form.Label>
                        <Form.Control
                          type="text"
                          name="work_address"
                          {...formik.getFieldProps("work_address")}
                          className={
                            formik.touched.work_address && formik.errors.work_address ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.work_address && formik.errors.work_address && (
                        <div className={Styles.formError}>{formik.errors.work_address}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.email && formik.errors.email ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>メールアドレス：</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          {...formik.getFieldProps("email")}
                          className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email && (
                        <div className={Styles.formError}>{formik.errors.email}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.phone && formik.errors.phone ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>電話：</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          {...formik.getFieldProps("phone")}
                          className={formik.touched.phone && formik.errors.phone ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.phone && formik.errors.phone && (
                        <div className={Styles.formError}>{formik.errors.phone}</div>
                      )}
                    </Form.Group>

                    <p className={Styles.subHead}>勤務先情報</p>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.place_of_employment && formik.errors.place_of_employment
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>勤務先（個人の場合は「個人」と表記）：</Form.Label>
                        <Form.Control
                          type="text"
                          name="place_of_employment"
                          {...formik.getFieldProps("place_of_employment")}
                          className={
                            formik.touched.place_of_employment && formik.errors.place_of_employment
                              ? "is-invalid"
                              : ""
                          }
                        />
                      </div>
                      {formik.touched.place_of_employment && formik.errors.place_of_employment && (
                        <div className={Styles.formError}>{formik.errors.place_of_employment}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.business_type && formik.errors.business_type
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>事業形態：</Form.Label>
                        <Form.Control
                          type="text"
                          name="business_type"
                          {...formik.getFieldProps("business_type")}
                          className={
                            formik.touched.business_type && formik.errors.business_type ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.business_type && formik.errors.business_type && (
                        <div className={Styles.formError}>{formik.errors.business_type}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.department && formik.errors.department ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>部署：</Form.Label>
                        <Form.Control
                          type="text"
                          name="department"
                          {...formik.getFieldProps("department")}
                          className={
                            formik.touched.department && formik.errors.department ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.department && formik.errors.department && (
                        <div className={Styles.formError}>{formik.errors.department}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.title && formik.errors.title ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>肩書：</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          {...formik.getFieldProps("title")}
                          className={formik.touched.title && formik.errors.title ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.title && formik.errors.title && (
                        <div className={Styles.formError}>{formik.errors.title}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.mobile_phone && formik.errors.mobile_phone
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>携帯電話：</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile_phone"
                          {...formik.getFieldProps("mobile_phone")}
                          className={
                            formik.touched.mobile_phone && formik.errors.mobile_phone ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.mobile_phone && formik.errors.mobile_phone && (
                        <div className={Styles.formError}>{formik.errors.mobile_phone}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.url && formik.errors.url ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>URL：</Form.Label>
                        <Form.Control
                          type="text"
                          name="url"
                          {...formik.getFieldProps("url")}
                          className={formik.touched.url && formik.errors.url ? "is-invalid" : ""}
                        />
                      </div>
                      {formik.touched.url && formik.errors.url && (
                        <div className={Styles.formError}>{formik.errors.url}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.area_of_expertise && formik.errors.area_of_expertise
                            ? Styles.itemErrorBorder
                            : ""
                        }`}
                      >
                        <Form.Label>専門分野：</Form.Label>
                        <Form.Control
                          type="text"
                          name="area_of_expertise"
                          {...formik.getFieldProps("area_of_expertise")}
                          className={
                            formik.touched.area_of_expertise && formik.errors.area_of_expertise
                              ? "is-invalid"
                              : ""
                          }
                        />
                      </div>
                      {formik.touched.area_of_expertise && formik.errors.area_of_expertise && (
                        <div className={Styles.formError}>{formik.errors.area_of_expertise}</div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div
                        className={`${Styles.item} ${
                          formik.touched.occupation && formik.errors.occupation ? Styles.itemErrorBorder : ""
                        }`}
                      >
                        <Form.Label>職業名 or 職種：</Form.Label>
                        <Form.Control
                          type="text"
                          name="occupation"
                          {...formik.getFieldProps("occupation")}
                          className={
                            formik.touched.occupation && formik.errors.occupation ? "is-invalid" : ""
                          }
                        />
                      </div>
                      {formik.touched.occupation && formik.errors.occupation && (
                        <div className={Styles.formError}>{formik.errors.occupation}</div>
                      )}
                    </Form.Group>

                    <p className={Styles.pinfo}>個人情報について</p>
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        className={`form-check ${Styles.checkbox} `}
                        name="privacyPolicy"
                        id="privacyPolicy"
                        {...formik.getFieldProps("privacyPolicy")}
                      />
                      <div className={Styles.terms}>
                        当協会の
                        <Link to={"/privacy-policy"} target="_blank">
                          個人情報保護方針
                        </Link>
                        に同意して送信します。
                      </div>
                    </div>

                    <div className="w-100 d-flex justify-content-center mt-5">
                      <Button type="submit" className={Styles.submitButton} disabled={isPending}>
                        {isPending ? (
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                          <>送信</>
                        )}
                      </Button>
                    </div>
                  </Col>
                </form>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default MembershipRegistration;
