import React from "react";

import * as yup from "yup";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import { useAddDocs } from "../../../hooks/useAddDocs";
import CreateHistoryInput from "./CreateHistoryInput";
import { useAggregation } from "../../../hooks/useAggregation";
import { useToast } from "../../../hooks/useToast";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useUpdateDoc } from "../../../hooks/useUpdateDoc";

function CreateHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const existData = location.state || {};
  const { addADoc, isPending } = useAddDocs();
  const { firebaseSetDoc, isPending: isPending2 } = useSetDoc();
  const { countWithQuery } = useAggregation();
  const toast = useToast();

  const helmetData = new HelmetData({});

  let initialValues = {
    title: existData.title || "",
    content: existData.content || "",
  };

  const schema = yup.object().shape({
    title: yup
      .string()
      .trim("先頭または、末尾に空白文字は使用できません")
      .strict()
      .required("この欄は必須です"),
    content: yup.string(),
  });

  const handleSubmit = async (values) => {
    const count = await countWithQuery("examHistory");

    if (existData && existData.id) {
      await firebaseSetDoc("examHistory", values, existData.id, true)
        .then(async () => {
          navigate("/mypage/create-exam-list");
        })
        .catch((error) => {
          toast("danger", "何かが間違っています。後でもう一度試してください");
        });
    } else {
      const data = { ...values, view: 0, position: count + 1 };
      await addADoc("examHistory", data)
        .then(() => {
          localStorage.removeItem("croppedThambnailImage");
          navigate("/mypage/create-exam-list");
        })
        .catch((error) => {
          toast("danger", "何かが間違っています。後でもう一度試してください");
          // console.log(error);
        });
    }
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}>
              {({ handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <CreateHistoryInput />

                  <div className="text-center mt-4 mb-3">
                    <Button className="" variant="primary" type="submit">
                      <b>
                        保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                      </b>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateHistory;
