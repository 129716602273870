import React from "react";
import Styles from "./MoreLink.module.css";
import { Link } from "react-router-dom";

const MoreLink = ({ raw = false, url, type = 1 }) => {
  return (
    <>
      {type == 1 && (
        <>
          {raw ? (
            <a href={url} className={Styles.linkMore}>
              <p>MORE</p>
              <img src="/img/arrowRight.svg" />
            </a>
          ) : (
            <Link to={url} className={Styles.linkMore}>
              <p>MORE</p>
              <img src="/img/arrowRight.svg" />
            </Link>
          )}
        </>
      )}
      {type == 2 && (
        <>
          {raw ? (
            <a href={url} className={`${Styles.linkMore} ${Styles.whiteTxt}`}>
              <p>MORE</p>
              <img src="/img/arrowRightw.svg" />
            </a>
          ) : (
            <Link to={url} className={`${Styles.linkMore} ${Styles.whiteTxt}`}>
              <p>MORE</p>
              <img src="/img/arrowRightw.svg" />
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default MoreLink;
