import React, { useRef, useState } from "react";

import * as yup from "yup";
import CSVReader from "react-csv-reader";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { useGetDoc } from "../../../../hooks/useGetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import {
  email,
  kanaOnlySchema,
  numberOnlySchema,
  openStringSchema,
  phoneSchemaBoth,
} from "../../../../utils/InputSchema";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAggregation } from "../../../../hooks/useAggregation";

const SupportMemberCSVUpload = () => {
  const [invalidCsvData, setInvalidCsvData] = useState(null); // State to store CSV data
  const [validCsvData, setValidCsvData] = useState(null); // State to store CSV data
  const [isValidationPending, setIsValidationPending] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const { addADoc, isPending, error } = useAddDocs();
  const [isUploading, setIsUploading] = useState(false);
  const csvReaderRef = useRef(null);

  const { getSingleDocWithQuery } = useGetDoc();
  const { firebaseSetDoc } = useSetDoc();

  const { countWithQuery } = useAggregation();
  const keys = {
    memberId: "会員番号", //会員番号 --
    company_name: "企業名", //企業名
    postal_code: "郵便番号", //郵便番号
    prefecture: "都道府県", //都道府県
    work_address: "勤務先住所", //勤務先住所
    phone: "電話", // 電話
    url: "URL", //URL
  };

  const handleFileSelect = (data, fileInfo) => {
    setIsUploading(false);
    setUploadedData([]);

    const modifiedData = data.map((item) => {
      return {
        memberId: item[keys.memberId] ? item[keys.memberId] : "",
        company_name: item[keys.company_name] ? item[keys.company_name].trim() : "",
        postal_code: item[keys.postal_code]
          ? item[keys.postal_code].trim()
          : "",
        prefecture: item[keys.prefecture] ? item[keys.prefecture].trim() : "",
        work_address: item[keys.work_address]
          ? item[keys.work_address].trim()
          : "",
        phone: item[keys.phone]
          ? item[keys.phone].trim().replace(/[−–—―‑‒－]/g, "-")
          : "",
        url: item[keys.url] ? item[keys.url].trim() : "",
      };
    });

    validateData(modifiedData);
  };

  const validateData = async (data) => {
    const validatedData = [];
    const invalidData = [];
    setIsValidationPending(true);

    const memberCreateSchema = yup.object().shape({
      //会員番号
      memberId: numberOnlySchema().required("この欄は必須です"),
      //名前
      company_name: openStringSchema(32).required("この欄は必須です"),
      postal_code: openStringSchema(1000),
      //都道府県
      prefecture: openStringSchema(1000),
      //勤務先住所
      work_address: openStringSchema(1000),
      // 電話
      phone: phoneSchemaBoth(),
      //URL
      url: yup.string().nullable().url("有効なURLを入力してください"),
    });

    for (const item of data) {
      try {
        await memberCreateSchema.validate(item, { abortEarly: false });
        validatedData.push({ ...item });
      } catch (validationError) {
        let invalidItem = {};
        Object.entries(keys).forEach(([key, value]) => {
          invalidItem[value] = { value: item[key] };
        });
        validationError.inner.forEach((error) => {
          // console.log(error.path);
          invalidItem[keys[error.path]].error = error.message;
        });

        invalidItem = {
          ...invalidItem,
        };
        invalidData.push(invalidItem);
      }
    }
    setInvalidCsvData(invalidData);
    setValidCsvData(validatedData);
    setIsValidationPending(false);
  };

  const handleGroupAdd = async () => {
    if (!validCsvData) {
      return;
    }
    setIsUploading(true);
    let items = [];
    await Promise.all(
      validCsvData.map(async (data) => {
        
        const count = await countWithQuery("supportmembers");

        // Check if a document with the memberId already exists
        const existingMember = await getSingleDocWithQuery(
          "supportmembers",
          [["memberId", "==", data.memberId]],
          null,
          "desc",
          1 // Only need to find one matching document
        );

        if (existingMember && existingMember.length > 0) {
          // If member exists, retain existing data for 'view' and 'status'
          const memberId = existingMember[0].id; // Get the document ID
          const existingData = existingMember[0]; // Existing document data

          await firebaseSetDoc(
            "supportmembers",
            {
              ...data,
              view: existingData.view ?? 0, // Retain existing 'view', default to 0 if not present
              status: existingData.status ?? "PUBLISHED", // Retain existing 'status', default to "PUBLISHED"
              private: existingData.private ?? false, // Retain existing 'private', default to false
              position: existingData.position ?? count + 1,
            },
            memberId, // Update the existing document by ID
            true // Use merge option to update the existing fields
          ).then(() => {
            items.push({
              [keys.memberId]: data.memberId,
              [keys.company_name]: data.company_name,
              [keys.postal_code]: data.postal_code,
              [keys.prefecture]: data.prefecture,
              [keys.work_address]: data.work_address,
              [keys.phone]: data.phone,
              [keys.url]: data.url,
              " ": `<a class="btn btn-primary" target="_blank" href="/mypage/support-user-details/${data.memberId}">詳細</a>`,
            });
            setUploadedData(items);
          });
        } else {
          // If member does not exist, add a new document
          await addADoc("supportmembers", {
            ...data,
            view: 0, // Set default view for new documents
            status: "PUBLISHED", // Set default status for new documents
            private: false,
            position: count + 1,
          }).then((res) => {
            if (res) {
              items.push({
                [keys.memberId]: data.memberId,
                [keys.company_name]: data.company_name,
                [keys.postal_code]: data.postal_code,
                [keys.prefecture]: data.prefecture,
                [keys.work_address]: data.work_address,
                [keys.phone]: data.phone,
                [keys.url]: data.url,
                " ": `<a class="btn btn-primary" target="_blank" href="/mypage/support-user-details/${data.memberId}">詳細</a>`,
              });
              setUploadedData(items);
            }
          });
        }
      })
    );

    setUploadedData(items);
    setIsUploading(false);
    setInvalidCsvData(null);
    setValidCsvData(null);

    if (csvReaderRef.current) {
      csvReaderRef.current.value = "";
    }
  };

  return (
    <Container>
      <h4>CSVデータ登録</h4>
      <Row>
        <Col xs={12} sm={4}>
          <CSVReader
            onFileLoaded={handleFileSelect} // Handle CSV file selection
            cssClass="input-group custom-file-button"
            ref={csvReaderRef}
            cssLabelClass="input-group-text"
            cssInputClass="form-control"
            parserOptions={{
              header: true,
              dynamicTyping: true,
              skipEmptyLines: true,
              transformHeader: (header) => header.trim(), // Trim header names
            }}
          />
        </Col>
        {validCsvData && !isValidationPending && (
          <>
            <Col xs={12} sm={8}>
              {validCsvData.length > 0 && (
                <Button
                  variant="success"
                  className="mt-2 mt-sm-0"
                  onClick={handleGroupAdd}
                  disabled={isUploading}>
                  {!isUploading && (
                    <>{validCsvData.length} つのcsvデータを登録する</>
                  )}
                  {isUploading && (
                    <div className="d-flex justify-content-center align-items-center">
                      {uploadedData.length} 件のデータをアップロード
                      <Spinner
                        animation="border"
                        role="status"
                        className="ms-2">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </Button>
              )}
              {validCsvData.length == 0 && (
                <Button variant="light">
                  アップロードするデータがありません
                </Button>
              )}
            </Col>
          </>
        )}
      </Row>

      {uploadedData && uploadedData.length > 0 && (
        <Row className="mt-5">
          <Col sm={12}>
            <h5>登録が完了した情報</h5>
            <Table>
              <thead>
                <tr>
                  {Object.keys(uploadedData[0]).map((key, index) => (
                    <th className="text-nowrap" key={index}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {uploadedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                      <td key={colIndex}>
                        <p
                          className="m-0"
                          style={{ textOverflow: "ellipsis" }}
                          dangerouslySetInnerHTML={{ __html: value }}></p>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {isValidationPending && (
        <>
          <Row className="d-flex justify-content-center mt-5 py-5">
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
          </Row>
        </>
      )}
      {!isValidationPending && (
        <>
          {invalidCsvData && invalidCsvData.length > 0 && (
            <Row className="mt-5">
              <Col xs={12} style={{ overflow: "auto" }}>
                <h5 className="text-danger">CSVの内容が無効です。</h5>
                <Table>
                  <colgroup>
                    {Object.keys(invalidCsvData[0]).map((_, index) => (
                      <col
                        key={index}
                        style={{
                          width: `${
                            100 / Object.keys(invalidCsvData[0]).length
                          }%`,
                          minWidth: "200px",
                          maxWidth: "250px",
                        }}
                      />
                    ))}
                  </colgroup>
                  <thead>
                    <tr>
                      {Object.keys(invalidCsvData[0]).map((key, index) => (
                        <th className="text-nowrap" key={index}>
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invalidCsvData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((value, colIndex) => (
                          <td key={colIndex}>
                            <p
                              className="m-0"
                              style={{ textOverflow: "ellipsis" }}>
                              {value.value}
                            </p>
                            {value.error && (
                              <p className="text-danger m-0">{value.error}</p>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default SupportMemberCSVUpload;
