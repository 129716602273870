import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { storage } from "../../../../firebase/config";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { getRandomString } from "../../../../utils/Utils";

function EditEventCheck() {
  const params = useParams();
  const backData = JSON.parse(localStorage.getItem("editEventData")) || false;
  // const { addADoc, isPending, error } = useAddDocs();
  const { firebaseSetDoc, isPending, error } = useSetDoc();

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const helmetData = new HelmetData({});

  useEffect(() => {
    if (!backData) {
      navigate(`/mypage/edit-event/${params.id}`);
    }
  }, []);

  async function uploadToStorage(url, type = "") {
    const imgRef = ref(storage, `image/events/${getRandomString(23)}.jpeg`);
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  const registerMember = async () => {
    if (backData.mainPhoto && backData.mainPhoto.substring(0, 4) === "data") {
      backData.mainPhoto = await uploadToStorage(backData.mainPhoto);
    }

    if (backData.subPhoto1 && backData.subPhoto1.substring(0, 4) === "data") {
      backData.subPhoto1 = await uploadToStorage(backData.subPhoto1);
    }

    if (backData.subPhoto2 && backData.subPhoto2.substring(0, 4) === "data") {
      backData.subPhoto2 = await uploadToStorage(backData.subPhoto2);
    }

    if (backData.subPhoto3 && backData.subPhoto3.substring(0, 4) === "data") {
      backData.subPhoto3 = await uploadToStorage(backData.subPhoto3);
    }
    await firebaseSetDoc("events", backData, params.id, true).then((res) => {
      navigate("/mypage/edit-event/completed");
    });
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={2}
        />
      </div>
      <br />
      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}>
                イメージ写真 <span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 py-2 border-end border-secondary`}>
                {backData.mainPhoto && (
                  <img
                    src={backData.mainPhoto}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                イメージ写真 1
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.subPhoto1 && (
                  <img
                    src={backData.subPhoto1}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                イメージ写真 2
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.subPhoto2 && (
                  <img
                    src={backData.subPhoto2}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                イメージ写真 3
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.subPhoto3 && (
                  <img
                    src={backData.subPhoto3}
                    className="shadow rounded"
                    alt=""
                    style={{ width: "500px", maxWidth: "95%" }}
                  />
                )}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                タイトル <span className="text-danger">※</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.title}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                詳細
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {backData.description && (
                  // <div
                  //   dangerouslySetInnerHTML={{
                  //     __html: backData.description.replace(
                  //       /(?:\r\n|\r|\n)/g,
                  //       "<br>"
                  //     ),
                  //   }}></div>

                  <div className={`ql-snow ql-container border-0`}>
                    <div
                      className={` ql-editor px-0`}
                      dangerouslySetInnerHTML={{ __html: backData.description }}></div>
                  </div>  
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="text-center mt-4">
          <Link
            to={`/mypage/edit-event/${params.id}`}
            className="m-2 btn  btn-secondary">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending && (
            <Button className="m-2" variant="primary" onClick={registerMember}>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {isPending && (
            <Button className="m-2" variant="primary" disabled>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}

export default EditEventCheck;
