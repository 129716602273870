import React, { useEffect, useState } from "react";
import Styles from "./Answer.module.css";
import { Link } from "react-router-dom";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const Answer3 = () => {
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState("#");

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      const storage = getStorage();
      const fileRef = ref(storage, "pdf/qualifying_examination_list_2012.pdf");

      try {
        const url = await getDownloadURL(fileRef);
        setPdfDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);

  return (
    <div className={Styles.answer}>
      <p className="mb-0">
        1級を直接受検する場合は、7年以上の実務経験が必要です。<br></br>
        3級合格後1級を受検される場合は、4年以上の実務経験が必要です。
        2級合格後1級を受検される場合は、2年以上の実務経験が必要です。
        ただし、関連の学科を専攻した方は、実務経験年数の軽減があります。詳しくは下記をご覧ください。
      </p>

      <Link to={pdfDownloadUrl} target="_blank">
        Microsoft Word - 受検資格一覧表_H25改正.doc (javma.com)
      </Link>
      <p>※実務経験の内容については、Q1社会人の場合をご参照ください。</p>
    </div>
  );
};

export default Answer3;
