import { useState, useEffect } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { useToast } from "./useToast";
import { useLogout } from "./useLogout";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { logout } = useLogout();
  const toast = useToast();
  const login = (email, password) => {
    setError(null);
    setIsPending(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        // if (res.user.emailVerified) {
          dispatch({ type: "LOGIN", payload: res.user });
          toast("success", "ログインしました");
        // } else {
        //   logout(false);
        //   setError("メールをご確認頂きアカウントを有効にしてください");
        // }
        setIsPending(false);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };

  return { error, isPending, login };
};
