import React from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Navbar } from "react-bootstrap";

export default function MyPageHeader({
  sideBarShow,
  sideBarShowHandler,
  pageTitle = "mai",
}) {
  return (
    <>
      <Navbar fixed="top" expand="lg" bg="secondary" variant="dark">
        <Container fluid>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={sideBarShowHandler}
          >
            {!sideBarShow ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </Navbar.Toggle>
          <Navbar.Brand href="#home">
            <span dangerouslySetInnerHTML={{ __html: pageTitle }}></span>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}
