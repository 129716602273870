import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import Members from "./pages/members/Members.js";
import Member from "./pages/members/Member.js";

import ToastDiv from "./components/toast/ToastDiv";
import { useAuthContext } from "./hooks/useAuthContext";

import Login from "./pages/login/Login";
import MyPage from "./pages/MyPage";
import { UserDetailsProvider } from "./context/UserDetailsContext";
import SupportingMembers from "./pages/supportingMembers/SupportingMembers.js";
import SupportingMember from "./pages/supportingMembers/SupportingMember.js";

import Events from "./pages/Events/Events.js";
import EventDeatil from "./pages/Events/EventDeatil.js";

import React, { useEffect } from "react";
import { LicensureExam } from "./pages/LicensureExam/LicensureExam";
import LicensureExamHistory from "./pages/LicensureExam/LicensureExamHistory";
import LicensureExamFaq from "./pages/LicensureExam/LicensureExamFaq.js";
import Publication from "./pages/Publication/Publication";
import Membership from "./pages/Membership/Membership";
import MembershipRegistration from "./pages/Membership/MembershipRegistration";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Contact from "./pages/Contact/Contact";
import MessageSent from "./pages/Contact/MessageSent";

import NotFound from "./pages/notFoundPage/notFound.js";
import Holder from "holderjs";
import AboutJavma from "./pages/About/AboutJavma.js";
import WhatIsVmd from "./pages/WhatIsVmd/WhatIsVmd.js";

function App() {
  const { user, authIsReady } = useAuthContext();
  useEffect(() => {
    Holder.run();
  }, []);
  return (
    <div className="content">
      {authIsReady && (
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/members" element={<Members />} />
            <Route path="/members/:memberID" element={<Member />} />
            <Route path="/supporting-members" element={<SupportingMembers />} />
            <Route path="/supporting-members/:memberID" element={<SupportingMember />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<EventDeatil />} />
            <Route path="/about-javma/" element={<AboutJavma />} />
            <Route path="/licensure-exam/" element={<LicensureExam />} />
            <Route path="/licensure-exam-history/:id" element={<LicensureExamHistory />} />
            <Route path="/licensure-exam-faq/" element={<LicensureExamFaq />} />
            <Route path="/publication/" element={<Publication />} />
            <Route path="/membership/" element={<Membership />} />
            <Route path="/membership-resgistration/" element={<MembershipRegistration />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/message-sent" element={<MessageSent />} />
            <Route path="/about-vmd" element={<WhatIsVmd />} />
            <Route
              path="login"
              element={
                !user ? (
                  <div className="admin">
                    {/* <UserDetailsProvider>
                      <Header withlinks={false} withMobileLocation={false} />
                    </UserDetailsProvider> */}
                    <Login />
                  </div>
                ) : (
                  <Navigate to="/mypage" />
                )
              }
            />

            <Route
              path="mypage/*"
              element={
                user ? (
                  <>
                    <UserDetailsProvider>
                      <MyPage />
                    </UserDetailsProvider>
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="*"
              element={
                <div className="topmargin">
                  <NotFound />
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
      <ToastDiv />
    </div>
  );
}

export default App;

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};
