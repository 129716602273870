import React, { useEffect, useRef } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { useToast } from "../../../hooks/useToast";
import { useDeleteDocs } from "../../../hooks/useDeleteDocs";

function AccountDeleteModal({ data, show, handleClose, updateByDeleteUser }) {
  const toast = useToast();

  const { deleteDocument, isPending } = useDeleteDocs();
  const initialValues = {
    lebel: ""
  };

  const formikRef = useRef(null);

  useEffect(() => {
    if (show && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [show, formikRef]);

  const schema = yup.object().shape({
    lebel: yup
      .string()
      .required("この欄は必須です")
      .test("is-match", "テキストが一致しません", (value) => {
        if (value) {
          return data.memberId == value;
        }
        return true;
      })
  });
  const handleSubmit = async (values, { resetForm }) => {
    await deleteDocument("supportmembers", data.id);
    updateByDeleteUser(data.id);
    toast("success", "完了しました");
    resetForm();
    handleClose();
  };
  return (
    <>
      {data && (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleSubmit}
          innerRef={(f) => (formikRef.current = f)}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {data.group === "OWNER" && "オーナー"}
                  {data.group === "AUTHOR" && "投稿者"}アカウントの退会
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {data.group === "OWNER" && (
                  <p>
                    <b>{data.memberId}</b>を退会してもよろしでしょうか。
                    <br />
                    退会すると以下の情報が完全に削除されます。
                    <br />
                    ・作成した投稿者アカウント情報及び全投稿
                    <br />
                    ・作成したグループページ
                    <br />
                    ・アカウント情報
                    <br />
                    この操作は取り消しできませんがよろしでしょうか。
                  </p>
                )}
                {data.group === "AUTHOR" && (
                  <p>
                    <b>{data.memberId}</b>を退会してもよろしでしょうか。
                    <br />
                    退会すると以下の情報が完全に削除されます。
                    <br />
                    ・作成した全投稿
                    <br />
                    ・アカウント情報
                    <br />
                    この操作は取り消しできませんがよろしでしょうか。
                  </p>
                )}

                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      よろしければ確認のために、次の文字列を入力してください： <b>{data.memberId}</b>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lebel}
                      name="lebel"
                      isInvalid={touched.lebel && errors.lebel}
                      isValid={touched.lebel && !errors.lebel}
                      required
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">{errors.lebel}</Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  閉じる
                </Button>

                {!isPending && (
                  <Button variant="danger" onClick={handleSubmit}>
                    削除する
                  </Button>
                )}

                {isPending && (
                  <Button variant="danger" disabled type="button">
                    削除する <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      )}
    </>
  );
}

export default AccountDeleteModal;
