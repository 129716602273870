import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./Sidebar.module.css";
import { useCollection } from "../../../hooks/useCollection";

const Sidebar = ({ examHistoryDataShow = false, showMobile = false }) => {
  const location = useLocation(); // Get the current route
  const { documents: examHistoryData, getMultipleDocs } = useCollection();

  const loadData = () => {
    getMultipleDocs({
      _collection: "examHistory",
      _query: [],
      orderByField: "position",
      orderByOption: "desc"
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <div
      className={`${Styles.sidebar} ${examHistoryDataShow ? Styles.showMobileSidebar : ""} ${
        showMobile ? Styles.showMobileSidebar : ""
      }`}
    >
      <div className={Styles.linkTitle}>商品装飾展示技能検定</div>

      {examHistoryDataShow ? (
        <>
          {examHistoryData && Object.values(examHistoryData).length > 0 && (
            <>
              {Object.values(examHistoryData).map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={`/licensure-exam-history/${item.id}`}
                    className={`${Styles.sidebarLink} ${
                      location.pathname == `/licensure-exam-history/${item.id}` ? Styles.active : ""
                    }`}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {examHistoryData && Object.values(examHistoryData).length > 0 && (
            <Link
              to={`/licensure-exam-history/${examHistoryData[0].id}`}
              className={`${Styles.sidebarLink} ${
                location.pathname.includes("/licensure-exam-history/") ? Styles.active : ""
              }`}
            >
              技能検定の詳細と履歴
            </Link>
          )}
          <Link
            to={"https://www.javada.or.jp/kyoukai/itiran.html"}
            target="_blank"
            className={Styles.sidebarLink}
          >
            職業能力開発協会の所在地
          </Link>
          <Link
            to={"/publication"}
            className={`${Styles.sidebarLink} ${location.pathname === "/publication" ? Styles.active : ""}`}
          >
            出版物のご案内
          </Link>
          <Link
            to={"/licensure-exam-faq"}
            className={`${Styles.sidebarLink} ${
              location.pathname === "/licensure-exam-faq" ? Styles.active : ""
            }`}
          >
            検定についてよくあるお問合せ
          </Link>
          {/* <Link to={"/#contactus"} className={Styles.sidebarLink}>
            検定についてのお問い合わせ
          </Link> */}
        </>
      )}
    </div>
  );
};

export default Sidebar;
