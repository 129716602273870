import React, { useEffect, useRef, useState } from "react";

import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Style from "./notFound.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";

const NotFound = () => {
  const navigate = useNavigate();

  const footerRef = useRef(null);
  const contactusRef = useRef(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const { width } = useWindowDimensions();


  useEffect(() => {
    const handleResize = () => {
      if (footerRef.current && contactusRef.current) {
        const contactusTopPosition =
          contactusRef.current.getBoundingClientRect().top;
        const footerHeight = footerRef.current.getBoundingClientRect().height;
        const headerHeight = contactusTopPosition;
        const remainingHeight = viewportHeight - headerHeight - footerHeight;
        contactusRef.current.style.minHeight = `${remainingHeight}px`;
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [footerRef, contactusRef, viewportHeight]);

  // State to control whether the content should be shown
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Set a timer to change showContent to true after 3 seconds
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    // Clean up the timer if the component unmounts before the timer fires
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showContent && (
        <>
          <Header />
          <div ref={contactusRef} className={Style.Contactus}>
            <Container className={Style.bgWhite}>
              <div className={Style.content}>
                <h1>404 Not Found</h1>
                <p>
                  お探しのページ一時的にアクセスできない状態にあるか、<br></br>
                  もしくは、移動、削除され見つけることができません。
                </p>
                <Link role="button" to="/">
                  トップページへ戻る
                </Link>
              </div>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default NotFound;
