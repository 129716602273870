import { useState } from "react";
import { db } from "../firebase/config";

//firebase imports
import { collection, orderBy, query, limit, startAfter, where, getDocs } from "firebase/firestore";

export const useCollection = () => {
  const [documents, setDocuments] = useState(null);
  //   const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [isLast, setIsLast] = useState(false);

  const getMultipleDocs = async ({
    _collection,
    _query,
    orderByField,
    orderByOption = "desc",
    _limit,
    _startAfter
  }) => {
    setDocuments(null);
    setIsLast(false);
    setIsPending(true);
    let ref = collection(db, _collection);
    let whereIN = {};

    //set the query
    if (Object.values(_query)) {
      _query.forEach((queryitem) => {
        if (queryitem[2] === "true" || queryitem[2] === "false") {
          queryitem[2] = queryitem[2] === "true";
        }

        if (queryitem[1] === "in") {
          let chunkArrayItem = chunkArray(queryitem.slice(2), 2);
          let field = queryitem[0];
          let condition = queryitem[1];
          let queryInArray = [];
          chunkArrayItem.forEach((item, i) => {
            queryInArray.push([condition, item]);
          });
          whereIN[field] = queryInArray;
        } else {
          ref = query(ref, where(...queryitem));
        }
      });
    }
    if (orderByField) {
      ref = query(ref, orderBy(orderByField, orderByOption));
    }
    if (_limit) {
      ref = query(ref, limit(_limit));
    }

    if (_startAfter) {
      ref = query(ref, startAfter(_startAfter));
    }

    let results = [];
    let docSnap;
    if (Object.keys(whereIN).length > 0) {
      let whereInAllArray = [];
      for (const key in whereIN) {
        const uniqueValues = [];
        if (whereIN.hasOwnProperty(key)) {
          let values = whereIN[key];
          if (Array.isArray(values)) {
            await Promise.all(
              values.map(async (item) => {
                let tempRef = query(ref, where(key, item[0], [...item[1][0]]));

                docSnap = await getDocs(tempRef);
                docSnap.forEach((doc) => {
                  const newDoc = { ...doc.data(), id: doc.id };
                  const isDuplicate = uniqueValues.some((existingDoc) => existingDoc.id === newDoc.id);

                  if (!isDuplicate) {
                    uniqueValues.push(newDoc);
                  }
                });
              })
            );
          }
        }

        whereInAllArray.push(uniqueValues);
      }
      results = intersection(whereInAllArray);
    } else {
      docSnap = await getDocs(ref);
      docSnap.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
    }

    setIsPending(false);
    setDocuments(results);
    setLastDoc(docSnap.docs[docSnap.docs.length - 1]);

    if (docSnap.empty || (_limit && docSnap.docs.length < _limit)) {
      setIsLast(true);
    }

    return () => docSnap();
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  function intersection(arrays) {
    if (arrays.length == 0) {
      return [];
    }

    if (arrays.length == 1) {
      return arrays[0];
    }

    let values = arrays[0];
    for (let i = 1; i < arrays.length; i++) {
      values = values.filter((o) => arrays[i].some(({ id }) => o.id === id));
    }

    return values;
  }
  return { documents, isPending, lastDoc, isLast, getMultipleDocs };
};
