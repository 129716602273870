import React from "react";

import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./SideBar.module.css";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

export default function SideBar({ sideBarShow, sideBarShowHandler, activeMenu, menus, certificateRequests = null }) {
  const { userData } = useUserDetailsContext();
  return (
    <>
      <div
        className={`${sideBarShow ? styles.bgWrapperActive : styles.bgWrapper} d-lg-none`}
        onClick={sideBarShowHandler}></div>
      <div
        className={`${sideBarShow ? styles.sidebarWrapperActive : ""} bg-light shadow-sm`}
        id={styles.sidebarWrapper}>
        
        {menus.map((menu, i) => {
          return (
            <div key={i}>
              <div className={`${styles.listGroup} list-group  list-group-flush`} onClick={sideBarShowHandler}>
                {menu.menuHeader ? (
                  <>
                    <span className={`${styles.link}   list-group-item list-group-item-action bg-light`}>
                      &nbsp;&nbsp;
                      <span
                        className={`${menu.bold ? "fw-bold " : ""}`}
                        dangerouslySetInnerHTML={{ __html: menu.text }}></span>
                    </span>
                  </>
                ) : (
                  <Link
                    to={menu.link}
                    id={menu.link}
                    onClick={menu.onclick ? menu.onclick : null}
                    className={`${styles.link} ${
                      menu.link === activeMenu ? styles.sidemenuactive : ""
                    }  list-group-item list-group-item-action bg-light ${menu.bold ? "fw-bold " : ""} ${menu.red ? "text-danger" : ""}`}>
                    {menu.icon && <FontAwesomeIcon icon={menu.icon} />}
                    &nbsp;&nbsp;
                    <span dangerouslySetInnerHTML={{ __html: menu.text }}></span>{" "}
                  </Link>
                )}
              </div>
              <hr className="m-0" />
            </div>
          );
        })}
      </div>
    </>
  );
}
