import React, { useEffect } from "react";
import Header from "../../components/Universal/Header/Header";
import { useParams } from "react-router-dom";
import Styles from "./SupportingMember.module.css";
import { Button, Col, Container, Ratio, Row } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Footer from "../../components/Universal/Footer/Footer";
import { useGetDoc } from "../../hooks/useGetDoc";

const SupportingMember = () => {
  const { width } = useWindowDimensions();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  const { memberID } = useParams();

  useEffect(() => {
    if (memberID) {
      getSingleDocWithQuery("supportmembers", [["memberId", "==", parseInt(memberID)]]);
    }
  }, [memberID]);

  return (
    <>
      {data && data[0] && (
        <>
          <Header />
          <div className={Styles.member}>
            <h1 className={Styles.title}>SUPPORTING MEMBERʼS DETAILS</h1>
            <p className={Styles.subtitle}>賛助会員の詳細</p>

            <Container>
              <Row className="d-flex justify-content-center px-0 mt-0 pt-0 mt-sm-4 pt-sm-3">
                <Col xs={11} sm={9} className="px-1 px-sm-0 mt-1 mt-sm-5">
                  <Row className="mt-5 justify-content-sm-center">
                    <Col xs={9} sm={4} className="px-0">
                      <Ratio aspectRatio="1x1" className="mt-4">
                        <div className={Styles.imgContainer}>
                          {data[0].profilePhoto ? (
                            <img src={data[0].profilePhoto} className="w-100" />
                          ) : (
                            <img src={"/img/no_user_pic.png"} className="w-100" />
                          )}
                        </div>
                      </Ratio>
                    </Col>
                    <Col xs={12} sm={8}>
                      <h2 className={Styles.greetings}>{data[0].company_name}</h2>
                      <p className={Styles.description}>{data[0].self_introduction}</p>
                    </Col>

                    <Col sm={9} className={Styles.details}>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>企業名：</p>
                        <p className={Styles.value}>{data[0].company_name}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>連絡先電話番号：</p>
                        <p className={Styles.value}>{data[0].phone}</p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>住所:</p>
                        <p className={Styles.value}>
                          {data[0].postal_code && `〒${data[0].postal_code}`}
                          {data[0].prefecture}
                          {data[0].work_address}
                        </p>
                      </div>
                      <div className={Styles.item}>
                        <p className={Styles.lable}>URL:</p>
                        <p className={Styles.value}>
                          {data[0].url && (
                            <a target="_blank" href={data[0].url}>
                              {data[0].url}
                            </a>
                          )}
                        </p>
                      </div>
                    </Col>

                    {/* <Col sm={9} className="text-center">
                      <Button variant="light" className={Styles.button}>
                        {width > 576 ? "仕事依頼" : "仕事を依頼する"}
                      </Button>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default SupportingMember;
