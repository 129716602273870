import React, { useRef, useState } from "react";

import * as yup from "yup";
import CSVReader from "react-csv-reader";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { useGetDoc } from "../../../../hooks/useGetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import {
  email,
  kanaOnlySchema,
  numberOnlySchema,
  openStringSchema,
  phoneSchemaBoth,
} from "../../../../utils/InputSchema";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAggregation } from "../../../../hooks/useAggregation";

const CSVUpload = () => {
  const [invalidCsvData, setInvalidCsvData] = useState(null); // State to store CSV data
  const [validCsvData, setValidCsvData] = useState(null); // State to store CSV data
  const [isValidationPending, setIsValidationPending] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const { addADoc, isPending, error } = useAddDocs();
  const [isUploading, setIsUploading] = useState(false);
  const csvReaderRef = useRef(null);

  const { getSingleDocWithQuery } = useGetDoc();
  const { firebaseSetDoc } = useSetDoc();
  const { countWithQuery } = useAggregation();

  const keys = {
    position: "位置",
    memberId: "会員番号", //会員番号
    name: "氏名", //名前
    furigana: "ヨミ", //ふりがな
    place_of_employment: "勤務先", //勤務先
    business_type: "事業形態", //事業形態
    department: "部署", //部署
    title: "肩書", //肩書
    postal_code: "郵便番号", //郵便番号
    prefecture: "都道府県", //都道府県
    work_address: "勤務先住所", //勤務先住所
    email: "メルアド", //メールアドレス
    phone: "電話", // 電話
    mobile_phone: "携帯", // 携帯電話
    area_of_expertise: "専門分野", //専門分野
    occupation: "職業名 or 職種", //職業名 or 職種
    url: "URL", //URL
    team: "所属チーム", //所属チーム
    area: "所属エリア", //所属エリア
    self_introduction: "詳細プロフィールに掲載する自己紹介文（130字程度）", //自己紹介
  };

  const handleFileSelect = (data, fileInfo) => {
    setIsUploading(false);
    setUploadedData([]);

    const modifiedData = data.map((item) => {
      return {
        position: item[keys.position] ? item[keys.position] : 999999999,
        memberId: item[keys.memberId] ? item[keys.memberId] : "",
        name: item[keys.name] ? item[keys.name].trim() : "",
        furigana: item[keys.furigana] ? item[keys.furigana].trim() : "",
        place_of_employment: item[keys.place_of_employment]
          ? item[keys.place_of_employment].trim()
          : "",
        business_type: item[keys.business_type]
          ? item[keys.business_type].trim()
          : "",
        department: item[keys.department] ? item[keys.department].trim() : "",
        title: item[keys.title] ? item[keys.title].trim() : "",
        postal_code: item[keys.postal_code]
          ? item[keys.postal_code].trim()
          : "",
        prefecture: item[keys.prefecture] ? item[keys.prefecture].trim() : "",
        work_address: item[keys.work_address]
          ? item[keys.work_address].trim()
          : "",
        email: item[keys.email] ? item[keys.email].trim() : "",
        phone: item[keys.phone]
          ? item[keys.phone].trim().replace(/[−–—―‑‒－]/g, "-")
          : "",
        mobile_phone: item[keys.mobile_phone]
          ? item[keys.mobile_phone].trim().replace(/[−–—―‑‒－]/g, "-")
          : "",
        area_of_expertise: item[keys.area_of_expertise]
          ? item[keys.area_of_expertise].trim()
          : "",
        occupation: item[keys.occupation] ? item[keys.occupation].trim() : "",
        url: item[keys.url] ? item[keys.url].trim() : "",
        team: item[keys.team] ? item[keys.team].trim() : "",
        area: item[keys.area] ? item[keys.area].trim() : "",
        self_introduction: item[keys.self_introduction]
          ? item[keys.self_introduction].trim()
          : "",
      };
    });

    validateData(modifiedData);
  };

  const validateData = async (data) => {
    const validatedData = [];
    const invalidData = [];
    setIsValidationPending(true);

    const memberCreateSchema = yup.object().shape({
      position: numberOnlySchema(),
      //会員番号
      memberId: numberOnlySchema().required("この欄は必須です"),
      //名前
      name: openStringSchema(32).required("この欄は必須です"),
      //ふりがな
      furigana: kanaOnlySchema(32).required("この欄は必須です"),
      //勤務先
      place_of_employment: openStringSchema(1000),
      //事業形態
      business_type: openStringSchema(1000),
      //部署
      department: openStringSchema(1000),
      //肩書
      title: openStringSchema(1000),
      //郵便番号
      postal_code: openStringSchema(1000),
      //都道府県
      prefecture: openStringSchema(1000),
      //勤務先住所
      work_address: openStringSchema(1000),
      //メールアドレス
      email: email().test(
        "is-unique",
        "ご登録メールアドレスがすでに登録済み、もしくは使用できない情報です。",
        async (value, context) => {
          if (value) {
            let user = await getSingleDocWithQuery("members", [
              ["memberId", "!=", context.parent.memberId],
              ["email", "==", value],
            ]);
            return !user;
          }
          return true;
        }
      ),
      // 電話
      phone: phoneSchemaBoth(),
      // 携帯電話
      mobile_phone: phoneSchemaBoth(),
      //専門分野
      area_of_expertise: openStringSchema(1000),
      //職業名 or 職種
      occupation: openStringSchema(1000),
      //URL
      url: yup.string().nullable().url("有効なURLを入力してください"),
      //所属チーム
      team: openStringSchema(1000),
      //所属エリア
      area: openStringSchema(1000),
      //自己紹介
      self_introduction: openStringSchema(1000),
    });

    for (const item of data) {
      try {
        await memberCreateSchema.validate(item, { abortEarly: false });
        validatedData.push({ ...item });
      } catch (validationError) {
        let invalidItem = {};
        Object.entries(keys).forEach(([key, value]) => {
          invalidItem[value] = { value: item[key] };
        });
        validationError.inner.forEach((error) => {
          // console.log(error.path);
          invalidItem[keys[error.path]].error = error.message;
        });

        invalidItem = {
          ...invalidItem,
        };
        invalidData.push(invalidItem);
      }
    }
    setInvalidCsvData(invalidData);
    setValidCsvData(validatedData);
    setIsValidationPending(false);
  };

  const handleGroupAdd = async () => {
    if (!validCsvData) {
      return;
    }
    setIsUploading(true);
    let items = [];
    await Promise.all(
      validCsvData.map(async (data) => {
        const count = await countWithQuery("members");
        // Check if a document with the memberId already exists
        const existingMember = await getSingleDocWithQuery(
          "members",
          [["memberId", "==", data.memberId]],
          null,
          "desc",
          1 // Only need to find one matching document
        );

        if (existingMember && existingMember.length > 0) {
          // If member exists, retain existing data for 'view' and 'status'
          const memberId = existingMember[0].id; // Get the document ID
          const existingData = existingMember[0]; // Existing document data

          await firebaseSetDoc(
            "members",
            {
              ...data,
              view: existingData.view ?? 0, // Retain existing 'view', default to 0 if not present
              status: existingData.status ?? "PUBLISHED", // Retain existing 'status', default to "PUBLISHED"
              private: existingData.private ?? false, // Retain existing 'private', default to false
              // position: existingData.position ?? count + 1,
            },
            memberId, // Update the existing document by ID
            true // Use merge option to update the existing fields
          ).then(() => {
            items.push({
              [keys.position]: data.position,
              [keys.memberId]: data.memberId,
              [keys.name]: data.name,
              [keys.furigana]: data.furigana,
              [keys.place_of_employment]: data.place_of_employment,
              [keys.business_type]: data.business_type,
              [keys.department]: data.department,
              [keys.title]: data.title,
              [keys.postal_code]: data.postal_code,
              [keys.prefecture]: data.prefecture,
              [keys.work_address]: data.work_address,
              [keys.email]: data.email,
              [keys.phone]: data.phone,
              [keys.mobile_phone]: data.mobile_phone,
              [keys.area_of_expertise]: data.area_of_expertise,
              [keys.occupation]: data.occupation,
              [keys.url]: data.url,
              [keys.team]: data.team,
              [keys.area]: data.area,
              [keys.self_introduction]: data.self_introduction,
              " ": `<a class="btn btn-primary" target="_blank" href="/mypage/user-details/${data.memberId}">詳細</a>`,
            });
            setUploadedData(items);
          });
        } else {
          // If member does not exist, add a new document
          await addADoc("members", {
            ...data,
            view: 0, // Set default view for new documents
            status: "PUBLISHED", // Set default status for new documents
            private: false,
            // position: count + 1,
          }).then((res) => {
            if (res) {
              items.push({
                [keys.position]: data.position,
                [keys.memberId]: data.memberId,
                [keys.name]: data.name,
                [keys.furigana]: data.furigana,
                [keys.place_of_employment]: data.place_of_employment,
                [keys.business_type]: data.business_type,
                [keys.department]: data.department,
                [keys.title]: data.title,
                [keys.postal_code]: data.postal_code,
                [keys.prefecture]: data.prefecture,
                [keys.work_address]: data.work_address,
                [keys.email]: data.email,
                [keys.phone]: data.phone,
                [keys.mobile_phone]: data.mobile_phone,
                [keys.area_of_expertise]: data.area_of_expertise,
                [keys.occupation]: data.occupation,
                [keys.url]: data.url,
                [keys.team]: data.team,
                [keys.area]: data.area,
                [keys.self_introduction]: data.self_introduction,
                " ": `<a class="btn btn-primary" target="_blank" href="/mypage/user-details/${data.memberId}">詳細</a>`,
              });
              setUploadedData(items);
            }
          });
        }
      })
    );

    setUploadedData(items);
    setIsUploading(false);
    setInvalidCsvData(null);
    setValidCsvData(null);

    if (csvReaderRef.current) {
      csvReaderRef.current.value = "";
    }
  };

  return (
    <Container>
      <h4>CSVデータ登録</h4>
      <Row>
        <Col xs={12} sm={4}>
          <CSVReader
            onFileLoaded={handleFileSelect} // Handle CSV file selection
            cssClass="input-group custom-file-button"
            ref={csvReaderRef}
            cssLabelClass="input-group-text"
            cssInputClass="form-control"
            parserOptions={{
              header: true,
              dynamicTyping: true,
              skipEmptyLines: true,
              transformHeader: (header) => header.trim(), // Trim header names
            }}
          />
        </Col>
        {validCsvData && !isValidationPending && (
          <>
            <Col xs={12} sm={8}>
              {validCsvData.length > 0 && (
                <Button
                  variant="success"
                  className="mt-2 mt-sm-0"
                  onClick={handleGroupAdd}
                  disabled={isUploading}>
                  {!isUploading && (
                    <>{validCsvData.length} つのcsvデータを登録する</>
                  )}
                  {isUploading && (
                    <div className="d-flex justify-content-center align-items-center">
                      {uploadedData.length} 件のデータをアップロード
                      <Spinner
                        animation="border"
                        role="status"
                        className="ms-2">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </Button>
              )}
              {validCsvData.length == 0 && (
                <Button variant="light">
                  アップロードするデータがありません
                </Button>
              )}
            </Col>
          </>
        )}
      </Row>

      {uploadedData && uploadedData.length > 0 && (
        <Row className="mt-5">
          <Col sm={12}>
            <h5>登録が完了した情報</h5>
            <Table>
              <thead>
                <tr>
                  {Object.keys(uploadedData[0]).map((key, index) => (
                    <th className="text-nowrap" key={index}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {uploadedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                      <td key={colIndex}>
                        <p
                          className="m-0"
                          style={{ textOverflow: "ellipsis" }}
                          dangerouslySetInnerHTML={{ __html: value }}></p>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {isValidationPending && (
        <>
          <Row className="d-flex justify-content-center mt-5 py-5">
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
            <Spinner animation="grow" className="mx-2" />
          </Row>
        </>
      )}
      {!isValidationPending && (
        <>
          {invalidCsvData && invalidCsvData.length > 0 && (
            <Row className="mt-5">
              <Col xs={12} style={{ overflow: "auto" }}>
                <h5 className="text-danger">CSVの内容が無効です。</h5>
                <Table>
                  <colgroup>
                    {Object.keys(invalidCsvData[0]).map((_, index) => (
                      <col
                        key={index}
                        style={{
                          width: `${
                            100 / Object.keys(invalidCsvData[0]).length
                          }%`,
                          minWidth: "200px",
                          maxWidth: "250px",
                        }}
                      />
                    ))}
                  </colgroup>
                  <thead>
                    <tr>
                      {Object.keys(invalidCsvData[0]).map((key, index) => (
                        <th className="text-nowrap" key={index}>
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invalidCsvData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((value, colIndex) => (
                          <td key={colIndex}>
                            <p
                              className="m-0"
                              style={{ textOverflow: "ellipsis" }}>
                              {value.value}
                            </p>
                            {value.error && (
                              <p className="text-danger m-0">{value.error}</p>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default CSVUpload;
