import React, { useEffect, useState } from "react";
import Header from "../../components/Universal/Header/Header";
import Styles from "./SupportingMembers.module.css";
import { Col, Container, Ratio, Row } from "react-bootstrap";
import SupportingMemberList from "./SupportingMemberList";
import Footer from "../../components/Universal/Footer/Footer";

import MetaData from "../../utils/MetaData";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import algoliasearch from "algoliasearch";
import { useCollectionSimple } from "../../hooks/useCollectionSimple";

const SupportingMembers = () => {
  const { width } = useWindowDimensions();
  const [searchValue, setSearchValue] = useState("");
  const client = algoliasearch("VEEOBJ6O7P", "5ac9ceff168fd785ef0b0a76d9650fd2");
  const index = client.initIndex("search_supporting_members");
  const [pending, setPending] = useState(false);

  const limit = width > 576 ? 9 : 10;
  const [members, setMembers] = useState({});

  const { documents, isPending, lastDoc, isLast, getMultipleDocs } = useCollectionSimple();

  const loadMoreMembers = async (startAfter = true) => {
    await getMultipleDocs({
      _collection: "supportmembers",
      orderByField: "position",
      orderByOption: "asc",
      _limit: limit,
      _startAfter: startAfter ? (lastDoc ? lastDoc : false) : false,
      _query: [["private", "==", false]]
    });
  };

  useEffect(() => {
    loadMoreMembers(false);
  }, []);

  useEffect(() => {
    if (documents) {
      console.log(documents);
      setMembers((prevMembers) => {
        const newMembers = { ...prevMembers }; // Copy previous members
        documents.forEach((doc) => {
          newMembers[doc.id] = doc; // Add or update member by id
        });
        return newMembers;
      });
    }
  }, [documents]);

  useEffect(() => {
    setPending(isPending);
  }, [isPending]);

  useEffect(() => {
    if (searchValue.trim() == "") {
      setMembers({});
      loadMoreMembers(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (documents) {
      setMembers((prevMembers) => {
        const newMembers = { ...prevMembers }; // Copy previous members
        documents.forEach((doc) => {
          newMembers[doc.id] = doc; // Add or update member by id
        });
        return newMembers;
      });
    }
  }, [documents]);

  const placeholders = Array.from({ length: limit }, (_, i) => (
    <div key={i} className="placeholder-glow px-3 px-sm-3 my-sm-3 col-sm-4 col-xs-6 mt-4">
      <div className="align-middle p-0">
        <Ratio aspectRatio="1x1" className="placeholder">
          <img src={"/img/no_user_pic.png"} className="w-100" alt="Placeholder" />
        </Ratio>
        <p className={`placeholder ${Styles.memeberName}`}>お名前</p>
        <p className={`placeholder ${Styles.memeberTitle}`}>協会理事長</p>
      </div>
    </div>
  ));

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchValue.trim()) {
      setPending(true);
      const { hits } = await index.search(searchValue, {});
      const members = {};
      hits.forEach((doc) => {
        if (!doc.private) {
          members[doc.objectID] = doc;
        }
      });
      setMembers(members);
      setPending(false);
    } else {
      loadMoreMembers(false);
    }
  };

  return (
    <>
      <MetaData />
      <Header />
      <div className={Styles.members}>
        <Container>
          <h1 className={Styles.title}>SUPPORTING MEMBERS</h1>
          <h4 className={Styles.subtitle}>賛助会員リスト</h4>
          <Row>
            <Col xs={12} className="d-flex justify-content-end">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  className={`form-control searchinput`}
                  placeholder="search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </form>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mt-4">
            <Col sm={10} className="px-1 px-sm-0 mt-2">
              <SupportingMemberList members={members} placeholders={placeholders} isPending={pending} />
            </Col>
          </Row>

          {!isLast && !isPending && Object.values(members).length != 0 && searchValue == "" && (
            <div className="d-flex w-100 justify-content-center mt-3 mt-sm-5">
              <button className={Styles.loadMore} onClick={loadMoreMembers}>
                もっと見る
              </button>
            </div>
          )}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SupportingMembers;
