import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TITLES } from "./titles";

const MetaData = () => {
  const [title, setTitle] = useState("デフォルトのタイトル");
  const [description, setDescription] = useState("デフォルトの説明");
  const location = useLocation();

  useEffect(() => {
    const matchingTitle = TITLES.find((item) => item.url === location.pathname);

    if (matchingTitle) {
      setTitle(matchingTitle.title || "デフォルトのタイトル");
      setDescription(matchingTitle.description || "デフォルトの説明");
    } else {
      setTitle("デフォルトのタイトル");
      setDescription("デフォルトの説明");
    }
  }, [location.pathname]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaData;
