import React, { useEffect, useState } from "react";

import _ from "lodash";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { HelmetData } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import EditEventInput from "./EditEventInput";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import ProgressStep from "../../../../components/progressStep/ProgressStep";
import {
  email,
  openStringSchema,
  phoneSchemaBoth,
  numberOnlySchema,
  kanaOnlySchema,
} from "../../../../utils/InputSchema";

function EditEvent() {
  const params = useParams();
  const navigate = useNavigate();
  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();

  const helmetData = new HelmetData({});

  const [initialValues, setInitialValues] = useState(null);

  const backData = localStorage.getItem("editEventData");

  useEffect(() => {
    async function fetchData() {
      await firebaseGetDoc("events", params.id).then((res) => {
        if (res) {
          const init = {
            mainPhoto: res.mainPhoto || "",
            subPhoto1: res.subPhoto1 || "",
            subPhoto2: res.subPhoto2 || "",
            subPhoto3: res.subPhoto3 || "",
            title: res.title || "",
            description: res.description || "",
          };
          setInitialValues(init);
        }
      });
    }
    if (!backData) {
      fetchData();
    } else {
      console.log(JSON.parse(localStorage.getItem("editEventData")));
      setInitialValues(JSON.parse(localStorage.getItem("editEventData")));
    }
  }, [params.id]);

  const schema = yup.object().shape({
    mainPhoto: yup.string().required("この欄は必須です"),
    title: openStringSchema(100).required("この欄は必須です"),
    description: openStringSchema(3000),
  });

  const handleSubmit = (values) => {
    const mainPhoto =
      localStorage.getItem("croppedMainPhoto") ||
      (_.has(JSON.parse(backData), "mainPhoto")
        ? JSON.parse(backData).mainPhoto
        : null);
    if (mainPhoto && mainPhoto !== undefined) {
      values.mainPhoto = mainPhoto;
      localStorage.removeItem("croppedImg");
    }

    const subPhoto1 = localStorage.getItem("croppedSubPhoto1");
    // console.log(subPhoto1);
    if (subPhoto1 && subPhoto1 !== undefined) {
      values.subPhoto1 = subPhoto1;
      localStorage.removeItem("croppedSubPhoto1");
    } 

    const subPhoto2 = localStorage.getItem("croppedSubPhoto2");
    console.log("subPhoto2", subPhoto2);
    if (subPhoto2 && subPhoto2 !== undefined) {
      values.subPhoto2 = subPhoto2;
      localStorage.removeItem("croppedSubPhoto2");
    } 

    const subPhoto3 = localStorage.getItem("croppedSubPhoto3");
    // console.log(subPhoto3);
    if (subPhoto3 && subPhoto3 !== undefined) {
      values.subPhoto3 = subPhoto3;
      localStorage.removeItem("croppedSubPhoto3");
    } 
    try {
      const serializedState = JSON.stringify(values);
      console.log("serializedState", serializedState);
      localStorage.setItem("editEventData", serializedState);
      navigate(`/mypage/edit-event/check/${params.id}`);
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>新規ご登録</title>
      </Helmet>

      <div className="justify-content-center d-flex my-3">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            {
              value: "確認",
              link: backData ? `/mypage/edit-event/check/${params.id}` : "#",
            },
            { value: "完了", link: "#" },
          ]}
          activeNo={1}
        />
      </div>
      <br />
      {initialValues && (
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg={10} md={8}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <EditEventInput />

                    <div className="text-center mt-4 mb-3">
                      <Button className="" variant="primary" type="submit">
                        <b>
                          次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                        </b>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default EditEvent;
