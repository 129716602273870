import { createContext, useContext } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useGetDocSnap } from "../hooks/useGetDocSnap";

const UserDetailsContext = createContext();

export function UserDetailsProvider({ children }) {
  const { user } = useAuthContext();
  const { data: userData, isPending } = useGetDocSnap("users",user ? user.uid : "NOTING");
  return (
    <UserDetailsContext.Provider value={{ userData, isPending }}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export const useUserDetailsContext = () => useContext(UserDetailsContext);
