import React, { useEffect, useState } from "react";

import Holder from "holderjs";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Ratio, Row } from "react-bootstrap";

import { documentId } from "firebase/firestore";
import Placeholder from "react-bootstrap/Placeholder";

import Styles from "./Event.module.css";
import { useGetDoc } from "../../hooks/useGetDoc";
import { formatFirebaseDate } from "../../utils/Utils";
import Header from "../../components/Universal/Header/Header";
import Footer from "../../components/Universal/Footer/Footer";

const EventDeatil = () => {
  useEffect(() => {
    Holder.run();
  }, []);

  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (id) {
      getSingleDocWithQuery("events", [
        [documentId(), "==", id],
        ["status", "==", "PUBLISHED"],
      ]).then((res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          setEventDetails(res[0]);
          console.log(res[0]);
        }
      });
    }
  }, [id]);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <>
      <Header />
      <div className={Styles.eventDetails}>
        <div className={Styles.intro}>
          <Container>
            <Row className="d-flex align-items-center">
              <Col sm={6} className=" py-sm-3 ps-sm-3">
                <Ratio
                  aspectRatio={"1x1"}
                  className={`${isPending ? "d-block" : "d-none"}`}>
                  <img src="holder.js/546x546??text= &theme=gray" />
                </Ratio>
                {!isPending && (
                  <Ratio aspectRatio={"1x1"}>
                    <img
                      src={eventDetails.mainPhoto}
                      className={`${Styles.img} object-fit-cover`}
                    />
                  </Ratio>
                )}
              </Col>

              <Col sm={6} className="">
                {isPending && (
                  <>
                    <div className="placeholder-glow">
                      <p className={`placeholder ${Styles.title}`}>
                        イベントタイトル
                      </p>
                    </div>
                    <div className="placeholder-glow">
                      <p className={`placeholder ${Styles.date}`}>2024.06.25</p>
                    </div>
                    <div className="placeholder-glow">
                      <p className={`placeholder ${Styles.date}`}>
                        イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベントの説明はこちら。イベント
                      </p>
                    </div>
                  </>
                )}
                {!isPending && (
                  <>
                    {eventDetails.title && (
                      <p className={Styles.title}>{eventDetails.title}</p>
                    )}
                    {eventDetails.createdAt && (
                      <p className={Styles.date}>
                        {formatFirebaseDate(eventDetails.createdAt)}
                      </p>
                    )}
                    {eventDetails.description && (
                      <div className={Styles.description}>
                        {/* <span
                          dangerouslySetInnerHTML={{
                            __html: isExpanded
                              ? eventDetails.description.replace(/(?:\r\n|\r|\n)/g, "<br>")
                              : eventDetails.description.replace(/(?:\r\n|\r|\n)/g, "<br>").slice(0, 30) // Show the first 30 characters
                          }}
                        ></span> */}

                        <div className={`ql-snow ql-container border-0`}>
                          <div
                            className={` ql-editor px-0`}
                            dangerouslySetInnerHTML={{
                              __html: eventDetails.description,
                            }}></div>
                        </div>
                        {/* {eventDetails.description.length > 30 && (
                          <>
                            {isExpanded && <br />}
                            <span
                              className={Styles.toggleDescription}
                              onClick={toggleExpanded}>
                              {isExpanded ? "See Less" : "...See More"}
                            </span>
                          </>
                        )} */}
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="my-4">
          <Row className="d-flex justify-content-center">
            <Col xs={10} sm={7}>
              <img
                src="holder.js/700x382??text= &theme=gray"
                className={`${isPending ? "d-block" : "d-none"} w-100`}
              />

              {eventDetails.subPhoto1 && (
                <div>
                  <img
                    src={eventDetails.subPhoto1}
                    className="w-100 object-fit-cover"
                    alt="Sub Photo 1"
                  />
                </div>
              )}
              {eventDetails.subPhoto2 && (
                <div className="mt-3 mt-sm-5">
                  <img
                    src={eventDetails.subPhoto2}
                    className="w-100 object-fit-cover"
                    alt="Sub Photo 2"
                  />
                </div>
              )}
              {eventDetails.subPhoto3 && (
                <div className="mt-3 mt-sm-5">
                  <img
                    src={eventDetails.subPhoto3}
                    className="w-100 object-fit-cover"
                    alt="Sub Photo 3"
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mb-3 mb-sm-5 mt-3">
            <Col xs={12} className="text-center mt-3 mt-sm-5 mb-3">
              <Link to={"/events"} className={`${Styles.goBackLink}`}>
                イベント一覧へ
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default EventDeatil;
