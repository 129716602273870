import React, { useEffect, useState } from "react";

import Select from "react-select";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";

import { useGetDoc } from "../../../hooks/useGetDoc";
import { useUpdateDoc } from "../../../hooks/useUpdateDoc";
import { useCollectionSimple } from "../../../hooks/useCollectionSimple";
import AccountDeleteModal from "./AccountDeleteModal";
import FilterContentUser from "./FilterContentUsers";
import SupportMemberListItem from "./SupportMemberListItem";
import SupportMemberCSVDownload from "../../../components/CSVDownload/SupportMemberCSVDownload";

export const RadioFilterConstants = {
  HIGH_TO_LOW_MEMBERID: "high_to_low_memeberId",
  LOW_TO_HIGH_MEMBERID: "low_to_high_memeberId",
  NEW_TO_OLD_CREATED: "new_to_old_created",
  OLD_TO_NEW_CREATED: "old_to_new_created",
};

export const radioOptions = [
  {
    value: RadioFilterConstants["HIGH_TO_LOW_MEMBERID"],
    label: "会員番号（降順）",
  },
  {
    value: RadioFilterConstants["LOW_TO_HIGH_MEMBERID"],
    label: "会員番号（昇順）",
  },
  {
    value: RadioFilterConstants["NEW_TO_OLD_CREATED"],
    label: "新しい順",
  },
  {
    value: RadioFilterConstants["OLD_TO_NEW_CREATED"],
    label: "古い順",
  },
];

function SupportMemberList() {
  const optionStatus = [
    { value: true, label: "非公開" },
    { value: false, label: "公開" },
  ];

  const { firebaseGetDoc } = useGetDoc();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectFilterCategories, setSelectFilterCategories] = useState([]);
  const [selectFilterSort, setSelectFilterSort] = useState({
    orderByField: "createdAt",
    orderByOption: "desc",
  });
  const [selectStatus, setSelectStatus] = useState(optionStatus);
  const [lastDocSort, setLastDocSort] = useState(false);

  const [isFilterApply, setIsFilterApply] = useState(false);
  const [selectRadioFilter, setSelectRadioFilter] = useState(null);
  const [filterQueries, setFilterQueries] = useState([]);

  const [users, setUsers] = useState([]);
  const [previewShow, setPreviewShow] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const handleRadioFilterChange = (selectOption) => {
    let sortedArticlesArray = Object.values(users); // Convert object to array for sorting

    switch (selectOption.value) {
      case RadioFilterConstants["HIGH_TO_LOW_MEMBERID"]:
        sortedArticlesArray.sort((a, b) => b.memberId - a.memberId);
        setSelectFilterSort({
          orderByField: "memberId",
          orderByOption: "desc",
          selectOption: selectOption.value,
        });
        console.log(sortedArticlesArray[sortedArticlesArray.length - 1]);
        setLastDocSort(sortedArticlesArray[sortedArticlesArray.length - 1]);
        break;
      case RadioFilterConstants["LOW_TO_HIGH_MEMBERID"]:
        sortedArticlesArray.sort((a, b) => a.memberId - b.memberId);
        setSelectFilterSort({
          orderByField: "memberId",
          orderByOption: "asc",
          selectOption: selectOption.value,
        });
        console.log(sortedArticlesArray[sortedArticlesArray.length - 1]);
        setLastDocSort(sortedArticlesArray[sortedArticlesArray.length - 1]);
        break;
      case RadioFilterConstants["NEW_TO_OLD_CREATED"]:
        sortedArticlesArray.sort((a, b) => b.createdAt - a.createdAt);
        setSelectFilterSort({
          orderByField: "createdAt",
          orderByOption: "desc",
          selectOption: selectOption.value,
        });
        console.log(sortedArticlesArray[sortedArticlesArray.length - 1]);
        setLastDocSort(sortedArticlesArray[sortedArticlesArray.length - 1]);
        break;
      case RadioFilterConstants["OLD_TO_NEW_CREATED"]:
        sortedArticlesArray.sort((a, b) => a.createdAt - b.createdAt);
        setSelectFilterSort({
          orderByField: "createdAt",
          orderByOption: "asc",
          selectOption: selectOption.value,
        });
        console.log(sortedArticlesArray[sortedArticlesArray.length - 1]);
        setLastDocSort(sortedArticlesArray[sortedArticlesArray.length - 1]);
        break;
      default:
        // Optionally reset to default sort order or handle as needed
        break;
    }

    let newArticles = {};
    sortedArticlesArray.forEach((doc) => {
      newArticles = { ...newArticles, [doc.id]: doc };
    });
    setUsers(newArticles);
    setSelectRadioFilter(selectOption);
  };

  const {
    documents: fetchedFilteredUsers,
    isPending: isFilteredUsersPending,
    lastDoc,
    isLast,
    getMultipleDocs: getFilteredUsersDocs,
  } = useCollectionSimple();

  const limit = 10;

  const loadFilteredUsersData = async (flast = "LAST") => {
    let queries = [];
    if (selectStatus.length !== 0) {
      queries.push([
        "private",
        "in",
        selectStatus?.map((status) => status?.value),
      ]);
    } else {
      queries.push(["private", "in", [false, true]]);
      setSelectStatus(optionStatus);
    }

    if (filterQueries.length !== 0) {
      const filteredGroups = filterQueries.filter(
        (query) => query.type === "group"
      );
      const filteredGroupTags = filterQueries.filter(
        (query) => query.type === "group_tag"
      );

      if (filteredGroups.length !== 0) {
        queries.push([
          "schools_array",
          "array-contains-any",
          filteredGroups?.map((group) => group?.value),
        ]);
      } else if (filteredGroupTags.length !== 0) {
        queries.push([
          "tags_array",
          "array-contains-any",
          filteredGroupTags.map((tag) => tag.value),
        ]);
      }
    }

    // console.log("queries", queries);

    getFilteredUsersDocs({
      _collection: "supportmembers",
      _query: queries,
      orderByField: selectFilterSort.orderByField,
      orderByOption: selectFilterSort.orderByOption,
      // _limit: limit,
      _startAfter: flast !== "NO_LAST" ? lastDocSort : false || false,
    });
  };

  useEffect(() => {
    loadFilteredUsersData("NO_LAST");
  }, [filterQueries]);

  useEffect(() => {
    if (isFilterApply) {
      var filter = filterQueries;

      if (selectStatus.length !== 0) {
        filter = [
          ...filter,
          ...selectStatus.map((item) => ({ ...item, type: "selectStatus" })),
        ];
      }

      const uniqueArray = removeDuplicates(filter);

      // console.log("uniqueArray", uniqueArray);

      setFilterQueries(uniqueArray);
    }
  }, [isFilterApply]);

  function removeDuplicates(arr) {
    const unique = {};
    arr.forEach((obj) => {
      unique[obj.value] = obj;
    });
    return Object.values(unique);
  }

  useEffect(() => {
    if (fetchedFilteredUsers && !isFilteredUsersPending) {
      let newUsers = isFilterApply ? {} : users;
      fetchedFilteredUsers.forEach((doc) => {
        newUsers = { ...newUsers, [doc.id]: doc };
      });
      setUsers(newUsers);
      setLastDocSort(lastDoc);
      // if (selectFilterSort && selectFilterSort.selectOption) {
      //   console.log(selectFilterSort.selectOption)
      //   handleRadioFilterChange(selectFilterSort.selectOption);
      // }
      setShowFilterModal(false);
      if (isFilterApply) {
        setIsFilterApply(false);
      }
    }
  }, [fetchedFilteredUsers, isFilteredUsersPending]);

  const handleFilterSubmit = () => {
    setIsFilterApply(true);
  };

  const handleClearAllFilter = () => {
    setSelectFilterCategories([]);
    setSelectRadioFilter(null);
    setFilterQueries([]);
  };

  // useEffect(() => {
  //   if (fetchedUsers) {
  //     let newUsers = users;
  //     fetchedUsers.forEach((doc) => {
  //       newUsers = { ...newUsers, [doc.id]: doc };
  //     });
  //     setUsers(newUsers);
  //   }
  // }, [fetchedUsers]);

  // useEffect(() => {
  //   setUsers([]);
  //   loadData();
  // }, []);

  // const loadData = async () => {
  //   getMultipleDocs({
  //     _collection: "supportmembers",
  //     _query: [["group", "in", ["OWNER", "AUTHOR"]]],
  //     orderByField: "createdAt",
  //     orderByOption: "desc",
  //     _limit: limit,
  //     _startAfter: lastDoc || false
  //   });
  // };

  const getUpdatedMCDatabyID = async (id) => {
    const data = await firebaseGetDoc(`supportmembers`, id);

    setUsers((prev) => {
      let newArticles = { ...prev, [id]: { ...data, id: id } };
      return newArticles;
    });
  };

  const updateByDeleteUser = (userId) => {
    setUsers((prev) => {
      let newUsers = { ...prev };
      delete newUsers[userId];
      return newUsers;
    });
  };

  // multi select checkbox
  const actions = [
    { method: "allpublish", value: "PUBLISHED", label: "公開" },
    { method: "allunpublish", value: "UNPUBLISHED", label: "非公開" },
  ];

  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [checkboxStatus, setCheckboxStatus] = useState([]);
  const [checkboxActions, setCheckboxActions] = useState(actions);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  useEffect(() => {
    // Filter actions based on the array of checkboxStatus
    let updatedActions = actions.filter(
      (action) => !checkboxStatus.includes(action.value)
    );

    setCheckboxActions(updatedActions);
  }, [checkboxStatus]);

  useEffect(() => {
    // set checkbox status based on the selectedUsersIds

    const selectedStatuses = selectedUsersIds.map((id) =>
      users[id]?.private ? "UNPUBLISHED" : "PUBLISHED"
    );
    let newStatuses = [];

    if (selectedStatuses.includes("PUBLISHED")) {
      newStatuses.push("PUBLISHED");
    }
    if (selectedStatuses.includes("UNPUBLISHED")) {
      newStatuses.push("UNPUBLISHED");
    }

    setCheckboxStatus(newStatuses);
  }, [selectedUsersIds, users]);

  // Handler for the checkbox toggle
  const batchCheckboxHandler = (e) => {
    const isChecked = e.target.checked;
    // console.log(isChecked, "isChecked");

    if (isChecked) {
      // Assuming users is defined and contains your users
      const allUsersIds = Object.keys(users);
      setSelectedUsersIds(allUsersIds);
    } else {
      // Clear selection if the checkbox is unchecked
      setSelectedUsersIds([]);
    }
  };

  useEffect(() => {
    if (selectedUsersIds.length !== 0) {
      const usersSetToDelete = Object.values(users)?.filter((article) =>
        selectedUsersIds.includes(article.id)
      );
      setSelectedUsersData(usersSetToDelete);
    } else {
      setSelectedUsersData([]);
    }
  }, [selectedUsersIds]);

  useEffect(() => {
    // console.log("selectedAction", selectedAction);
  }, [selectedAction]);

  const handleActionChange = () => {
    if (selectedUsersData?.length === 0) {
      return;
    }
    switch (selectedAction) {
      case "allpublish":
        handleLockUser(false);
        // console.log("allpublish");
        break;
      case "allunpublish":
        handleLockUser(true);
        // console.log("allunpublish");
        break;
      default:
      // console.log("No action selected");
    }
  };

  // scaleton for loading
  let items = [];
  for (let i = 0; i < limit; i++) {
    items.push(
      <>
        <tr key={`loader${i}`} className="placeholder-glow">
          <td className="border rounded align-middle p-0" colSpan={13}>
            <span
              className="placeholder rounded col-12"
              style={{ background: "#c6c6c6", padding: "30px" }}></span>
          </td>
        </tr>
        <tr className="spacer">
          <td></td>
        </tr>
      </>
    );
  }

  const {
    firebaseUpdateDocs: userUpdateDocs,
    isPending: usersPending,
    isConfirmed: usersConfirmed,
  } = useUpdateDoc();

  const handleLockUser = async (privateStatus) => {
    await userUpdateDocs(
      "supportmembers",
      { private: privateStatus },
      selectedUsersIds
    );

    // update each article
    selectedUsersIds.forEach(async (id) => {
      // console.log("id", id);
      await getUpdatedMCDatabyID(id);
    });
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const deleteUser = async (data) => {
    setDeleteData(data);
    handleDeleteShow();
  };

  // const convertTagsField = async () => {
  //   const usersRef = collection(db, "group_page"); // Adjust 'users' to your actual collection path
  //   const snapshot =  await getDocs(usersRef);

  //   const batch = writeBatch(db)
  //   snapshot.forEach(item => {
  //     const data = item.data();
  //     // if (data.schools && typeof data.schools === 'object' && !Array.isArray(data.schools)) {
  //     //   const schoolsArray = Object.keys(data.schools);
  //     //   const itemRef = doc(db, "supportmembers", item.id);
  //     //   batch.update(itemRef, { schools_array: schoolsArray });
  //     // }
  //     const itemRef = doc(db, "group_page", item.id);
  //     batch.update(itemRef, { view: 0 });
  //   });

  //   await batch.commit();
  //   // console.log('Conversion completed.');
  // };

  return (
    <>
      <Container>
        <div
          className="d-sm-flex align-items-center"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}>
          <h4>アカウント一覧</h4>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "1rem" }}>
              <Select
                className="w-100"
                options={radioOptions}
                name="radioOptions"
                placeholder="並べ替え　　"
                value={selectRadioFilter}
                onChange={handleRadioFilterChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
                formatOptionLabel={(data) => data.label}
                // noOptionsMessage={({ inputValue }) => `No result found for "${inputValue}"`}
              />
            </div>
            <SupportMemberCSVDownload />
          </div>
        </div>

        {filterQueries?.length !== 0 && (
          <div className="pb-3 d-flex align-items-center flex-wrap">
            {filterQueries?.map((item, index) => (
              <h5
                style={{ marginRight: "0.7rem" }}
                key={item.value}
                onClick={() => {
                  setFilterQueries((prev) =>
                    prev.filter((prevItem) => prevItem.value !== item.value)
                  );

                  if (item.type === "selectStatus") {
                    setSelectStatus((prev) =>
                      prev.filter((prevItem) => prevItem.value !== item.value)
                    );
                  }

                  handleFilterSubmit();
                }}>
                <Badge bg="secondary" pill={true} style={{ cursor: "pointer" }}>
                  {item.label}
                  <ClearIcon fontSize="sm" />
                </Badge>
              </h5>
            ))}
            <h5
              style={{ marginRight: "0.7rem" }}
              onClick={handleClearAllFilter}>
              <Badge bg="danger" pill={true} style={{ cursor: "pointer" }}>
                すべてクリア
              </Badge>
            </h5>
          </div>
        )}

        <Row>
          <Col lg={5} xl={4}>
            <InputGroup className="mb-3">
              <Form.Check
                className="pt-2"
                type="checkbox"
                onChange={batchCheckboxHandler}
                checked={
                  users &&
                  selectedUsersIds.length > 0 &&
                  selectedUsersIds.length === Object.values(users).length
                }
              />

              <InputGroup.Text className="bg-transparent border-0">
                すべて選択
              </InputGroup.Text>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setSelectedAction(e.target.value)}
                value={selectedAction}>
                <option>▼選択してください</option>
                {checkboxActions.map((action, index) => (
                  <option key={index} value={action.method}>
                    {action.label}
                  </option>
                ))}
              </Form.Select>

              {selectedUsersIds &&
              selectedUsersIds.length > 0 &&
              selectedAction !== "" ? (
                <Button variant="secondary" onClick={handleActionChange}>
                  変更
                </Button>
              ) : (
                <Button variant="secondary" disabled>
                  変更
                </Button>
              )}
            </InputGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            {Object.values(users).length > 0 && (
              <>
                <Table
                  borderless
                  responsive
                  style={{ borderCollapse: "separate", borderSpacing: 0 }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center"></th>
                      <th className="">会員番号</th>
                      <th className="">企業名</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(users) &&
                      Object.values(users).map((doc, i) => (
                        <SupportMemberListItem
                          key={`data${i}`}
                          doc={doc}
                          getUpdatedMCDatabyID={getUpdatedMCDatabyID}
                          selectedUsersIds={selectedUsersIds}
                          setSelectedUsersIds={setSelectedUsersIds}
                          deleteUser={deleteUser}
                        />
                      ))}

                    {isFilteredUsersPending && items}
                  </tbody>
                  {Object.values(users).length == 1 && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                </Table>
                {/* <div className="my-4 text-center">
                  {!isLast && !isFilteredUsersPending && (
                    <Button variant="secondary" onClick={loadFilteredUsersData}>
                      もっと見る
                    </Button>
                  )}

                  {!isLast && isFilteredUsersPending && (
                    <Button
                      variant="secondary"
                      disabled
                      onClick={loadFilteredUsersData}>
                      もっと見る{" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </div> */}
              </>
            )}

            {!Object.values(users).length && !isFilteredUsersPending && (
              <Alert variant="warning" className="text-center">
                データが見つかりません
              </Alert>
            )}
          </Col>
        </Row>
      </Container>

      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>フィルター記事</Modal.Header>
        <FilterContentUser
          handleFilterSubmit={handleFilterSubmit}
          selectStatus={selectStatus}
          setSelectStatus={setSelectStatus}
          optionStatus={optionStatus}
          handleClearAllFilter={handleClearAllFilter}
        />
      </Modal>

      <AccountDeleteModal
        data={deleteData}
        show={showDelete}
        handleClose={handleDeleteClose}
        updateByDeleteUser={updateByDeleteUser}
      />
    </>
  );
}

export default SupportMemberList;
