import React, { useState } from "react";

import { useFormikContext } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CropModal from "../../../../components/Common/ImageCrop/CropModal";

function EditSupportMemberInput() {
  const { width } = useWindowDimensions();
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
  } = useFormikContext();

  const croppedProfilePhotoString = "croppedProfilePhoto";
  const groupPageString = "editGroupPage";

  const [profilePhoto, setProfilePhoto] = useState(values.profilePhoto || "");
  const [show, setShow] = useState(false);
  const [ratio, setRatio] = useState(1);
  const [localStorageVar, setLocalStorageVar] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  const cropProfilePhoto = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setRatio(1);
      setLocalStorageVar(croppedProfilePhotoString);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setCropImage = (type, url) => {
    if (type === croppedProfilePhotoString) {
      setProfilePhoto(url);
    }
  };

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}>
          イメージ写真
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 py-2 border-end border-secondary`}>
          {profilePhoto === "" ? (
            <Form.Control
              type="file"
              value={""}
              accept="image/*"
              onChange={(e) => {
                cropProfilePhoto(e);
              }}
            />
          ) : (
            <div
              className="position-relative mx-lg-0 mx-auto"
              style={{ width: "500px", maxWidth: "95%" }}>
              <img src={profilePhoto} className="w-100 shadow rounded" alt="" />
              <Button
                variant="danger"
                className="position-absolute top-0 end-0 me-1 mt-1"
                onClick={() => {
                  localStorage.removeItem(croppedProfilePhotoString);
                  setProfilePhoto("");
                  setFieldValue("profilePhoto", "");
                  const v = {
                    ...values,
                    ...JSON.parse(localStorage.getItem(groupPageString)),
                    profilePhoto: "",
                  };
                  const serializedState = JSON.stringify(v);
                  localStorage.setItem(groupPageString, serializedState);
                }}>
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          会員番号<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.memberId}
              name="memberId"
              required
              autoComplete="off"
              isInvalid={touched.memberId && errors.memberId}
              isValid={touched.memberId && !errors.memberId}
            />
            <Form.Control.Feedback type="invalid">
              {errors.memberId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          企業名<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company_name}
            name="company_name"
            isInvalid={touched.company_name && errors.company_name}
            isValid={touched.company_name && !errors.company_name}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.company_name}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          郵便番号
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postal_code}
            name="postal_code"
            isInvalid={touched.postal_code && errors.postal_code}
            isValid={touched.postal_code && !errors.postal_code}
          />
          <Form.Control.Feedback type="invalid">
            {errors.postal_code}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          都道府県
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prefecture}
            name="prefecture"
            isInvalid={touched.prefecture && errors.prefecture}
            isValid={touched.prefecture && !errors.prefecture}
          />
          <Form.Control.Feedback type="invalid">
            {errors.prefecture}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          勤務先住所
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.work_address}
            name="work_address"
            isInvalid={touched.work_address && errors.work_address}
            isValid={touched.work_address && !errors.work_address}
          />
          <Form.Control.Feedback type="invalid">
            {errors.work_address}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          電話
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              name="phone"
              isInvalid={touched.phone && errors.phone}
              isValid={touched.phone && !errors.phone}
              placeholder="ハイフンを入力してください"
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}>
          URL
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}>
          <Form.Group>
            <Form.Control
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              name="url"
              isInvalid={touched.url && errors.url}
              isValid={touched.url && !errors.url}
            />
            <Form.Control.Feedback type="invalid">
              {errors.url}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <CropModal
        show={show}
        setShow={setShow}
        setImage={setCropImage}
        imgSrc={imgSrc}
        aspect={ratio}
        localStorageVar={localStorageVar}
      />
    </>
  );
}

export default EditSupportMemberInput;
