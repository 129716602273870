import { useState } from "react";

import {
  average,
  collection,
  getAggregateFromServer,
  getCountFromServer,
  query,
  sum,
  where
} from "firebase/firestore";

import { db } from "../firebase/config";

export const useAggregation = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const averageWithQuery = async (c, q, fieldName) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);

    let ref = collection(db, c);
    if (q) {
      q.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }

    const docSnap = await getAggregateFromServer(ref, {
      average: average(fieldName.toString())
    });

    return docSnap.data().average;
  };


  const countWithQuery = async (c, q) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);

    let ref = collection(db, c);
    if (q) {
      q.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }

    const docSnap = await getCountFromServer(ref);
    // console.log(docSnap.data());
    return docSnap.data().count;
  };

  const sumWithQuery = async (c, q, fieldName) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);

    let ref = collection(db, c);
    if (q) {
      q.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }

    const docSnap = await getAggregateFromServer(ref, {
      sum: sum(fieldName.toString())
    });

    return docSnap.data().sum;
  };

  return { averageWithQuery, countWithQuery, sumWithQuery, isPending, isConfirmed, error };
};
